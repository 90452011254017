import React from 'react'

const BmsLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="170"
    height="36"
    viewBox="0 0 170 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_29202_115065)">
      <path
        d="M82.2535 4.7818C82.2535 7.79549 80.7395 9.30014 77.7076 9.30014H74.9878V0.413086H77.7076C79.2262 0.413086 80.3653 0.773849 81.1227 1.49977C81.8748 2.22129 82.2535 3.31677 82.2535 4.7818ZM81.5546 4.7818C81.5546 4.19667 81.4961 3.67313 81.3762 3.21118C81.2609 2.74923 81.0514 2.35767 80.7577 2.0365C80.464 1.71534 80.0671 1.46897 79.5685 1.30179C79.0744 1.13461 78.4468 1.05102 77.6901 1.05102H75.6422V8.64899H77.6901C78.4422 8.64899 79.0661 8.56102 79.5601 8.38942C80.0542 8.21782 80.4503 7.96267 80.7486 7.62832C81.0423 7.29393 81.2518 6.88918 81.3717 6.41403C81.4961 5.93888 81.5546 5.39774 81.5546 4.7818Z"
        fill="#6F6F6E"
      />
      <path
        d="M90.7338 9.30014H84.2297V0.404297H90.5114V1.05542H84.8839V4.34626H90.1554V4.99739H84.8839V8.65342H90.73V9.30014H90.7338Z"
        fill="#6F6F6E"
      />
      <path
        d="M99.7801 0.404297L96.2496 9.30014H95.4042L91.8782 0.404297H92.6083L95.8314 8.49059L99.0546 0.404297H99.7801Z"
        fill="#6F6F6E"
      />
      <path
        d="M107.789 9.30014H101.285V0.404297H107.567V1.05542H101.944V4.34626H107.215V4.99739H101.944V8.65342H107.789V9.30014Z"
        fill="#6F6F6E"
      />
      <path
        d="M115.13 9.30014H109.828V0.404297H110.487V8.65342H115.135V9.30014H115.13Z"
        fill="#6F6F6E"
      />
      <path
        d="M120.722 0.242188C121.336 0.242188 121.901 0.352175 122.423 0.567752C122.943 0.783326 123.393 1.09129 123.767 1.49605C124.141 1.89641 124.435 2.38035 124.649 2.94349C124.858 3.50663 124.964 4.13576 124.964 4.83088C124.964 5.51721 124.862 6.14634 124.653 6.71828C124.444 7.28582 124.155 7.77419 123.776 8.18331C123.402 8.59244 122.952 8.90924 122.436 9.13364C121.92 9.35796 121.345 9.47241 120.722 9.47241C120.099 9.47241 119.524 9.35796 119.004 9.13364C118.482 8.90924 118.033 8.59244 117.659 8.18774C117.285 7.78296 116.991 7.29462 116.782 6.72268C116.573 6.15514 116.47 5.52161 116.47 4.83088C116.47 4.13136 116.577 3.50223 116.786 2.93909C116.996 2.37595 117.294 1.89641 117.673 1.49605C118.051 1.1001 118.501 0.792123 119.017 0.572151C119.538 0.347775 120.103 0.242188 120.722 0.242188ZM120.717 8.81684C121.234 8.81684 121.71 8.72886 122.146 8.55291C122.583 8.37689 122.962 8.11731 123.277 7.77854C123.594 7.4354 123.843 7.01745 124.016 6.5247C124.19 6.02755 124.279 5.46441 124.279 4.83088C124.279 4.19295 124.19 3.62982 124.012 3.14147C123.834 2.65312 123.589 2.24397 123.273 1.9096C122.957 1.57524 122.587 1.32447 122.156 1.15289C121.724 0.981303 121.256 0.897716 120.744 0.897716C120.223 0.897716 119.742 0.985706 119.31 1.15728C118.875 1.32887 118.496 1.58404 118.18 1.91841C117.864 2.25276 117.614 2.66192 117.437 3.15027C117.258 3.63861 117.169 4.19736 117.169 4.83088C117.169 5.46441 117.258 6.02755 117.432 6.5247C117.606 7.02184 117.85 7.4398 118.167 7.77854C118.482 8.12174 118.857 8.37689 119.293 8.55291C119.725 8.72886 120.201 8.81684 120.717 8.81684Z"
        fill="#6F6F6E"
      />
      <path
        d="M126.866 0.404297H130.138C130.578 0.404297 130.997 0.439493 131.403 0.509885C131.807 0.580278 132.164 0.707863 132.475 0.897045C132.787 1.08182 133.032 1.33699 133.214 1.65816C133.397 1.97932 133.49 2.38408 133.49 2.87683C133.49 3.74793 133.21 4.41226 132.658 4.86981C132.101 5.32296 131.286 5.55173 130.218 5.55173H127.52V9.30449H126.861V0.404297H126.866ZM127.524 4.8962H130.244C130.704 4.8962 131.095 4.85221 131.42 4.76422C131.745 4.67623 132.007 4.54864 132.212 4.38146C132.417 4.21428 132.568 4.0075 132.662 3.76113C132.756 3.51475 132.8 3.22879 132.8 2.90322C132.8 2.50726 132.729 2.1861 132.591 1.94413C132.453 1.70215 132.262 1.51297 132.026 1.38099C131.785 1.249 131.513 1.16101 131.198 1.11702C130.886 1.07302 130.556 1.05103 130.209 1.05103H127.52V4.8962H127.524Z"
        fill="#6F6F6E"
      />
      <path
        d="M143.774 9.30014H143.115V1.46898L139.878 9.30014H139.188L135.957 1.46458V9.30014H135.297V0.404297H136.251L139.531 8.49059L142.839 0.404297H143.778V9.30014H143.774Z"
        fill="#6F6F6E"
      />
      <path
        d="M152.567 9.30014H146.062V0.404297H152.343V1.05542H146.717V4.34626H151.987V4.99739H146.717V8.65342H152.567V9.30014Z"
        fill="#6F6F6E"
      />
      <path
        d="M161.519 9.30014H160.655L155.162 1.17421V9.30014H154.503V0.404297H155.407L160.86 8.51699V0.404297H161.519V9.30014Z"
        fill="#6F6F6E"
      />
      <path
        d="M170 1.05542H166.853V9.30449H166.194V1.05542H163.077V0.404297H170V1.05542Z"
        fill="#6F6F6E"
      />
      <path
        d="M79.2884 22.6711C78.6471 22.6711 78.0597 22.5611 77.5345 22.3367C77.0048 22.1124 76.5502 21.8 76.1723 21.3997C75.7936 20.9949 75.4998 20.5066 75.295 19.9346C75.0902 19.3627 74.9878 18.7291 74.9878 18.0296C74.9878 17.3257 75.0902 16.6921 75.2994 16.129C75.5087 15.5659 75.798 15.0863 76.1768 14.686C76.5502 14.29 77.0002 13.9821 77.5209 13.7665C78.0415 13.5509 78.616 13.4453 79.2482 13.4453C79.7423 13.4453 80.2006 13.5069 80.6287 13.6301C81.056 13.7533 81.4385 13.9249 81.7724 14.1493C82.1063 14.3736 82.3826 14.642 82.6049 14.9587C82.8235 15.2711 82.9745 15.6231 83.0588 16.0058H82.3515C82.3067 15.7462 82.1997 15.4999 82.035 15.2711C81.8657 15.0424 81.6479 14.8399 81.3808 14.664C81.1136 14.4924 80.8017 14.3516 80.4412 14.2504C80.0853 14.1493 79.6937 14.0965 79.2664 14.0965C78.7405 14.0965 78.2555 14.18 77.8191 14.3472C77.3782 14.5144 77.0048 14.7652 76.6883 15.0995C76.3726 15.4339 76.1275 15.8431 75.9537 16.3358C75.7803 16.8241 75.6912 17.3917 75.6912 18.0296C75.6912 18.6631 75.7803 19.2263 75.9537 19.7234C76.1275 20.2206 76.3772 20.6386 76.6883 20.9773C77.0048 21.3205 77.3827 21.5756 77.8237 21.7516C78.2646 21.9276 78.7496 22.0156 79.2839 22.0156C80.0276 22.0156 80.6773 21.8748 81.2252 21.5932C81.7724 21.3117 82.2353 20.9201 82.614 20.4229V18.8303H80.1301V18.1792H83.2728V22.5127H82.8941L82.6049 21.3249C82.3955 21.5756 82.164 21.7824 81.9151 21.954C81.6608 22.1256 81.3944 22.2619 81.1136 22.3675C80.8328 22.4731 80.5346 22.5523 80.228 22.6007C79.916 22.6491 79.6041 22.6711 79.2884 22.6711Z"
        fill="#6F6F6E"
      />
      <path
        d="M92.5992 22.5081H91.8736L90.9789 20.1412C90.8901 19.9125 90.7922 19.7013 90.6852 19.4989C90.5782 19.2965 90.4537 19.125 90.311 18.9753C90.1683 18.8258 90.0036 18.707 89.8215 18.6234C89.6386 18.5354 89.4208 18.4914 89.1757 18.4914H86.0284V22.5081H85.3696V13.6123H89.4253C89.8033 13.6123 90.1683 13.6476 90.525 13.7136C90.8764 13.7795 91.1929 13.9027 91.4684 14.0743C91.7446 14.2458 91.967 14.4879 92.1317 14.7914C92.2964 15.0994 92.3768 15.4909 92.3768 15.9661C92.3768 16.2652 92.3411 16.5424 92.2653 16.7976C92.1901 17.0571 92.0786 17.286 91.9275 17.4883C91.7757 17.6907 91.5845 17.8667 91.353 18.0075C91.1216 18.1527 90.8453 18.2538 90.5334 18.3198C90.7026 18.4518 90.8453 18.575 90.9523 18.6938C91.0639 18.8126 91.1572 18.9402 91.2369 19.0765C91.3174 19.2129 91.3887 19.3625 91.4509 19.5253C91.5132 19.6881 91.5845 19.8729 91.6687 20.0841L92.5992 22.5081ZM86.0284 17.8447H89.1536C89.5991 17.8447 89.9771 17.8139 90.2981 17.7479C90.6184 17.6819 90.8764 17.5763 91.0859 17.4267C91.2908 17.2771 91.4418 17.0835 91.5443 16.846C91.6422 16.6084 91.6915 16.3137 91.6915 15.9661C91.6915 15.3546 91.4957 14.9146 91.1033 14.655C90.7117 14.391 90.1289 14.259 89.3495 14.259H86.0375V17.8447H86.0284Z"
        fill="#6F6F6E"
      />
      <path
        d="M98.4134 13.4492C99.0281 13.4492 99.5935 13.5592 100.114 13.7748C100.635 13.9903 101.085 14.2983 101.459 14.7031C101.832 15.1034 102.127 15.5874 102.34 16.1505C102.55 16.7137 102.657 17.3428 102.657 18.0379C102.657 18.7242 102.554 19.3534 102.345 19.9253C102.135 20.4928 101.846 20.9812 101.467 21.3903C101.094 21.7995 100.644 22.1162 100.128 22.3406C99.611 22.565 99.0372 22.6794 98.4134 22.6794C97.7903 22.6794 97.2158 22.565 96.6952 22.3406C96.1745 22.1162 95.7245 21.7995 95.3503 21.3947C94.977 20.9899 94.6833 20.5016 94.4738 19.9297C94.2643 19.3621 94.1619 18.7286 94.1619 18.0379C94.1619 17.3383 94.2689 16.7092 94.4783 16.1461C94.687 15.583 94.9853 15.1034 95.364 14.7031C95.7427 14.3071 96.192 13.9991 96.7088 13.7791C97.2249 13.5548 97.7949 13.4492 98.4134 13.4492ZM98.405 22.0239C98.9211 22.0239 99.3977 21.9358 99.8295 21.7599C100.266 21.5839 100.644 21.3244 100.96 20.9856C101.276 20.6424 101.526 20.2244 101.699 19.7317C101.873 19.2346 101.962 18.6714 101.962 18.0379C101.962 17.4 101.873 16.8368 101.695 16.3485C101.517 15.8602 101.267 15.451 100.956 15.1166C100.639 14.7823 100.266 14.5315 99.8379 14.3599C99.4061 14.1883 98.9386 14.1047 98.427 14.1047C97.9064 14.1047 97.4298 14.1927 96.9934 14.3643C96.557 14.5359 96.1791 14.791 95.8626 15.1254C95.5469 15.4597 95.2972 15.8689 95.1189 16.3573C94.9413 16.8456 94.8517 17.4043 94.8517 18.0379C94.8517 18.6714 94.9413 19.2346 95.1151 19.7317C95.2881 20.2288 95.5332 20.6468 95.849 20.9856C96.1654 21.3287 96.5396 21.5839 96.976 21.7599C97.4162 21.9358 97.8882 22.0239 98.405 22.0239Z"
        fill="#6F6F6E"
      />
      <path
        d="M107.994 22.671C107.455 22.671 106.997 22.6137 106.618 22.5038C106.235 22.3938 105.919 22.2442 105.67 22.055C105.417 21.8658 105.216 21.6414 105.069 21.3907C104.922 21.1355 104.811 20.8671 104.731 20.5768C104.655 20.2908 104.606 19.9917 104.589 19.6836C104.57 19.3757 104.561 19.0722 104.561 18.7818V13.6123H105.221V18.7554C105.221 19.4329 105.283 19.9828 105.407 20.4096C105.532 20.8363 105.71 21.1663 105.951 21.4039C106.191 21.6414 106.481 21.8043 106.823 21.8922C107.166 21.9802 107.558 22.0242 108.003 22.0242C108.452 22.0242 108.853 21.9802 109.201 21.8922C109.547 21.8043 109.841 21.6414 110.073 21.3995C110.305 21.1575 110.482 20.8275 110.603 20.4052C110.723 19.9828 110.785 19.4329 110.785 18.7554V13.6123H111.444V18.7818C111.444 19.3933 111.391 19.9432 111.288 20.4228C111.186 20.9067 111.003 21.3115 110.745 21.6459C110.487 21.9802 110.136 22.2354 109.69 22.4114C109.236 22.583 108.675 22.671 107.994 22.671Z"
        fill="#6F6F6E"
      />
      <path
        d="M113.782 13.6123H117.053C117.494 13.6123 117.913 13.6476 118.318 13.7179C118.723 13.7883 119.079 13.9159 119.391 14.1051C119.703 14.2899 119.947 14.545 120.13 14.8662C120.312 15.1874 120.406 15.5921 120.406 16.0848C120.406 16.956 120.126 17.6203 119.574 18.0779C119.017 18.531 118.202 18.7598 117.134 18.7598H114.436V22.5126H113.777V13.6123H113.782ZM114.44 18.1042H117.16C117.619 18.1042 118.011 18.0603 118.336 17.9723C118.661 17.8842 118.923 17.7567 119.128 17.5895C119.333 17.4223 119.484 17.2155 119.578 16.9692C119.672 16.7228 119.716 16.4368 119.716 16.1112C119.716 15.7153 119.644 15.3942 119.507 15.1521C119.369 14.9102 119.177 14.721 118.942 14.589C118.701 14.457 118.429 14.3691 118.113 14.325C117.802 14.2811 117.472 14.259 117.125 14.259H114.436V18.1042H114.44Z"
        fill="#6F6F6E"
      />
      <path
        d="M0 22.6709V0.242188H8.23157C10.7113 0.242188 12.6033 0.748131 13.9033 1.76442C15.2032 2.78071 15.8532 4.25455 15.8532 6.19473C15.8532 7.28142 15.5861 8.20094 15.0518 8.95761C14.5176 9.71436 13.6807 10.3699 12.5455 10.9242C13.8276 11.3554 14.7848 12.0285 15.4258 12.9348C16.0669 13.8455 16.3874 14.9893 16.3874 16.3752C16.3874 18.4958 15.6974 20.0752 14.3217 21.1135C12.9461 22.1518 10.8448 22.6709 8.01785 22.6709H0ZM4.17588 9.22161H7.66616C8.99725 9.22161 9.99448 8.98844 10.6534 8.52651C11.3123 8.06451 11.6461 7.37381 11.6461 6.44991C11.6461 5.48641 11.3167 4.78689 10.6534 4.34694C9.99448 3.91139 8.91711 3.69141 7.4213 3.69141H4.17588V9.22161ZM4.17588 19.2217H7.66616C9.21096 19.2217 10.3373 18.9533 11.0407 18.4166C11.7485 17.8798 12.1003 17.0219 12.1003 15.8473C12.1003 14.7518 11.753 13.9335 11.054 13.3924C10.3595 12.8512 9.29111 12.5785 7.86204 12.5785H4.17588V19.2217Z"
        fill="#414140"
      />
      <path
        d="M48.3386 17.6113L52.2117 15.9086C52.8128 17.1053 53.4806 17.9764 54.2151 18.522C54.9497 19.0675 55.8223 19.3447 56.8284 19.3447C57.9681 19.3447 58.8673 19.0895 59.5262 18.5748C60.1851 18.06 60.519 17.3693 60.519 16.4938C60.519 15.8031 60.2741 15.2223 59.7889 14.7516C59.2991 14.2853 58.4221 13.8189 57.1534 13.357C56.9798 13.2865 56.7305 13.1941 56.4054 13.0842C54.1795 12.2791 52.6302 11.5971 51.7577 11.034C50.894 10.4929 50.2351 9.7977 49.7766 8.9574C49.318 8.11267 49.091 7.15802 49.091 6.09333C49.091 4.28513 49.7722 2.8157 51.1433 1.68502C52.5011 0.563138 54.2864 0 56.4945 0C58.4266 0 60.0337 0.413555 61.3203 1.23626C62.6069 2.05897 63.5507 3.28204 64.1473 4.89667L60.2741 6.29571C59.7978 5.29262 59.2324 4.5623 58.5824 4.10475C57.9325 3.6472 57.1266 3.41843 56.174 3.41843C55.248 3.41843 54.5223 3.63401 53.9881 4.06075C53.4539 4.4875 53.1867 5.06824 53.1867 5.80297C53.1867 6.93804 54.4911 8.0115 57.1044 9.02782C57.9058 9.3402 58.5379 9.59093 58.9965 9.78015C61.1824 10.6952 62.6692 11.6279 63.4572 12.5826C64.2452 13.5373 64.637 14.7823 64.637 16.3178C64.637 18.3856 63.9514 20.0134 62.5802 21.1969C61.2091 22.3804 59.3215 22.9743 56.9264 22.9743C54.8429 22.9743 53.0754 22.5212 51.6286 21.6192C50.1728 20.7129 49.0777 19.3799 48.3386 17.6113Z"
        fill="#414140"
      />
      <path
        d="M22.1392 22.6709H17.3757L25.7631 0.242188H30.5266L22.1392 22.6709Z"
        fill="#414140"
      />
      <path
        d="M30.549 22.6709H25.7854L34.1773 0.242188H38.9407L30.549 22.6709Z"
        fill="#009B3D"
      />
      <path
        d="M47.2969 22.6703L40.5746 4.69824L38.2729 11.2843L42.5334 22.6703H47.2969Z"
        fill="#414140"
      />
      <path
        d="M24.1095 32.2796C24.1094 31.9262 23.9743 31.6513 23.6982 31.4694C23.4297 31.2925 23.0282 31.2129 22.5132 31.2129H21.3824H21.2686V31.3254V35.1222V35.2347H21.3824H22.6645C23.1785 35.2347 23.5834 35.1526 23.8629 34.9709C24.1508 34.7837 24.2921 34.4998 24.2921 34.1367C24.2921 33.8239 24.1885 33.563 23.9697 33.367C23.8563 33.2653 23.72 33.1876 23.5625 33.1326C23.6729 33.0827 23.7685 33.0206 23.848 32.9453C24.0273 32.7752 24.1118 32.5491 24.1095 32.2796ZM22.5399 32.9903H21.6565V31.5435H22.5176C22.9688 31.5435 23.2802 31.6135 23.4662 31.7321C23.6474 31.8492 23.7393 32.0221 23.7393 32.2801C23.7393 32.5196 23.6466 32.6879 23.4629 32.8026C23.2705 32.9227 22.9678 32.9903 22.5399 32.9903ZM22.669 34.9085H21.6609V33.3253H22.5443C23.0493 33.3253 23.3967 33.3998 23.6096 33.5321C23.8078 33.6579 23.913 33.8523 23.913 34.1411C23.913 34.3999 23.8154 34.5826 23.6243 34.7064C23.425 34.8356 23.1119 34.9085 22.669 34.9085Z"
        fill="#414140"
      />
      <path
        d="M24.1095 32.2796C24.1094 31.9262 23.9743 31.6513 23.6982 31.4694C23.4297 31.2925 23.0282 31.2129 22.5132 31.2129H21.3824H21.2686V31.3254V35.1222V35.2347H21.3824H22.6645C23.1785 35.2347 23.5834 35.1526 23.8629 34.9709C24.1508 34.7837 24.2921 34.4998 24.2921 34.1367C24.2921 33.8239 24.1885 33.563 23.9697 33.367C23.8563 33.2653 23.72 33.1876 23.5625 33.1326C23.6729 33.0827 23.7685 33.0206 23.848 32.9453C24.0273 32.7752 24.1118 32.5491 24.1095 32.2796ZM24.1095 32.2796C24.1095 32.2798 24.1095 32.2795 24.1095 32.2796ZM24.1095 32.2796L23.9957 32.2801M22.5399 32.9903H21.6565V31.5435H22.5176C22.9688 31.5435 23.2802 31.6135 23.4662 31.7321C23.6474 31.8492 23.7393 32.0221 23.7393 32.2801C23.7393 32.5196 23.6466 32.6879 23.4629 32.8026C23.2705 32.9227 22.9678 32.9903 22.5399 32.9903ZM22.669 34.9085H21.6609V33.3253H22.5443C23.0493 33.3253 23.3967 33.3998 23.6096 33.5321C23.8078 33.6579 23.913 33.8523 23.913 34.1411C23.913 34.3999 23.8154 34.5826 23.6243 34.7064C23.425 34.8356 23.1119 34.9085 22.669 34.9085Z"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M27.9 31.2119H27.7861V31.3244V33.5374C27.7861 34.0982 27.9054 34.5394 28.1642 34.8414C28.4262 35.147 28.8143 35.2909 29.3068 35.2909C29.7974 35.2909 30.1843 35.1457 30.4456 34.8396C30.7037 34.5371 30.8229 34.0961 30.8229 33.5374V31.3244V31.2119H30.7091H30.5533H30.4394V31.3244V33.5374C30.4394 34.0377 30.3368 34.3917 30.1519 34.619C29.9706 34.8417 29.6957 34.9603 29.3068 34.9603C28.9153 34.9603 28.6394 34.8415 28.4577 34.6189C28.2722 34.3916 28.1696 34.0378 28.1696 33.5374V31.3244V31.2119H28.0558H27.9Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M34.6865 35.1256V35.2381H34.8004H34.9473H35.0611V35.1256V31.3244V31.2119H34.9473H34.8004H34.6865V31.3244V35.1256Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M41.3671 34.9075H39.3796V31.3244V31.2119H39.2657H39.1099H38.9961V31.3244V35.1256V35.2381H39.1099H41.3671H41.4809V35.1256V35.02V34.9075H41.3671Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M47.611 31.7442C47.2613 31.3842 46.7315 31.2119 46.0547 31.2119H44.973H44.8591V31.3244V35.1256V35.2381H44.973H46.0547C46.724 35.2381 47.2464 35.0755 47.6008 34.7319C47.9557 34.3878 48.123 33.8809 48.123 33.2338C48.123 32.6073 47.9573 32.1054 47.611 31.7442ZM46.0547 34.9075H45.2426V31.5425H46.0547C46.6421 31.5425 47.0553 31.6917 47.3198 31.9768C47.5888 32.2667 47.7306 32.6805 47.7306 33.2338C47.7306 33.8129 47.5857 34.2259 47.3162 34.4948C47.0467 34.7635 46.6336 34.9075 46.0547 34.9075Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M53.9111 33.0481L53.9065 33.0447C53.8379 32.9973 53.7597 32.9805 53.6809 32.9805C53.6005 32.9805 53.5184 32.9961 53.4507 33.0481C53.3889 33.0957 53.3445 33.1659 53.3445 33.2513C53.3445 33.343 53.3906 33.4083 53.4507 33.4545C53.5184 33.5064 53.6005 33.5222 53.6809 33.5222C53.7651 33.5222 53.8385 33.5049 53.9065 33.4578C53.9821 33.4056 54.0173 33.3312 54.0173 33.2513C54.0173 33.1596 53.9712 33.0943 53.9111 33.0481Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M59.2432 35.1257V35.2382H59.357H59.5084H59.6223V35.1257V31.8382L61.0585 34.155L61.0918 34.2087H61.1556H61.259L61.2624 34.2146L61.2659 34.2087H61.3648H61.4287L61.462 34.1549L62.8982 31.8301V35.1257V35.2382H63.012H63.1678H63.2817V35.1257V31.3245V31.212H63.1678H63.1258V31.0303L63.0135 31.212H62.9141H62.8504L62.817 31.2656L61.2624 33.7683L59.6988 31.2654L59.6654 31.212H59.6019H59.5015L59.3946 31.0391V31.212H59.357H59.2432V31.3245V35.1257Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M67.2637 31.2119H67.1499V31.3244V33.5374C67.1499 34.0982 67.2691 34.5394 67.528 34.8414C67.79 35.147 68.178 35.2909 68.6705 35.2909C69.1612 35.2909 69.548 35.1457 69.8093 34.8396C70.0674 34.5371 70.1867 34.0961 70.1867 33.5374V31.3244V31.2119H70.0728H69.917H69.8032V31.3244V33.5374C69.8032 34.0377 69.7006 34.3917 69.5156 34.619C69.3344 34.8417 69.0595 34.9603 68.6705 34.9603C68.2791 34.9603 68.0032 34.8415 67.8215 34.6189C67.636 34.3916 67.5334 34.0378 67.5334 33.5374V31.3244V31.2119H67.4196H67.2637Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M76.4211 34.9075H74.4338V31.3244V31.2119H74.3199H74.1641H74.0503V31.3244V35.1256V35.2381H74.1641H76.4211H76.535V35.1256V35.02V34.9075H76.4211Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M81.6995 31.3244V31.2119H81.5856H78.8565H78.7427V31.3244V31.43V31.5425H78.8565H80.0336V35.1256V35.2381H80.1475H80.303H80.4169V35.1256V31.5425H81.5856H81.6995V31.43V31.3244Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M85.0198 35.1256V35.2381H85.1336H85.2808H85.3947V35.1256V31.3244V31.2119H85.2808H85.1336H85.0198V31.3244V35.1256Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M89.7129 33.2225V31.5381H90.427C90.9241 31.5381 91.2596 31.6131 91.4577 31.7471C91.6474 31.876 91.7506 32.0794 91.7506 32.3759C91.7506 32.6671 91.6481 32.8712 91.4523 33.0049L91.4516 33.0058C91.2649 33.1367 90.9697 33.214 90.5409 33.2254V33.2225H90.427H89.7129ZM89.7129 33.5575H90.427C90.9864 33.5575 91.4197 33.4691 91.7066 33.2737C92.001 33.0733 92.143 32.7716 92.143 32.3803C92.143 31.9852 92.001 31.6826 91.7058 31.4863C91.4189 31.2956 90.9856 31.2119 90.427 31.2119H89.4427H89.3289V31.3244V35.1256V35.2381H89.4427H89.599H89.7129V35.1256V33.5575Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M98.0711 34.9075H96.0835V31.3244V31.2119H95.9696H95.814H95.7002V31.3244V35.1256V35.2381H95.814H98.0711H98.1849V35.1256V35.02V34.9075H98.0711Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M103.346 31.2119H103.28L103.248 31.2682L102.028 33.3556L100.805 31.268L100.772 31.2119H100.706H100.528H100.33L100.43 31.3812L101.83 33.7483V35.1256V35.2381H101.944H102.099H102.213V35.1256V33.7484L103.623 31.3815L103.724 31.2119H103.525H103.346Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M108.937 33.0481L108.932 33.0447C108.863 32.9973 108.785 32.9805 108.707 32.9805C108.626 32.9805 108.543 32.9961 108.476 33.0481C108.414 33.0957 108.37 33.1659 108.37 33.2513C108.37 33.343 108.416 33.4083 108.476 33.4545C108.543 33.5064 108.626 33.5222 108.707 33.5222C108.787 33.5222 108.869 33.5064 108.937 33.4545C108.998 33.4069 109.043 33.3368 109.043 33.2513C109.043 33.1596 108.996 33.0943 108.937 33.0481Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M114.245 35.0137L114.246 35.0141C114.542 35.1982 114.884 35.2909 115.277 35.2909C115.663 35.2909 115.992 35.2083 116.262 35.0413C116.55 34.8714 116.696 34.615 116.696 34.2898C116.696 34.1222 116.656 33.9607 116.568 33.8178C116.483 33.6799 116.364 33.558 116.212 33.4612C116.069 33.3651 115.931 33.2923 115.807 33.2337C115.682 33.1741 115.531 33.1153 115.361 33.0535L115.36 33.0532C115.007 32.9282 114.744 32.7884 114.562 32.6408C114.392 32.5039 114.309 32.3335 114.309 32.112C114.309 31.9149 114.389 31.7651 114.545 31.659C114.707 31.5485 114.936 31.4854 115.246 31.4854C115.558 31.4854 115.8 31.5562 115.981 31.6843C116.151 31.8048 116.24 31.9834 116.24 32.244V32.3725L116.369 32.3555L116.503 32.3379L116.6 32.3251L116.602 32.2281C116.607 31.9024 116.48 31.6399 116.219 31.4421C115.967 31.2517 115.638 31.1592 115.246 31.1592C114.866 31.1592 114.552 31.2371 114.305 31.403L114.304 31.4036C114.055 31.5739 113.926 31.8151 113.926 32.1164C113.926 32.4135 114.043 32.6642 114.272 32.8612L114.273 32.862C114.496 33.0496 114.808 33.2135 115.205 33.3652L115.209 33.3667C115.374 33.4224 115.508 33.478 115.618 33.5257C115.718 33.5713 115.834 33.6343 115.958 33.7151C116.077 33.7921 116.161 33.8727 116.216 33.9676C116.273 34.0657 116.303 34.1696 116.303 34.2853C116.303 34.5008 116.213 34.6591 116.026 34.7754C115.831 34.8974 115.582 34.9603 115.268 34.9603C114.944 34.9603 114.679 34.8857 114.462 34.7345C114.255 34.5911 114.149 34.381 114.144 34.0858L114.143 33.9679L114.024 33.9751L113.877 33.9838L113.767 33.9904L113.77 34.0988C113.779 34.5027 113.936 34.8181 114.245 35.0137Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M122.695 31.5425H122.808V31.43V31.3244V31.2119H122.695H120.397H120.283V31.3244V35.1256V35.2381H120.397H122.695H122.808V35.1256V35.02V34.9075H122.695H120.671V33.3111H122.583H122.697V33.1986V33.0931V32.9806H122.583H120.671V31.5425H122.695Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M128.715 31.4678L128.714 31.4673C128.435 31.2625 128.103 31.1592 127.725 31.1592C127.188 31.1592 126.758 31.3425 126.443 31.7194C126.132 32.0935 125.986 32.6017 125.986 33.2338C125.986 33.8617 126.132 34.3658 126.444 34.7357C126.758 35.1078 127.188 35.2909 127.725 35.2909C128.106 35.2909 128.436 35.1929 128.712 35.0022L128.713 35.0006C128.99 34.7998 129.189 34.5107 129.315 34.1458L129.354 34.0337L129.239 34.0012L129.083 33.9572L128.976 33.9271L128.943 34.0315C128.846 34.3342 128.689 34.5645 128.483 34.7244C128.273 34.8837 128.027 34.9648 127.725 34.9648C127.307 34.9648 126.985 34.8156 126.746 34.5188C126.504 34.2198 126.378 33.7948 126.378 33.2383C126.378 32.6729 126.504 32.2476 126.746 31.944L126.746 31.9432C126.981 31.6435 127.303 31.4941 127.725 31.4941C128.018 31.4941 128.269 31.5753 128.478 31.7383C128.688 31.9023 128.845 32.1365 128.943 32.4526L128.975 32.5585L129.083 32.5281L129.239 32.4841L129.352 32.4522L129.315 32.3416C129.194 31.9661 128.99 31.6728 128.715 31.4678Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M132.876 31.2119H132.762V31.3244V33.5374C132.762 34.0982 132.881 34.5394 133.14 34.8414C133.402 35.147 133.79 35.2909 134.283 35.2909C134.773 35.2909 135.16 35.1457 135.421 34.8396C135.68 34.5371 135.799 34.0961 135.799 33.5374V31.3244V31.2119H135.685H135.529H135.415V31.3244V33.5374C135.415 34.0377 135.312 34.3917 135.127 34.619C134.947 34.8417 134.671 34.9603 134.283 34.9603C133.891 34.9603 133.616 34.8415 133.433 34.6189C133.248 34.3916 133.146 34.0378 133.146 33.5374V31.3244V31.2119H133.032H132.876Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M142.217 33.9082C142.154 33.7117 142.054 33.5499 141.913 33.4276C141.866 33.3874 141.817 33.3522 141.763 33.3214C141.919 33.2641 142.054 33.1836 142.168 33.0788L142.169 33.0778C142.37 32.8901 142.468 32.6341 142.468 32.3275C142.468 31.9596 142.323 31.6714 142.03 31.4812C141.744 31.2963 141.332 31.2119 140.808 31.2119H139.772H139.658V31.3244V35.1212V35.2337H139.772H139.927H140.041V35.1212V33.4563H140.911C141.172 33.4563 141.373 33.4988 141.52 33.5787C141.657 33.6537 141.766 33.7894 141.841 34.0079L142.214 35.16L142.239 35.2381H142.323H142.487H142.643L142.596 35.0917L142.217 33.9082ZM140.925 33.1257H140.046V31.5381H140.813C141.287 31.5381 141.61 31.6129 141.799 31.7424C141.981 31.8671 142.08 32.0623 142.08 32.3407C142.08 32.6104 141.986 32.7976 141.81 32.9224C141.627 33.0521 141.337 33.1257 140.925 33.1257Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
      <path
        d="M148.614 31.5425H148.727V31.43V31.3244V31.2119H148.614H146.316H146.202V31.3244V35.1256V35.2381H146.316H148.614H148.727V35.1256V35.02V34.9075H148.614H146.59V33.3111H148.502H148.616V33.1986V33.0931V32.9806H148.502H146.59V31.5425H148.614Z"
        fill="#414140"
        stroke="#414140"
        strokeWidth="0.3"
      />
    </g>
    <defs>
      <clipPath id="clip0_29202_115065">
        <rect width="170" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default BmsLogo
