import React from 'react'

const AagWhiteLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="110"
    height="36"
    viewBox="0 0 110 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_29202_115444)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.23 17.6089C80.1675 22.5515 84.2361 26.6304 89.244 26.6304H89.3065L92.3121 35.2178H89.1816C79.5417 35.2178 71.6544 27.3097 71.6544 17.6089C71.6544 7.90811 79.5417 0 89.1816 0H99.7609L103.016 8.46405H89.1816C84.1736 8.58892 80.1675 12.6664 80.23 17.6089ZM63.5174 35.2178L56.9442 17.6089H65.7709L72.3442 35.2178H63.5174ZM37.6644 35.2178L50.808 0H59.6347L46.4882 35.2178H37.6644ZM20.5104 17.6089H29.3386L35.9104 35.2178H27.0836L20.5104 17.6089ZM1.16823 35.2178L14.3147 0H23.1415L9.9935 35.2178H1.16823ZM108.84 35.2178H100.011L93.4374 17.6089H102.264L108.837 35.2178H108.84Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_29202_115444">
        <rect width="110" height="35.6757" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default AagWhiteLogo
