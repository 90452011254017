import React from 'react'

const CimajloLyashenkoPartnyoryLogo: React.FC<
  React.SVGProps<SVGSVGElement>
> = props => (
  <svg
    width="112"
    height="61"
    viewBox="0 0 112 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M65.9316 0.936354C65.6525 1.17552 65.6127 1.4147 65.7323 1.77345H69.3996C70.003 1.75334 70.2004 1.78398 70.3555 1.71682C70.5078 1.6509 70.6192 1.49073 71.0339 1.0958C71.0754 1.05434 71.112 1.01288 71.1472 0.973078C71.2136 0.898085 71.2747 0.82899 71.3528 0.776907C71.592 0.577598 71.6318 0.338426 71.4724 0.0195312H70.9542C70.5821 0.0195312 70.2101 0.0239604 69.838 0.0283895C69.0939 0.0372476 68.3499 0.0461058 67.6058 0.0195312C67.0876 0.0195312 66.6092 0.0992556 66.2903 0.577598C66.2372 0.657319 66.1486 0.737039 66.06 0.81676C66.0157 0.856624 65.9714 0.896489 65.9316 0.936354Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.885 14.1306C92.3201 16.7216 94.632 17.8776 97.6616 17.9972C97.7736 17.986 97.895 17.978 98.0224 17.9695C98.3482 17.9479 98.7128 17.9237 99.0567 17.8378C102.684 17.2398 105.235 14.1705 105.156 10.6228C105.116 7.91216 103.88 5.83934 101.528 4.48404C99.2162 3.1686 96.8244 3.08887 94.3929 4.12528C90.2074 5.91906 88.9717 10.6626 90.885 14.1306ZM97.4622 15.2467C94.8314 15.2069 93.0774 13.0942 93.1572 10.9417C93.1572 9.34719 93.5956 8.15133 94.6719 7.31423C96.1069 6.07851 97.7811 5.91906 99.4952 6.67644C101.169 7.39395 101.927 8.78912 102.006 10.543C102.046 11.3403 101.927 12.1774 101.528 12.8949C100.651 14.5292 99.2959 15.2866 97.4622 15.2467Z"
      fill="black"
    />
    <path
      d="M15.9049 17.558V3.92525C16.3035 3.84553 18.4162 3.84553 18.8547 3.92525C18.8253 5.08512 18.8338 6.2504 18.8423 7.4171C18.8569 9.41804 18.8715 11.4232 18.6952 13.4124C18.7105 13.4124 18.7257 13.4182 18.7387 13.4232C18.7597 13.4312 18.775 13.437 18.775 13.4124C18.775 13.4124 18.8148 13.4124 18.8148 13.3725C18.8547 13.3326 18.8945 13.2928 18.8945 13.2529C19.5052 12.2041 20.1159 11.1597 20.7266 10.1153L20.7282 10.1128C21.9505 8.02232 23.1731 5.93154 24.3955 3.80566H28.0229V17.5181H25.113C25.113 17.4384 25.103 17.3687 25.0931 17.2989C25.0831 17.2291 25.0731 17.1594 25.0731 17.0797V9.86467C25.0731 9.30661 25.1529 8.74857 25.2326 8.19051L25.2326 7.9513C25.0604 7.92261 25.0122 8.01782 24.969 8.10316C24.9521 8.13639 24.936 8.16813 24.9137 8.19047C24.5749 8.7884 24.2261 9.37636 23.8773 9.96432C23.5285 10.5523 23.1797 11.1402 22.8409 11.7382C22.3028 12.655 21.7746 13.5618 21.2465 14.4686C20.7183 15.3755 20.1901 16.2824 19.6519 17.1992C19.5323 17.3986 19.3729 17.5181 19.1337 17.5181H16.0644C16.1042 17.5979 16.0245 17.5979 15.9049 17.558Z"
      fill="black"
    />
    <path
      d="M30.7335 17.5987H33.5637C33.5637 17.5637 33.5671 17.5355 33.5699 17.5121C33.5767 17.4557 33.5802 17.4276 33.5238 17.3994C33.4948 16.1812 33.508 14.963 33.5212 13.7467C33.5442 11.6199 33.5672 9.49888 33.3643 7.39404C33.3398 7.34503 33.3605 7.28094 33.3801 7.22031C33.3924 7.18234 33.4042 7.14572 33.4042 7.11501H33.5238C34.6001 9.58644 35.6365 12.018 36.6729 14.4496H40.5395C40.603 14.3099 40.6665 14.1743 40.7287 14.0414C40.8617 13.7573 40.9889 13.4854 41.0975 13.2139C41.1773 13.0146 41.2669 12.8054 41.3566 12.5961C41.4463 12.3868 41.536 12.1775 41.6158 11.9782C41.6955 11.7788 41.7852 11.5696 41.8749 11.3603C41.9645 11.151 42.0542 10.9417 42.134 10.7424C42.2137 10.5431 42.3034 10.3338 42.3931 10.1245C42.4827 9.91536 42.5725 9.70593 42.6522 9.50672C42.6861 9.42197 42.72 9.33542 42.7542 9.24784L42.755 9.24588L42.7564 9.24231L42.7576 9.23923C42.8835 8.91747 43.0148 8.5821 43.1704 8.271C43.2343 8.1431 43.2859 8.0111 43.3371 7.88026C43.4454 7.60337 43.5518 7.33154 43.7683 7.11501C43.8082 7.23459 43.8082 7.31432 43.8082 7.39404C43.792 7.63603 43.7628 7.8715 43.7338 8.10572C43.6911 8.45013 43.6487 8.79212 43.6487 9.14796V17.2001C43.6487 17.2556 43.6573 17.3111 43.6665 17.3705C43.6771 17.4392 43.6886 17.5132 43.6886 17.5987H46.5985V3.8862H46.0404C45.6684 3.8862 45.2963 3.89063 44.9243 3.89506C44.1802 3.90392 43.4361 3.91277 42.692 3.8862C42.2934 3.84634 42.134 4.00578 41.9745 4.36454C41.257 6.15833 40.4996 7.91225 39.7422 9.66617C39.4633 10.3436 39.1841 11.0217 38.8653 11.739C38.7058 11.6991 38.5464 11.6991 38.3869 11.6991C37.9107 10.5766 37.4345 9.46138 36.9614 8.3534L36.9604 8.35111C36.325 6.86301 35.6952 5.38799 35.0784 3.92606C34.4406 3.80647 31.0524 3.84634 30.6936 3.92606C30.6139 4.44426 30.6537 17.2399 30.7335 17.5987Z"
      fill="black"
    />
    <path
      d="M28.7404 39.6021H46.0405C46.1202 39.0839 46.1202 26.3281 45.9608 25.9295H43.0907V36.8118C42.5725 36.9314 39.1444 36.8915 38.7856 36.7719V27.4841C38.7856 27.3114 38.7901 27.1387 38.7945 26.9659C38.8033 26.6204 38.8122 26.275 38.7856 25.9295H35.8358V36.8118C35.2778 36.8915 31.8895 36.8915 31.4909 36.7719V25.8896H28.6607C28.6607 25.9363 28.647 25.9557 28.6357 25.9717C28.6277 25.983 28.6208 25.9927 28.6208 26.0092V39.1238C28.6208 39.2092 28.6323 39.2832 28.6429 39.3519C28.6521 39.4114 28.6607 39.4669 28.6607 39.5224L28.7404 39.6021Z"
      fill="black"
    />
    <path
      d="M68.107 14.7369C69.4136 12.4792 70.7121 10.2354 72.0304 7.99151C72.0585 7.99151 72.1067 8.01145 72.2029 8.05131C72.2428 8.06782 72.2909 8.08775 72.3493 8.1111V8.31041C72.2695 8.82861 72.1898 9.34682 72.1898 9.86502V17.2793C72.1898 17.3348 72.1984 17.3817 72.2076 17.432C72.2182 17.4901 72.2297 17.5526 72.2297 17.6381C73.1864 17.6779 74.143 17.6779 75.0599 17.6381C75.0599 17.5623 75.0759 17.5185 75.0876 17.4865C75.0944 17.468 75.0997 17.4534 75.0997 17.4388V4.2445C75.0997 4.20467 75.0898 4.16484 75.0798 4.125C75.0698 4.08514 75.0599 4.04519 75.0599 4.00533L74.9403 3.88574H71.6716C71.407 3.88574 71.3217 4.02907 71.2116 4.2138L71.1933 4.2445C70.6949 5.10173 70.2065 5.94899 69.718 6.79625L69.7164 6.79907L69.7152 6.80117C69.2279 7.6464 68.7407 8.49165 68.2435 9.34682C67.8648 9.98461 67.4961 10.6224 67.1273 11.2602C66.7586 11.898 66.3899 12.5358 66.0112 13.1736C65.9961 13.1962 65.9824 13.2203 65.9685 13.2448C65.9092 13.3493 65.8463 13.4601 65.6525 13.4925V13.1337C65.6652 12.9804 65.6821 12.823 65.6991 12.6641C65.7352 12.3273 65.772 11.9838 65.772 11.6588V4.32422C65.772 4.26872 65.7635 4.20464 65.7543 4.13595C65.7436 4.05664 65.7322 3.97119 65.7322 3.88574H62.8223V17.5982H66.4497C67.005 16.6413 67.5567 15.6879 68.107 14.7369Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.804 32.7863C102.804 36.0151 100.691 38.8055 97.5419 39.6824C94.9509 40.3999 92.5193 40.0412 90.4067 38.367C88.294 36.7327 87.417 34.5004 87.7359 31.8695C88.0947 28.6806 90.4864 26.1693 93.6753 25.6112C96.3062 25.1727 98.6979 25.7308 100.651 27.6043C102.046 28.9596 102.804 30.8331 102.804 32.7863ZM99.6546 32.7465C99.6546 31.9094 99.4553 31.1121 99.0168 30.3548C98.0601 28.7204 96.3062 28.0029 94.3131 28.3617C93.4362 28.5211 92.6389 28.9197 92.041 29.5575C90.6857 30.9527 90.4067 33.2647 91.3235 35.0186C92.2403 36.7725 94.0341 37.4103 95.6286 37.2509C96.5853 37.1711 97.4223 36.8522 98.1399 36.2145C99.2161 35.2976 99.6148 34.1416 99.6546 32.7465Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.0298 25.9296V39.6023C25.4841 39.6477 24.9384 39.6413 24.3852 39.6348C23.9687 39.6299 23.548 39.625 23.1199 39.6421C23.0932 39.2951 23.1023 38.9659 23.1112 38.6427C23.1156 38.4832 23.1199 38.3252 23.1199 38.1672V35.1776C23.0345 35.1776 22.9605 35.1662 22.8918 35.1555C22.8323 35.1463 22.7768 35.1377 22.7213 35.1377H20.6086C20.2897 35.1377 20.1303 35.2573 19.9708 35.5364L17.9379 39.0043C17.8183 39.2036 17.6987 39.403 17.5392 39.6421H14.3104C14.5496 39.1937 14.7888 38.8125 15.0279 38.4313C15.1077 38.3043 15.1874 38.1772 15.2671 38.0477C15.4266 37.7686 15.596 37.4995 15.7654 37.2305C15.9348 36.9614 16.1042 36.6924 16.2637 36.4133C16.5826 35.8951 16.9015 35.3769 17.2602 34.779C17.1273 34.7258 17.0299 34.6727 16.9325 34.6195C16.8837 34.593 16.835 34.5664 16.7819 34.5398C15.5462 33.902 14.6692 32.9453 14.3503 31.5502C13.9118 29.5969 14.6293 27.7234 16.3833 26.687C17.2204 26.1688 18.1372 25.8898 19.0939 25.8898C20.5806 25.8632 22.0496 25.8721 23.5245 25.8809C24.2641 25.8853 25.0052 25.8898 25.7508 25.8898C25.7866 25.8898 25.8225 25.8978 25.8619 25.9067C25.9102 25.9176 25.964 25.9296 26.0298 25.9296ZM23.0402 32.3075V28.6801C22.9935 28.6801 22.9605 28.6664 22.9331 28.6551C22.9138 28.6471 22.8973 28.6403 22.8807 28.6403H19.2932C18.9344 28.6403 18.6155 28.7598 18.2966 28.8794C17.619 29.1983 17.3001 29.7564 17.3001 30.4739C17.3001 31.2313 17.6987 31.7893 18.3763 32.0684C18.7351 32.188 19.1337 32.2677 19.4925 32.2677C20.2071 32.2942 20.9217 32.2855 21.648 32.2766H21.6507C22.0171 32.2722 22.3873 32.2677 22.7612 32.2677C22.8409 32.3474 22.9206 32.3474 23.0402 32.3075Z"
      fill="black"
    />
    <path
      d="M4.14566 14.8482V3.88623C3.10925 3.88623 2.15256 3.88623 1.31546 3.84637C1.19588 4.36457 1.23574 17.2001 1.31546 17.5588C1.43505 17.5987 1.51477 17.5987 1.63436 17.5987H12.9153C13.1943 17.5987 13.3537 17.5588 13.5132 17.3595C13.951 16.8421 14.3889 16.3644 14.8267 15.8867L14.8281 15.8852C15.1071 15.5664 15.1874 15.2469 15.0678 14.8482C14.7269 14.8312 14.386 14.836 14.042 14.8409C13.5815 14.8474 13.1153 14.854 12.6362 14.8084V3.88623C11.9984 3.80651 10.0452 3.84637 9.72632 3.92609C9.68645 3.92609 9.68646 3.96595 9.68646 3.96595V14.6489C9.64659 14.6489 9.64659 14.6888 9.64659 14.7287C9.60673 14.7287 9.60673 14.7685 9.60673 14.7685C9.60673 14.7685 9.56687 14.7685 9.52701 14.8482H4.14566Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.9834 17.5582H47.9539C48.5518 15.266 49.1597 12.9839 49.7676 10.7017C50.3755 8.41968 50.9834 6.13767 51.5813 3.8457H57.7997L57.8003 3.84776C58.9959 8.3911 60.1917 12.9351 61.4272 17.4785C61.0684 17.598 59.0753 17.598 58.3977 17.5183C58.2236 16.822 58.0284 16.1046 57.8275 15.3661L57.8268 15.3636L57.825 15.3571C57.7507 15.0838 57.6756 14.8077 57.6004 14.5287H51.8205C51.5415 15.5648 51.2623 16.562 50.9834 17.5582ZM56.2053 9.14733C55.9761 8.30054 55.7468 7.45293 55.5276 6.59617H54.6507C54.3716 6.59617 54.1324 6.59617 53.8534 6.63603C53.6079 7.5958 53.3499 8.54307 53.0934 9.48484L53.093 9.48598C52.8916 10.2255 52.6909 10.9623 52.4981 11.6985C52.8967 11.8181 56.4046 11.8181 56.8829 11.6985C56.6637 10.8417 56.4344 9.99414 56.2053 9.14733Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.9137 60.8883H27.9831C26.7872 56.2643 25.5914 51.72 24.3556 47.1758H18.177C17.8183 48.0926 14.5895 60.5295 14.5895 60.8883H17.5393C17.6486 60.482 17.7519 60.088 17.8538 59.6991L17.8541 59.6979C18.0121 59.095 18.1669 58.5042 18.3365 57.8986H24.1165C24.3955 58.8951 24.6745 59.8917 24.9137 60.8883ZM19.7317 52.4973C19.9609 51.6403 20.1901 50.7833 20.4093 49.9262H22.0835C22.522 51.6403 22.9605 53.3145 23.4388 55.0684H19.054C19.2732 54.2114 19.5025 53.3544 19.7317 52.4973Z"
      fill="black"
    />
    <path
      d="M67.2869 60.8887H64.4168C64.4168 60.809 64.4068 60.7293 64.3969 60.6496C64.3869 60.5699 64.3769 60.4901 64.3769 60.4104V55.8662C64.3769 55.7067 64.3769 55.5074 64.3371 55.348C63.9385 55.2682 58.7564 55.2284 58.2781 55.348V60.809C57.9193 60.9286 56.0857 60.9286 55.408 60.8489C55.3283 60.53 55.2884 47.9735 55.3682 47.2161C55.6871 47.1364 57.4808 47.0965 58.2781 47.1763C58.298 48.0532 58.298 48.9401 58.298 49.832C58.298 50.7239 58.298 51.6208 58.3179 52.5178H64.4168V47.1364H67.3267C67.2869 51.7604 67.2869 56.3046 67.2869 60.8887Z"
      fill="black"
    />
    <path
      d="M10.2046 60.8883H13.1544C13.1544 56.3043 13.1544 51.7598 13.1145 47.1758H1.1958V60.8883H4.10572V51.4809C4.1228 51.2588 4.11792 51.0367 4.11304 50.8146C4.10653 50.5185 4.10002 50.2224 4.14558 49.9262C5.26171 49.8465 9.84582 49.8864 10.2046 50.006V57.2608C10.2046 57.658 10.2002 58.0551 10.1958 58.4538C10.1869 59.2553 10.178 60.0631 10.2046 60.8883Z"
      fill="black"
    />
    <path
      d="M72.9871 39.602H70.1171C70.0904 39.3084 70.0994 38.9969 70.1083 38.6915C70.1127 38.5409 70.1171 38.3914 70.1171 38.2467V34.1409C69.7583 34.0213 64.6161 34.0213 64.0182 34.101V39.602H61.1481C61.0684 39.2432 61.0285 26.8462 61.1083 26.0091C61.4272 25.9293 63.4203 25.8895 64.0182 26.0091V31.3107H70.0772C70.137 30.9818 70.1295 30.653 70.122 30.3241C70.1195 30.2145 70.1171 30.1049 70.1171 29.9953V26.0489C70.4758 25.9293 72.2696 25.9293 72.9871 26.0091C73.027 26.2881 73.0668 38.6851 72.9871 39.602Z"
      fill="black"
    />
    <path
      d="M80.1622 47.1758H69.8778C69.8778 51.7599 69.8778 56.3041 69.9177 60.8883C69.9644 60.8883 69.9974 60.9019 70.0247 60.9133C70.0441 60.9213 70.0606 60.9281 70.0771 60.9281H79.8831C79.923 60.9281 79.9629 60.9182 80.0027 60.9082C80.0426 60.8982 80.0825 60.8883 80.1223 60.8883L80.202 60.8085C80.2818 59.9714 80.2419 58.4567 80.1223 58.0979H72.8674C72.8276 57.1412 72.8276 56.1846 72.8674 55.188H77.2523C77.3718 55.188 77.4914 55.1905 77.611 55.193C77.9698 55.2005 78.3285 55.2079 78.6873 55.1482C78.767 54.3908 78.767 52.9956 78.6474 52.6369H73.3857C73.3059 52.6369 73.2162 52.6269 73.1265 52.6169C73.0369 52.607 72.9472 52.597 72.8674 52.597C72.7877 51.9193 72.7877 50.285 72.9073 49.8864H80.1622V47.1758Z"
      fill="black"
    />
    <path
      d="M57.4809 31.3904V33.9416H51.6212V36.8914H58.9159V39.602H48.6714V26.0091C48.9903 25.9293 58.3578 25.8895 58.9159 26.0091V28.7197H51.6212V31.3904C51.9799 31.4502 52.3387 31.4427 52.6974 31.4353C52.817 31.4328 52.9366 31.4303 53.0562 31.4303H55.9661C56.4445 31.3904 56.9228 31.3904 57.4809 31.3904Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.5774 47.1567C29.5276 47.1666 29.4778 47.1766 29.418 47.1766C29.418 51.7607 29.418 56.3049 29.4977 60.8891H32.4076V58.1386C32.4275 57.9194 32.4275 57.7002 32.4275 57.476C32.4275 57.2518 32.4275 57.0225 32.4475 56.7833H35.9553C36.8721 56.7434 37.789 56.584 38.6659 56.1455C41.9346 54.5112 42.2535 50.1662 39.224 48.0934C38.2274 47.4158 37.1113 47.1367 35.9553 47.1367H29.7369C29.6771 47.1367 29.6272 47.1467 29.5774 47.1567ZM32.5127 54.0159C32.4753 54.005 32.4336 53.993 32.3678 53.993V49.9669C32.5272 49.9271 32.6468 49.927 32.7664 49.927H35.7161C36.0749 49.927 36.3938 49.9669 36.7127 50.0466C37.6694 50.3257 38.2274 51.0432 38.2274 51.96C38.2274 52.8768 37.7491 53.5545 36.7924 53.8734C36.5134 53.9531 36.2344 54.0328 35.9553 54.0328H32.6069C32.5711 54.0328 32.5433 54.0248 32.5127 54.0159Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M85.1849 56.7825V60.8883H82.3547C82.275 60.5295 82.2351 47.973 82.3148 47.2156C82.3746 47.2156 82.4344 47.2057 82.4942 47.1957C82.554 47.1857 82.6138 47.1758 82.6736 47.1758H88.7326C89.9683 47.1758 91.1243 47.4548 92.1607 48.2122C94.8713 50.1256 94.7916 54.0719 92.0411 55.9055C91.0446 56.5433 89.9683 56.8223 88.8123 56.8223H85.7429C85.5835 56.7825 85.424 56.7825 85.1849 56.7825ZM85.2247 53.9922C85.3005 53.9922 85.3443 54.0082 85.3764 54.0199C85.3949 54.0267 85.4094 54.032 85.424 54.032H88.8123C89.2508 54.032 89.6893 53.9124 90.0879 53.6733C90.7655 53.3145 91.0844 52.7166 91.0844 51.9592C91.0844 51.2018 90.7257 50.6039 90.048 50.2451C89.6095 50.006 89.1312 49.9262 88.6529 49.9262H85.5436C85.4881 49.9262 85.4412 49.9348 85.3909 49.944C85.3329 49.9547 85.2703 49.9661 85.1849 49.9661C85.2247 51.3214 85.2247 52.6369 85.2247 53.9922Z"
      fill="black"
    />
    <path
      d="M87.8556 39.6424C86.2212 37.171 84.6268 34.8192 82.9924 32.4275C83.3013 31.9723 83.6101 31.5238 83.9163 31.0792C84.3609 30.4335 84.7998 29.7962 85.2247 29.1588C85.5092 28.732 85.8062 28.3115 86.1059 27.8874C86.5619 27.2418 87.0239 26.5877 87.457 25.8901H84.1086C83.8694 26.2289 83.6402 26.5678 83.411 26.9066C83.1818 27.2454 82.9526 27.5842 82.7134 27.9231C82.0358 28.9196 81.3581 29.9162 80.6406 30.9127C80.5609 31.0323 80.4014 31.2316 80.2818 31.2316H78.4482V25.93C77.93 25.8104 75.9369 25.8502 75.5781 25.93C75.4984 26.6873 75.5383 39.204 75.618 39.5627H78.4482V35.8556C78.4482 35.6578 78.4438 35.46 78.4394 35.2607C78.4305 34.8577 78.4215 34.4487 78.4482 34.0219C78.6388 34.0219 78.8249 34.0266 79.0062 34.0311C79.3557 34.0399 79.6879 34.0482 80.0028 34.0219C80.4014 34.0219 80.6406 34.1415 80.8399 34.4604C81.378 35.2576 81.9261 36.0549 82.4742 36.8521C83.0223 37.6494 83.5704 38.4466 84.1086 39.2438C84.3079 39.5627 84.5072 39.6424 84.8659 39.6424H87.8556Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.0671 47.1758H98.9372C98.9372 47.281 98.9483 47.3751 98.9588 47.464C98.9682 47.5434 98.9771 47.6188 98.9771 47.694V50.8829C98.9771 51.0424 98.9771 51.2417 99.0169 51.441H99.575C100.021 51.4569 100.461 51.4601 100.899 51.4633C101.558 51.4681 102.214 51.4729 102.884 51.5207C104.079 51.6403 105.156 52.0788 106.033 52.9558C106.989 53.8726 107.388 55.0286 107.348 56.344C107.268 58.5763 105.873 60.1707 103.601 60.6889C103.083 60.8085 102.525 60.8883 102.007 60.8883C100.784 60.9148 99.562 60.906 98.3397 60.8971C97.7284 60.8927 97.1168 60.8883 96.5056 60.8883C96.4009 60.9144 96.3134 60.9062 96.2204 60.8975C96.1719 60.893 96.1218 60.8883 96.0671 60.8883V47.1758ZM98.9771 58.1378C99.0429 58.1378 99.0966 58.1499 99.145 58.1607C99.1844 58.1696 99.2203 58.1777 99.2561 58.1777H102.086C102.325 58.1777 102.565 58.1378 102.804 58.0979C103.721 57.8986 104.279 57.1811 104.279 56.2643C104.279 55.3475 103.76 54.6299 102.844 54.4306C102.565 54.3509 102.286 54.3111 102.007 54.3111H99.3358C99.276 54.3111 99.2262 54.321 99.1764 54.331C99.1265 54.341 99.0767 54.3509 99.0169 54.3509C98.9771 55.5866 98.9771 56.8622 98.9771 58.1378Z"
      fill="black"
    />
    <path
      d="M86.1017 3.88574H79.8832C79.3506 5.99784 78.8095 8.09301 78.2678 10.1908C77.6337 12.6458 76.9987 15.1048 76.3754 17.5982H79.3251C80.0847 14.5601 80.8716 11.5492 81.6632 8.52046C81.8271 7.89328 81.9912 7.26533 82.1553 6.63621C82.7533 6.59635 83.2715 6.59635 83.8295 6.63621C84.2371 8.19845 84.6374 9.75346 85.0366 11.3043L85.0375 11.3079C85.5752 13.3964 86.1109 15.4774 86.6597 17.5584C87.1779 17.6381 89.2109 17.6381 89.6095 17.5185C89.0316 15.2466 88.4437 12.9747 87.8558 10.7028C87.2678 8.43068 86.6797 6.15788 86.1017 3.88574Z"
      fill="black"
    />
    <path
      d="M9.7263 25.9297C10.2382 27.9419 10.7579 29.9542 11.2785 31.9699L11.2788 31.9711C11.9338 34.507 12.5901 37.0485 13.2341 39.6023H10.2844C9.80603 37.7886 9.33766 35.9749 8.86929 34.1613C8.40091 32.3475 7.93252 30.5338 7.45417 28.72H5.77997C5.35646 30.3258 4.94075 31.9317 4.52595 33.534C4.00375 35.5513 3.48294 37.5631 2.94978 39.5624C2.59102 39.682 0.478342 39.682 0 39.5624C1.15599 35.0182 2.31199 30.5138 3.50785 25.9695C5.58066 25.9297 7.61362 25.9297 9.7263 25.9297Z"
      fill="black"
    />
    <path
      d="M42.0941 49.8864H46.4789C46.4789 53.5935 46.4789 57.221 46.5188 60.9281C47.0769 61.0477 49.1098 61.0078 49.4686 60.8883V51.3214C49.4686 51.1834 49.4619 51.0487 49.4554 50.9154C49.4392 50.5868 49.4234 50.2665 49.5084 49.9262C50.1414 49.9091 50.767 49.9141 51.3884 49.919C52.2149 49.9255 53.0343 49.9319 53.8534 49.8864V47.1758H42.0941V49.8864Z"
      fill="black"
    />
    <path
      d="M108.783 47.1758H111.613V60.8883H108.783L108.703 60.8085C108.703 60.753 108.695 60.6975 108.686 60.6381C108.675 60.5694 108.663 60.4954 108.663 60.4099V47.2555C108.703 47.2555 108.743 47.2555 108.783 47.1758Z"
      fill="black"
    />
  </svg>
)

export default CimajloLyashenkoPartnyoryLogo
