import React from 'react'

const RbiWhiteLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="162"
    height="24"
    viewBox="0 0 162 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_29202_115287)">
      <path
        d="M9.13581 16.6034C9.12894 16.6072 17.1308 24 17.1429 24H12.7979L5.39086 17.1524L5.46259 16.9717C9.42359 16.907 12.4938 13.683 12.4938 8.62055C12.4938 3.57773 9.39009 0.245396 5.41277 0.200856V0.202141H4.16889V24H0V0L5.41277 0.00171306C11.2465 0.0342612 15.5408 3.30706 15.5408 8.46252C15.5408 12.4137 12.9078 15.2766 9.13581 16.6034Z"
        fill="white"
      />
      <path
        d="M60 0H66V0.767738H63.6481V9.42857H62.3772V0.767738H60V0Z"
        fill="white"
      />
      <path
        d="M67.7158 0H69.0196V3.44833H73.2696V0H74.573V9.42857H73.2696V4.21564H69.0196V9.42857H67.7158V0Z"
        fill="white"
      />
      <path
        d="M84.001 0H78.001V9.42857H84.001V8.66083H79.4094V4.20224H83.2967V3.4345H79.4094V0.767738H84.001V0Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.2861 9.42857L91.924 1.18532L91.3612 0H92.9059L96.8576 9.42857H95.3658L94.7509 7.94669H89.7258L89.0845 9.42857H88.2861ZM90.0668 7.17799H94.4375L92.3309 2.01953L90.0668 7.17799Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.428 9.42857L102.669 5.9403C104.117 5.60353 105.212 4.5526 105.212 3.03089C105.212 1.02369 103.657 0 101.642 0H98.5713V9.42857H99.9239V6.04794H101.209L103.805 9.42857H105.428ZM101.288 5.37293H99.9229V0.671619C100.051 0.671619 100.179 0.670699 100.307 0.669782C101.271 0.662854 102.205 0.656145 102.79 1.04902C103.358 1.42599 103.656 2.08612 103.656 3.01557C103.656 4.3354 102.831 5.37293 101.288 5.37293Z"
        fill="white"
      />
      <path
        d="M106.285 0H112.285V0.767738H109.933V9.42857H108.662V0.767738H106.285V0Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.2879 18.4542C70.2879 15.8638 68.3805 13.7139 65.5601 13.7139C62.7664 13.7139 60.8594 15.8638 60.8594 18.4542C60.8594 21.0184 62.7664 23.1425 65.5601 23.1425C68.3805 23.1425 70.2879 21.0184 70.2879 18.4542ZM68.7154 18.454C68.7154 20.5906 67.7757 22.4947 65.5592 22.4947C63.3431 22.4947 62.4297 20.5906 62.4297 18.454C62.4297 16.3167 63.3431 14.3614 65.5592 14.3614C67.7757 14.3614 68.7154 16.446 68.7154 18.454Z"
        fill="white"
      />
      <path
        d="M72 13.7139H78V14.4817H73.4086V17.1484H77.2957V17.9162H73.4086V23.1425H72V13.7139Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.0017 23.1425L87.2427 19.6542C88.6899 19.3175 89.7855 18.2665 89.7855 16.7448C89.7855 14.7376 88.2298 13.7139 86.2148 13.7139H83.1445V23.1425H84.4971V19.7619H85.782L88.3787 23.1425H90.0017ZM85.8621 19.0869H84.4961V14.3855C84.624 14.3855 84.7521 14.3845 84.8797 14.3836C85.8444 14.3767 86.7782 14.37 87.3632 14.7629C87.9314 15.1399 88.2287 15.8 88.2287 16.7295C88.2287 18.0493 87.4036 19.0869 85.8621 19.0869Z"
        fill="white"
      />
      <path
        d="M91.7158 13.7139H97.7158V14.4817H93.1243V17.1484H97.0116V17.9162H93.1243V22.3748H97.7158V23.1425H91.7158V13.7139Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.21 14.8989L98.5713 23.1425H99.3697L100.011 21.661H105.036L105.651 23.1425H107.143L103.191 13.7139H101.647L102.21 14.8989ZM104.723 20.8933H100.352L102.616 15.7342L104.723 20.8933Z"
        fill="white"
      />
      <path
        d="M108.858 13.7139H110.267V22.3748H114.858V23.1425H108.858V13.7139Z"
        fill="white"
      />
      <path
        d="M125.144 13.7139H119.144V23.1425H125.144V22.3748H120.552V17.9162H124.439V17.1484H120.552V14.4817H125.144V13.7139Z"
        fill="white"
      />
      <path
        d="M131.261 15.1517C130.626 14.6982 129.888 14.4393 129.214 14.4393C128.631 14.4393 128.008 14.5947 128.008 15.2423C128.008 15.5532 128.164 15.9157 128.644 16.4594L130.912 18.933C131.56 19.6588 132 20.4228 132 21.1739C132 22.5597 130.652 23.1425 129.382 23.1425C127.931 23.1425 126.868 22.5082 126 21.4981L126.492 20.954C127.179 21.718 128.164 22.3008 129.11 22.3008C130.017 22.3008 130.561 21.8473 130.561 21.161C130.561 20.6169 130.108 19.9951 129.836 19.6979L127.607 17.2238C127.088 16.6539 126.57 15.9677 126.57 15.1904C126.57 14.517 127.166 13.7139 128.89 13.7139C129.965 13.7139 130.989 14.0248 131.754 14.6725L131.261 15.1517Z"
        fill="white"
      />
      <path
        d="M138.855 13.7139H132.855V14.4817H135.232V23.1425H136.504V14.4817H138.855V13.7139Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.714 23.1425L143.352 14.8989L142.789 13.7139H144.333L148.285 23.1425H146.794L146.178 21.661H141.153L140.512 23.1425H139.714ZM141.494 20.8933H145.865L143.758 15.7342L141.494 20.8933Z"
        fill="white"
      />
      <path
        d="M154.285 13.7139H148.285V14.4817H150.662V23.1425H151.933V14.4817H154.285V13.7139Z"
        fill="white"
      />
      <path
        d="M156.001 13.7139H162.001V14.4817H157.409V17.1484H161.297V17.9162H157.409V22.3748H162.001V23.1425H156.001V13.7139Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.6669 10.5601C33.2234 9.74564 34.4849 7.50878 34.4849 5.45139C34.4849 1.85182 31.5023 0 27.3196 0H20.5703V24H27.66C32.8743 24 35.9989 21.128 35.9989 17.1507C35.9989 14.7164 34.3929 11.4068 29.6669 10.5601ZM26.3549 23.7676L24.6493 23.768V0.202314L26.4145 0.210884C30.1123 0.210884 32.0894 3.0173 32.0894 5.41773C32.0894 8.01859 30.601 10.2018 27.8983 10.4845V10.6789C32.0024 11.1594 32.7155 15.1577 32.7155 17.2117C32.7155 19.4057 31.6452 23.7676 26.3549 23.7676Z"
        fill="white"
      />
      <path d="M41.1445 24V0L45.4302 0.0012848V24H41.1445Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_29202_115287">
        <rect width="162" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default RbiWhiteLogo
