import React from 'react'

const BmsWhiteLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="170"
    height="36"
    viewBox="0 0 170 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color="white"
    {...props}
  >
    <g clipPath="url(#clip0_29202_115064)">
      <path
        d="M52.2118 15.9086L48.3387 17.6113C49.0778 19.3799 50.1729 20.7129 51.6287 21.6192C53.0755 22.5212 54.843 22.9743 56.9264 22.9743C59.3215 22.9743 61.2092 22.3804 62.5803 21.1969C63.9515 20.0134 64.6371 18.3856 64.6371 16.3178C64.6371 14.7823 64.2453 13.5374 63.4573 12.5826C62.6693 11.6279 61.1824 10.6952 58.9966 9.78015C58.538 9.59093 57.9058 9.3402 57.1045 9.02782C54.4912 8.0115 53.1868 6.93804 53.1868 5.80297C53.1868 5.06824 53.454 4.4875 53.9882 4.06075C54.5224 3.634 55.2481 3.41843 56.174 3.41843C57.1267 3.41843 57.9326 3.6472 58.5825 4.10475C59.2325 4.5623 59.7978 5.29262 60.2742 6.29571L64.1473 4.89667C63.5508 3.28204 62.607 2.05897 61.3204 1.23626C60.0338 0.413555 58.4267 0 56.4946 0C54.2864 0 52.5012 0.563138 51.1434 1.68502C49.7722 2.8157 49.0911 4.28513 49.0911 6.09333C49.0911 7.15801 49.3181 8.11267 49.7766 8.9574C50.2352 9.7977 50.8941 10.4929 51.7578 11.034C52.6303 11.5971 54.1796 12.2791 56.4055 13.0842C56.7306 13.1942 56.9799 13.2865 57.1534 13.357C58.4222 13.8189 59.2992 14.2853 59.789 14.7516C60.2742 15.2223 60.5191 15.8031 60.5191 16.4938C60.5191 17.3693 60.1852 18.06 59.5263 18.5748C58.8674 19.0895 57.9681 19.3447 56.8285 19.3447C55.8224 19.3447 54.9497 19.0675 54.2152 18.522C53.4807 17.9764 52.8129 17.1053 52.2118 15.9086Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 22.6704V0.241699H8.23157C10.7113 0.241699 12.6033 0.747643 13.9033 1.76394C15.2032 2.78022 15.8532 4.25406 15.8532 6.19425C15.8532 7.28093 15.5861 8.20045 15.0518 8.95713C14.5176 9.71387 13.6807 10.3694 12.5455 10.9237C13.8276 11.3549 14.7848 12.028 15.4258 12.9343C16.0669 13.845 16.3874 14.9889 16.3874 16.3747C16.3874 18.4953 15.6974 20.0747 14.3217 21.113C12.9461 22.1513 10.8448 22.6704 8.01785 22.6704H0ZM4.17588 9.22113H7.66616C8.99725 9.22113 9.99448 8.98795 10.6534 8.52602C11.3123 8.06402 11.6461 7.37332 11.6461 6.44942C11.6461 5.48592 11.3167 4.7864 10.6534 4.34645C9.99448 3.9109 8.91711 3.69092 7.4213 3.69092H4.17588V9.22113ZM4.17588 19.2213H7.66616C9.21096 19.2213 10.3373 18.9528 11.0407 18.4161C11.7485 17.8794 12.1003 17.0214 12.1003 15.8468C12.1003 14.7513 11.753 13.933 11.054 13.3919C10.3595 12.8507 9.29111 12.578 7.86204 12.578H4.17588V19.2213Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.423 0.567264C121.901 0.351687 121.336 0.241699 120.722 0.241699C120.104 0.241699 119.538 0.347287 119.017 0.571663C118.501 0.791635 118.051 1.09961 117.673 1.49556C117.294 1.89592 116.996 2.37546 116.786 2.9386C116.578 3.50175 116.471 4.13088 116.471 4.83039C116.471 5.52112 116.573 6.15465 116.782 6.72219C116.991 7.29413 117.285 7.78248 117.659 8.18725C118.033 8.59195 118.482 8.90875 119.004 9.13315C119.524 9.35748 120.099 9.47192 120.722 9.47192C121.345 9.47192 121.92 9.35748 122.436 9.13315C122.953 8.90875 123.402 8.59195 123.776 8.18283C124.155 7.7737 124.444 7.28533 124.653 6.71779C124.862 6.14586 124.964 5.51672 124.964 4.83039C124.964 4.13527 124.858 3.50614 124.649 2.943C124.435 2.37987 124.141 1.89592 123.767 1.49556C123.393 1.0908 122.943 0.782838 122.423 0.567264ZM122.147 8.55243C121.71 8.72838 121.234 8.81635 120.717 8.81635C120.201 8.81635 119.725 8.72838 119.293 8.55243C118.857 8.3764 118.482 8.12125 118.167 7.77805C117.85 7.43931 117.606 7.02135 117.432 6.52421C117.258 6.02706 117.169 5.46393 117.169 4.83039C117.169 4.19687 117.258 3.63812 117.437 3.14978C117.614 2.66143 117.864 2.25228 118.18 1.91792C118.496 1.58355 118.875 1.32838 119.31 1.1568C119.742 0.985218 120.223 0.897228 120.744 0.897228C121.256 0.897228 121.724 0.980815 122.156 1.1524C122.587 1.32398 122.957 1.57476 123.273 1.90911C123.589 2.24348 123.834 2.65263 124.012 3.14098C124.19 3.62933 124.279 4.19246 124.279 4.83039C124.279 5.46393 124.19 6.02706 124.017 6.52421C123.843 7.01696 123.594 7.43491 123.277 7.77805C122.962 8.11683 122.583 8.3764 122.147 8.55243Z"
        fill="white"
      />
      <path
        d="M84.2298 9.30087H90.73V8.65415H84.884V4.99812H90.1555V4.347H84.884V1.05615H90.5114V0.405029H84.2298V9.30087Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.7075 9.30087C80.7394 9.30087 82.2535 7.79622 82.2535 4.78254C82.2535 3.3175 81.8748 2.22202 81.1227 1.5005C80.3653 0.774582 79.2261 0.413818 77.7075 0.413818H74.9877V9.30087H77.7075ZM81.3762 3.21191C81.4961 3.67386 81.5545 4.1974 81.5545 4.78254C81.5545 5.39847 81.4961 5.93961 81.3716 6.41476C81.2517 6.88991 81.0422 7.29466 80.7485 7.62905C80.4503 7.9634 80.0541 8.21855 79.5601 8.39015C79.066 8.56175 78.4421 8.64972 77.69 8.64972H75.6422V1.05175H77.69C78.4467 1.05175 79.0743 1.13534 79.5684 1.30252C80.067 1.4697 80.4639 1.71607 80.7576 2.03724C81.0514 2.3584 81.2608 2.74996 81.3762 3.21191Z"
        fill="white"
      />
      <path
        d="M99.7801 0.405029L96.2496 9.30087H95.4041L91.8782 0.405029H92.6082L95.8314 8.49132L99.0546 0.405029H99.7801Z"
        fill="white"
      />
      <path
        d="M101.285 9.30087H107.789V8.65415H101.944V4.99812H107.215V4.347H101.944V1.05615H107.567V0.405029H101.285V9.30087Z"
        fill="white"
      />
      <path
        d="M109.828 9.30087V0.405029H110.487V8.65415H115.135V9.30087H109.828Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.138 0.405029C130.578 0.405029 130.997 0.440225 131.402 0.510617C131.807 0.58101 132.164 0.708596 132.475 0.897777C132.787 1.08255 133.032 1.33773 133.214 1.65889C133.397 1.98006 133.49 2.38481 133.49 2.87756C133.49 3.74866 133.209 4.41299 132.658 4.87054C132.101 5.32369 131.286 5.55246 130.218 5.55246H127.52V9.30522H126.861V0.405029H130.138ZM130.244 4.89693C130.703 4.89693 131.095 4.85294 131.42 4.76495C131.745 4.67696 132.007 4.54938 132.212 4.38219C132.417 4.21501 132.568 4.00824 132.662 3.76186C132.756 3.51549 132.8 3.22952 132.8 2.90395C132.8 2.508 132.729 2.18683 132.591 1.94486C132.453 1.70289 132.261 1.51371 132.025 1.38172C131.785 1.24974 131.513 1.16175 131.197 1.11775C130.886 1.07376 130.556 1.05176 130.209 1.05176H127.52V4.89693H130.244Z"
        fill="white"
      />
      <path
        d="M143.115 9.30087H143.778V0.405029H142.839L139.531 8.49132L136.25 0.405029H135.297V9.30087H135.957V1.46531L139.188 9.30087H139.878L143.115 1.46971V9.30087Z"
        fill="white"
      />
      <path
        d="M152.567 9.30087H146.062V0.405029H152.344V1.05615H146.717V4.347H151.988V4.99812H146.717V8.65415H152.567V9.30087Z"
        fill="white"
      />
      <path
        d="M160.655 9.30087H161.519V0.405029H160.86V8.51772L155.407 0.405029H154.503V9.30087H155.162V1.17495L160.655 9.30087Z"
        fill="white"
      />
      <path
        d="M170 1.05615H166.853V9.30522H166.194V1.05615H163.077V0.405029H170V1.05615Z"
        fill="white"
      />
      <path
        d="M77.5345 22.3365C78.0597 22.5609 78.6471 22.6709 79.2884 22.6709C79.6041 22.6709 79.916 22.6488 80.2279 22.6005C80.5345 22.5521 80.8328 22.4729 81.1136 22.3673C81.3944 22.2617 81.6608 22.1253 81.915 21.9537C82.1639 21.7821 82.3954 21.5754 82.6049 21.3246L82.894 22.5125H83.2727V18.179H80.13V18.8301H82.614V20.4227C82.2353 20.9199 81.7723 21.3114 81.2251 21.593C80.6772 21.8745 80.0276 22.0153 79.2838 22.0153C78.7495 22.0153 78.2646 21.9273 77.8236 21.7514C77.3827 21.5754 77.0047 21.3202 76.6883 20.977C76.3771 20.6383 76.1274 20.2203 75.9536 19.7232C75.7802 19.2261 75.6911 18.6629 75.6911 18.0294C75.6911 17.3914 75.7802 16.8239 75.9536 16.3356C76.1274 15.8428 76.3726 15.4337 76.6883 15.0993C77.0047 14.7649 77.3781 14.5142 77.8191 14.3469C78.2555 14.1798 78.7404 14.0962 79.2663 14.0962C79.6936 14.0962 80.0852 14.149 80.4412 14.2502C80.8017 14.3514 81.1136 14.4921 81.3807 14.6637C81.6479 14.8397 81.8657 15.0421 82.0349 15.2709C82.1996 15.4996 82.3066 15.746 82.3514 16.0056H83.0587C82.9745 15.6228 82.8234 15.2709 82.6049 14.9585C82.3825 14.6418 82.1063 14.3733 81.7723 14.149C81.4384 13.9246 81.0559 13.753 80.6286 13.6299C80.2006 13.5066 79.7422 13.4451 79.2481 13.4451C78.6159 13.4451 78.0414 13.5507 77.5208 13.7662C77.0002 13.9818 76.5501 14.2898 76.1768 14.6857C75.798 15.0861 75.5086 15.5656 75.2994 16.1288C75.0901 16.6919 74.9877 17.3254 74.9877 18.0294C74.9877 18.7289 75.0901 19.3624 75.295 19.9344C75.4997 20.5063 75.7936 20.9946 76.1722 21.3994C76.5501 21.7998 77.0047 22.1121 77.5345 22.3365Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.5992 22.5079H91.8737L90.9789 20.141C90.8901 19.9122 90.7922 19.701 90.6852 19.4987C90.5782 19.2962 90.4537 19.1247 90.311 18.9751C90.1683 18.8255 90.0037 18.7067 89.8215 18.6231C89.6386 18.5351 89.4208 18.4912 89.1757 18.4912H86.0284V22.5079H85.3696V13.6121H89.4254C89.8033 13.6121 90.1683 13.6473 90.525 13.7133C90.8764 13.7792 91.1929 13.9025 91.4684 14.0741C91.7446 14.2456 91.967 14.4876 92.1317 14.7911C92.2964 15.0992 92.3768 15.4907 92.3768 15.9659C92.3768 16.265 92.3412 16.5422 92.2653 16.7973C92.1901 17.0569 92.0786 17.2857 91.9275 17.4881C91.7758 17.6904 91.5845 17.8664 91.353 18.0072C91.1216 18.1524 90.8453 18.2536 90.5334 18.3196C90.7026 18.4516 90.8453 18.5747 90.9523 18.6935C91.0639 18.8123 91.1572 18.9399 91.2369 19.0763C91.3174 19.2127 91.3887 19.3622 91.4509 19.5251C91.5132 19.6878 91.5845 19.8726 91.6687 20.0838L92.5992 22.5079ZM86.0375 17.8445H89.1537C89.5991 17.8445 89.9771 17.8136 90.2981 17.7476C90.6184 17.6816 90.8764 17.576 91.0859 17.4265C91.2908 17.2769 91.4418 17.0833 91.5443 16.8458C91.6422 16.6082 91.6915 16.3134 91.6915 15.9659C91.6915 15.3543 91.4957 14.9144 91.1033 14.6548C90.7117 14.3908 90.1289 14.2588 89.3495 14.2588H86.0375V17.8445Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.114 13.775C99.5934 13.5594 99.0281 13.4495 98.4133 13.4495C97.7948 13.4495 97.2248 13.5551 96.7088 13.7794C96.1919 13.9994 95.7426 14.3073 95.3639 14.7033C94.9852 15.1037 94.687 15.5832 94.4783 16.1463C94.2688 16.7095 94.1618 17.3386 94.1618 18.0381C94.1618 18.7289 94.2643 19.3624 94.4737 19.9299C94.6832 20.5019 94.9769 20.9902 95.3503 21.395C95.7244 21.7997 96.1745 22.1165 96.6951 22.3409C97.2157 22.5653 97.7902 22.6796 98.4133 22.6796C99.0372 22.6796 99.6109 22.5653 100.128 22.3409C100.644 22.1165 101.094 21.7997 101.467 21.3905C101.846 20.9814 102.135 20.4931 102.345 19.9256C102.554 19.3536 102.656 18.7244 102.656 18.0381C102.656 17.343 102.549 16.7139 102.34 16.1507C102.127 15.5876 101.832 15.1037 101.459 14.7033C101.085 14.2985 100.635 13.9906 100.114 13.775ZM99.8295 21.7601C99.3976 21.9361 98.921 22.0241 98.405 22.0241C97.8881 22.0241 97.4161 21.9361 96.9759 21.7601C96.5395 21.5842 96.1654 21.329 95.8489 20.9858C95.5332 20.6471 95.288 20.2291 95.115 19.732C94.9412 19.2348 94.8517 18.6716 94.8517 18.0381C94.8517 17.4046 94.9412 16.8458 95.1188 16.3575C95.2972 15.8692 95.5468 15.46 95.8626 15.1256C96.179 14.7913 96.557 14.5361 96.9934 14.3645C97.4297 14.1929 97.9064 14.105 98.427 14.105C98.9385 14.105 99.406 14.1886 99.8378 14.3601C100.266 14.5317 100.639 14.7825 100.956 15.1169C101.267 15.4512 101.517 15.8604 101.695 16.3487C101.873 16.8371 101.962 17.4002 101.962 18.0381C101.962 18.6716 101.873 19.2348 101.699 19.732C101.526 20.2247 101.276 20.6426 100.96 20.9858C100.644 21.3246 100.266 21.5842 99.8295 21.7601Z"
        fill="white"
      />
      <path
        d="M107.994 22.6707C107.455 22.6707 106.997 22.6135 106.618 22.5035C106.236 22.3935 105.919 22.244 105.67 22.0547C105.417 21.8656 105.216 21.6412 105.069 21.3905C104.922 21.1352 104.811 20.8669 104.731 20.5766C104.655 20.2906 104.606 19.9914 104.589 19.6834C104.57 19.3754 104.561 19.0719 104.561 18.7815V13.6121H105.221V18.7551C105.221 19.4327 105.283 19.9826 105.408 20.4093C105.532 20.8361 105.71 21.1661 105.951 21.4037C106.191 21.6412 106.481 21.804 106.823 21.892C107.166 21.98 107.558 22.024 108.003 22.024C108.452 22.024 108.853 21.98 109.201 21.892C109.547 21.804 109.841 21.6412 110.073 21.3992C110.305 21.1573 110.482 20.8273 110.603 20.405C110.723 19.9826 110.785 19.4327 110.785 18.7551V13.6121H111.444V18.7815C111.444 19.3931 111.391 19.943 111.288 20.4225C111.186 20.9065 111.003 21.3113 110.745 21.6456C110.487 21.98 110.136 22.2351 109.69 22.4111C109.236 22.5827 108.675 22.6707 107.994 22.6707Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.053 13.6121H113.777V22.5123H114.436V18.7595H117.134C118.202 18.7595 119.017 18.5308 119.574 18.0776C120.125 17.6201 120.406 16.9557 120.406 16.0846C120.406 15.5918 120.312 15.1871 120.13 14.866C119.947 14.5448 119.703 14.2896 119.391 14.1048C119.079 13.9157 118.723 13.7881 118.318 13.7177C117.913 13.6473 117.494 13.6121 117.053 13.6121ZM117.16 18.104H114.436V14.2588H117.125C117.472 14.2588 117.802 14.2808 118.113 14.3248C118.429 14.3688 118.701 14.4568 118.942 14.5888C119.177 14.7208 119.369 14.9099 119.507 15.1519C119.644 15.3939 119.716 15.7151 119.716 16.111C119.716 16.4366 119.672 16.7225 119.578 16.9689C119.484 17.2153 119.333 17.4221 119.128 17.5892C118.923 17.7564 118.661 17.884 118.336 17.972C118.011 18.06 117.619 18.104 117.16 18.104Z"
        fill="white"
      />
      <path
        d="M22.1392 22.6704H17.3757L25.7631 0.241699H30.5266L22.1392 22.6704Z"
        fill="white"
      />
      <path
        d="M25.7854 22.6704H30.549L38.9408 0.241699H34.1773L25.7854 22.6704Z"
        fill="white"
      />
      <path
        d="M47.2969 22.6705L40.5745 4.69849L38.2729 11.2845L42.5334 22.6705H47.2969Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6982 31.4694C23.9743 31.6513 24.1093 31.9262 24.1095 32.2796C24.1118 32.5491 24.0272 32.7752 23.848 32.9453C23.7685 33.0206 23.6729 33.0827 23.5625 33.1326C23.7199 33.1876 23.8562 33.2653 23.9697 33.367C24.1884 33.563 24.292 33.8239 24.292 34.1367C24.292 34.4998 24.1508 34.7837 23.8628 34.9709C23.5834 35.1526 23.1784 35.2347 22.6645 35.2347H21.2685V31.2129H22.5132C23.0282 31.2129 23.4297 31.2925 23.6982 31.4694ZM21.6565 32.9903H22.5399C22.9678 32.9903 23.2705 32.9227 23.4629 32.8026C23.6466 32.6879 23.7393 32.5196 23.7393 32.2801C23.7393 32.0221 23.6474 31.8492 23.4662 31.7321C23.2802 31.6135 22.9688 31.5435 22.5176 31.5435H21.6565V32.9903ZM21.6609 34.9085H22.669C23.1119 34.9085 23.425 34.8356 23.6243 34.7064C23.8154 34.5826 23.9129 34.3999 23.9129 34.1411C23.9129 33.8523 23.8078 33.6579 23.6096 33.5321C23.3967 33.3998 23.0493 33.3253 22.5443 33.3253H21.6609V34.9085Z"
        fill="white"
      />
      <path
        d="M27.7861 31.2122V33.5376C27.7861 34.0985 27.9054 34.5396 28.1642 34.8416C28.4262 35.1473 28.8142 35.2911 29.3068 35.2911C29.7974 35.2911 30.1843 35.1459 30.4456 34.8398C30.7037 34.5374 30.8229 34.0964 30.8229 33.5376V31.2122H30.4394V33.5376C30.4394 34.0379 30.3368 34.3919 30.1519 34.6193C29.9706 34.8419 29.6957 34.9606 29.3068 34.9606C28.9153 34.9606 28.6394 34.8418 28.4577 34.6191C28.2722 34.3919 28.1696 34.038 28.1696 33.5376V31.2122H27.7861Z"
        fill="white"
      />
      <path
        d="M34.6865 35.2384V31.2122H35.0611V35.2384H34.6865Z"
        fill="white"
      />
      <path
        d="M39.3796 34.9078V31.2122H38.9961V35.2384H41.4809V34.9078H39.3796Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.0549 31.2122C46.7316 31.2122 47.2614 31.3844 47.6111 31.7444C47.9574 32.1056 48.1231 32.6075 48.1231 33.2341C48.1231 33.8811 47.9558 34.388 47.6009 34.7321C47.2465 35.0758 46.7241 35.2384 46.0549 35.2384H44.8592V31.2122H46.0549ZM45.2427 34.9078H46.0549C46.6337 34.9078 47.0468 34.7638 47.3163 34.4951C47.5858 34.2262 47.7307 33.8132 47.7307 33.2341C47.7307 32.6807 47.5889 32.267 47.3199 31.977C47.0554 31.6919 46.6422 31.5428 46.0549 31.5428H45.2427V34.9078Z"
        fill="white"
      />
      <path
        d="M53.9112 33.0486L53.9065 33.0452C53.8379 32.9978 53.7598 32.981 53.681 32.981C53.6005 32.981 53.5184 32.9966 53.4508 33.0486C53.389 33.0962 53.3446 33.1664 53.3446 33.2518C53.3446 33.3435 53.3907 33.4088 53.4508 33.455C53.5184 33.5069 53.6005 33.5227 53.681 33.5227C53.7652 33.5227 53.8385 33.5054 53.9065 33.4583C53.9822 33.4061 54.0174 33.3317 54.0174 33.2518C54.0174 33.1601 53.9713 33.0948 53.9112 33.0486Z"
        fill="white"
      />
      <path
        d="M59.2433 35.2385V31.2122H59.3947V31.0393L59.5016 31.2122H59.6655L61.2625 33.7685L62.8172 31.2659L62.8506 31.2122H63.0137L63.126 31.0305V31.2122H63.2818V35.2385H62.8983V31.8303L61.4289 34.2089H61.2661L61.2626 34.2149L61.2591 34.2089H61.092L59.6224 31.8385V35.2385H59.2433Z"
        fill="white"
      />
      <path
        d="M67.1498 31.2122V33.5376C67.1498 34.0985 67.269 34.5396 67.5279 34.8416C67.7899 35.1473 68.1779 35.2911 68.6704 35.2911C69.1611 35.2911 69.5479 35.1459 69.8092 34.8398C70.0673 34.5374 70.1866 34.0964 70.1866 33.5376V31.2122H69.8031V33.5376C69.8031 34.0379 69.7005 34.3919 69.5155 34.6193C69.3343 34.8419 69.0594 34.9606 68.6704 34.9606C68.279 34.9606 68.0031 34.8418 67.8214 34.6191C67.6359 34.3919 67.5333 34.038 67.5333 33.5376V31.2122H67.1498Z"
        fill="white"
      />
      <path
        d="M74.4337 34.9078H76.5349V35.2384H74.0502V31.2122H74.4337V34.9078Z"
        fill="white"
      />
      <path
        d="M81.6993 31.2122H78.7425V31.5428H80.0335V35.2384H80.4167V31.5428H81.6993V31.2122Z"
        fill="white"
      />
      <path
        d="M85.0196 35.2384V31.2122H85.3945V35.2384H85.0196Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.713 33.5577H90.4272C90.9865 33.5577 91.4198 33.4694 91.7067 33.2739C92.0012 33.0735 92.1431 32.7719 92.1431 32.3806C92.1431 31.9854 92.0012 31.6829 91.706 31.4865C91.4191 31.2959 90.9857 31.2122 90.4272 31.2122H89.329V35.2384H89.713V33.5577ZM89.713 33.2228V31.5383H90.4272C90.9243 31.5383 91.2597 31.6133 91.4578 31.7474C91.6475 31.8763 91.7507 32.0797 91.7507 32.3762C91.7507 32.6673 91.6483 32.8715 91.4525 33.0052L91.4517 33.006C91.265 33.137 90.9698 33.2142 90.541 33.2257V33.2228H89.713Z"
        fill="white"
      />
      <path
        d="M96.0835 34.9078H98.185V35.2384H95.7002V31.2122H96.0835V34.9078Z"
        fill="white"
      />
      <path
        d="M103.28 31.2122L103.248 31.2684L102.028 33.3558L100.805 31.2683L100.772 31.2122H100.33L101.83 33.7485V35.2384H102.213V33.7487L103.724 31.2122H103.28Z"
        fill="white"
      />
      <path
        d="M108.932 33.0452L108.937 33.0486C108.997 33.0948 109.043 33.1601 109.043 33.2518C109.043 33.3373 108.998 33.4074 108.937 33.455C108.869 33.5069 108.787 33.5227 108.707 33.5227C108.626 33.5227 108.543 33.5069 108.476 33.455C108.416 33.4088 108.37 33.3435 108.37 33.2518C108.37 33.1664 108.414 33.0962 108.476 33.0486C108.543 32.9966 108.626 32.981 108.707 32.981C108.785 32.981 108.863 32.9978 108.932 33.0452Z"
        fill="white"
      />
      <path
        d="M114.245 35.0139L114.246 35.0144C114.542 35.1984 114.884 35.2912 115.277 35.2912C115.663 35.2912 115.992 35.2085 116.263 35.0415C116.55 34.8716 116.696 34.6152 116.696 34.29C116.696 34.1224 116.656 33.961 116.568 33.818C116.483 33.6802 116.364 33.5582 116.212 33.4614C116.069 33.3653 115.931 33.2925 115.807 33.2339C115.682 33.1743 115.531 33.1156 115.361 33.0538L115.36 33.0534C115.007 32.9284 114.744 32.7886 114.562 32.641C114.392 32.5041 114.309 32.3338 114.309 32.1122C114.309 31.9151 114.39 31.7653 114.545 31.6592C114.707 31.5487 114.936 31.4856 115.246 31.4856C115.558 31.4856 115.8 31.5564 115.981 31.6845C116.151 31.805 116.241 31.9836 116.241 32.2442V32.3727L116.6 32.3253L116.602 32.2283C116.607 31.9027 116.48 31.6401 116.219 31.4423C115.967 31.2519 115.638 31.1594 115.246 31.1594C114.866 31.1594 114.552 31.2374 114.305 31.4032L114.305 31.4039C114.056 31.5742 113.926 31.8153 113.926 32.1166C113.926 32.4137 114.043 32.6645 114.272 32.8615L114.273 32.8622C114.497 33.0499 114.808 33.2137 115.205 33.3654L115.209 33.3669C115.374 33.4226 115.508 33.4783 115.618 33.5259C115.718 33.5716 115.834 33.6345 115.958 33.7154C116.077 33.7924 116.161 33.8729 116.216 33.9679C116.273 34.066 116.304 34.1698 116.304 34.2856C116.304 34.501 116.213 34.6593 116.027 34.7756C115.831 34.8976 115.583 34.9606 115.268 34.9606C114.944 34.9606 114.679 34.8859 114.462 34.7347C114.255 34.5914 114.149 34.3813 114.144 34.0861L114.143 33.9682L113.767 33.9907L113.77 34.099C113.779 34.5029 113.936 34.8184 114.245 35.0139Z"
        fill="white"
      />
      <path
        d="M122.808 31.5428H120.671V32.9808H122.697V33.3113H120.671V34.9078H122.808V35.2384H120.283V31.2122H122.808V31.5428Z"
        fill="white"
      />
      <path
        d="M128.715 31.468L128.714 31.4675C128.435 31.2627 128.103 31.1594 127.725 31.1594C127.188 31.1594 126.758 31.3427 126.443 31.7196C126.132 32.0938 125.986 32.6019 125.986 33.2341C125.986 33.862 126.132 34.3661 126.444 34.7359C126.758 35.108 127.188 35.2912 127.725 35.2912C128.106 35.2912 128.436 35.1931 128.712 35.0024L128.713 35.0008C128.99 34.8001 129.189 34.5109 129.315 34.146L129.354 34.0339L129.083 33.9575L128.976 33.9274L128.943 34.0318C128.846 34.3345 128.689 34.5647 128.483 34.7246C128.273 34.8839 128.027 34.965 127.725 34.965C127.307 34.965 126.985 34.8158 126.746 34.5191C126.504 34.22 126.378 33.795 126.378 33.2385C126.378 32.6731 126.504 32.2478 126.746 31.9442L126.746 31.9435C126.981 31.6437 127.303 31.4944 127.725 31.4944C128.018 31.4944 128.269 31.5755 128.478 31.7386C128.688 31.9025 128.845 32.1367 128.943 32.4528L128.975 32.5588L129.352 32.4524L129.315 32.3418C129.194 31.9664 128.99 31.673 128.715 31.468Z"
        fill="white"
      />
      <path
        d="M132.762 31.2122H133.146V33.5376C133.146 34.038 133.248 34.3919 133.433 34.6191C133.616 34.8418 133.891 34.9606 134.283 34.9606C134.671 34.9606 134.947 34.8419 135.127 34.6193C135.312 34.3919 135.415 34.0379 135.415 33.5376V31.2122H135.799V33.5376C135.799 34.0964 135.68 34.5374 135.421 34.8398C135.16 35.1459 134.773 35.2911 134.283 35.2911C133.79 35.2911 133.402 35.1473 133.14 34.8416C132.881 34.5396 132.762 34.0985 132.762 33.5376V31.2122Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.217 33.9085C142.154 33.7119 142.054 33.5501 141.913 33.4278C141.867 33.3877 141.817 33.3524 141.763 33.3217C141.919 33.2643 142.054 33.1838 142.169 33.0791L142.169 33.0781C142.37 32.8904 142.468 32.6343 142.468 32.3278C142.468 31.9598 142.323 31.6716 142.03 31.4814C141.744 31.2965 141.332 31.2122 140.809 31.2122H139.658V35.234H140.041V33.4565H140.911C141.172 33.4565 141.373 33.4991 141.52 33.5789C141.657 33.6539 141.766 33.7897 141.841 34.0082L142.214 35.1602L142.239 35.2384H142.643L142.596 35.092L142.217 33.9085ZM140.925 33.1259H140.046V31.5383H140.813C141.287 31.5383 141.61 31.6131 141.799 31.7426C141.981 31.8674 142.08 32.0626 142.08 32.341C142.08 32.6107 141.986 32.7978 141.81 32.9226C141.627 33.0524 141.338 33.1259 140.925 33.1259Z"
        fill="white"
      />
      <path
        d="M148.727 31.5428H146.59V32.9808H148.616V33.3113H146.59V34.9078H148.727V35.2384H146.202V31.2122H148.727V31.5428Z"
        fill="white"
      />
      <path
        d="M52.2118 15.9086L48.3387 17.6113C49.0778 19.3799 50.1729 20.7129 51.6287 21.6192C53.0755 22.5212 54.843 22.9743 56.9264 22.9743C59.3215 22.9743 61.2092 22.3804 62.5803 21.1969C63.9515 20.0134 64.6371 18.3856 64.6371 16.3178C64.6371 14.7823 64.2453 13.5374 63.4573 12.5826C62.6693 11.6279 61.1824 10.6952 58.9966 9.78015C58.538 9.59093 57.9058 9.3402 57.1045 9.02782C54.4912 8.0115 53.1868 6.93804 53.1868 5.80297C53.1868 5.06824 53.454 4.4875 53.9882 4.06075C54.5224 3.634 55.2481 3.41843 56.174 3.41843C57.1267 3.41843 57.9326 3.6472 58.5825 4.10475C59.2325 4.5623 59.7978 5.29262 60.2742 6.29571L64.1473 4.89667C63.5508 3.28204 62.607 2.05897 61.3204 1.23626C60.0338 0.413555 58.4267 0 56.4946 0C54.2864 0 52.5012 0.563138 51.1434 1.68502C49.7722 2.8157 49.0911 4.28513 49.0911 6.09333C49.0911 7.15801 49.3181 8.11267 49.7766 8.9574C50.2352 9.7977 50.8941 10.4929 51.7578 11.034C52.6303 11.5971 54.1796 12.2791 56.4055 13.0842C56.7306 13.1942 56.9799 13.2865 57.1534 13.357C58.4222 13.8189 59.2992 14.2853 59.789 14.7516C60.2742 15.2223 60.5191 15.8031 60.5191 16.4938C60.5191 17.3693 60.1852 18.06 59.5263 18.5748C58.8674 19.0895 57.9681 19.3447 56.8285 19.3447C55.8224 19.3447 54.9497 19.0675 54.2152 18.522C53.4807 17.9764 52.8129 17.1053 52.2118 15.9086Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 22.6704V0.241699H8.23157C10.7113 0.241699 12.6033 0.747643 13.9033 1.76394C15.2032 2.78022 15.8532 4.25406 15.8532 6.19425C15.8532 7.28093 15.5861 8.20045 15.0518 8.95713C14.5176 9.71387 13.6807 10.3694 12.5455 10.9237C13.8276 11.3549 14.7848 12.028 15.4258 12.9343C16.0669 13.845 16.3874 14.9889 16.3874 16.3747C16.3874 18.4953 15.6974 20.0747 14.3217 21.113C12.9461 22.1513 10.8448 22.6704 8.01785 22.6704H0ZM4.17588 9.22113H7.66616C8.99725 9.22113 9.99448 8.98795 10.6534 8.52602C11.3123 8.06402 11.6461 7.37332 11.6461 6.44942C11.6461 5.48592 11.3167 4.7864 10.6534 4.34645C9.99448 3.9109 8.91711 3.69092 7.4213 3.69092H4.17588V9.22113ZM4.17588 19.2213H7.66616C9.21096 19.2213 10.3373 18.9528 11.0407 18.4161C11.7485 17.8794 12.1003 17.0214 12.1003 15.8468C12.1003 14.7513 11.753 13.933 11.054 13.3919C10.3595 12.8507 9.29111 12.578 7.86204 12.578H4.17588V19.2213Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.423 0.567264C121.901 0.351687 121.336 0.241699 120.722 0.241699C120.104 0.241699 119.538 0.347287 119.017 0.571663C118.501 0.791635 118.051 1.09961 117.673 1.49556C117.294 1.89592 116.996 2.37546 116.786 2.9386C116.578 3.50175 116.471 4.13088 116.471 4.83039C116.471 5.52112 116.573 6.15465 116.782 6.72219C116.991 7.29413 117.285 7.78248 117.659 8.18725C118.033 8.59195 118.482 8.90875 119.004 9.13315C119.524 9.35748 120.099 9.47192 120.722 9.47192C121.345 9.47192 121.92 9.35748 122.436 9.13315C122.953 8.90875 123.402 8.59195 123.776 8.18283C124.155 7.7737 124.444 7.28533 124.653 6.71779C124.862 6.14586 124.964 5.51672 124.964 4.83039C124.964 4.13527 124.858 3.50614 124.649 2.943C124.435 2.37987 124.141 1.89592 123.767 1.49556C123.393 1.0908 122.943 0.782838 122.423 0.567264ZM122.147 8.55243C121.71 8.72838 121.234 8.81635 120.717 8.81635C120.201 8.81635 119.725 8.72838 119.293 8.55243C118.857 8.3764 118.482 8.12125 118.167 7.77805C117.85 7.43931 117.606 7.02135 117.432 6.52421C117.258 6.02706 117.169 5.46393 117.169 4.83039C117.169 4.19687 117.258 3.63812 117.437 3.14978C117.614 2.66143 117.864 2.25228 118.18 1.91792C118.496 1.58355 118.875 1.32838 119.31 1.1568C119.742 0.985218 120.223 0.897228 120.744 0.897228C121.256 0.897228 121.724 0.980815 122.156 1.1524C122.587 1.32398 122.957 1.57476 123.273 1.90911C123.589 2.24348 123.834 2.65263 124.012 3.14098C124.19 3.62933 124.279 4.19246 124.279 4.83039C124.279 5.46393 124.19 6.02706 124.017 6.52421C123.843 7.01696 123.594 7.43491 123.277 7.77805C122.962 8.11683 122.583 8.3764 122.147 8.55243Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M84.2298 9.30087H90.73V8.65415H84.884V4.99812H90.1555V4.347H84.884V1.05615H90.5114V0.405029H84.2298V9.30087Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.7075 9.30087C80.7394 9.30087 82.2535 7.79622 82.2535 4.78254C82.2535 3.3175 81.8748 2.22202 81.1227 1.5005C80.3653 0.774582 79.2261 0.413818 77.7075 0.413818H74.9877V9.30087H77.7075ZM81.3762 3.21191C81.4961 3.67386 81.5545 4.1974 81.5545 4.78254C81.5545 5.39847 81.4961 5.93961 81.3716 6.41476C81.2517 6.88991 81.0422 7.29466 80.7485 7.62905C80.4503 7.9634 80.0541 8.21855 79.5601 8.39015C79.066 8.56175 78.4421 8.64972 77.69 8.64972H75.6422V1.05175H77.69C78.4467 1.05175 79.0743 1.13534 79.5684 1.30252C80.067 1.4697 80.4639 1.71607 80.7576 2.03724C81.0514 2.3584 81.2608 2.74996 81.3762 3.21191Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M99.7801 0.405029L96.2496 9.30087H95.4041L91.8782 0.405029H92.6082L95.8314 8.49132L99.0546 0.405029H99.7801Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M101.285 9.30087H107.789V8.65415H101.944V4.99812H107.215V4.347H101.944V1.05615H107.567V0.405029H101.285V9.30087Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M109.828 9.30087V0.405029H110.487V8.65415H115.135V9.30087H109.828Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.138 0.405029C130.578 0.405029 130.997 0.440225 131.402 0.510617C131.807 0.58101 132.164 0.708596 132.475 0.897777C132.787 1.08255 133.032 1.33773 133.214 1.65889C133.397 1.98006 133.49 2.38481 133.49 2.87756C133.49 3.74866 133.209 4.41299 132.658 4.87054C132.101 5.32369 131.286 5.55246 130.218 5.55246H127.52V9.30522H126.861V0.405029H130.138ZM130.244 4.89693C130.703 4.89693 131.095 4.85294 131.42 4.76495C131.745 4.67696 132.007 4.54938 132.212 4.38219C132.417 4.21501 132.568 4.00824 132.662 3.76186C132.756 3.51549 132.8 3.22952 132.8 2.90395C132.8 2.508 132.729 2.18683 132.591 1.94486C132.453 1.70289 132.261 1.51371 132.025 1.38172C131.785 1.24974 131.513 1.16175 131.197 1.11775C130.886 1.07376 130.556 1.05176 130.209 1.05176H127.52V4.89693H130.244Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M143.115 9.30087H143.778V0.405029H142.839L139.531 8.49132L136.25 0.405029H135.297V9.30087H135.957V1.46531L139.188 9.30087H139.878L143.115 1.46971V9.30087Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M152.567 9.30087H146.062V0.405029H152.344V1.05615H146.717V4.347H151.988V4.99812H146.717V8.65415H152.567V9.30087Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M160.655 9.30087H161.519V0.405029H160.86V8.51772L155.407 0.405029H154.503V9.30087H155.162V1.17495L160.655 9.30087Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M170 1.05615H166.853V9.30522H166.194V1.05615H163.077V0.405029H170V1.05615Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M77.5345 22.3365C78.0597 22.5609 78.6471 22.6709 79.2884 22.6709C79.6041 22.6709 79.916 22.6488 80.2279 22.6005C80.5345 22.5521 80.8328 22.4729 81.1136 22.3673C81.3944 22.2617 81.6608 22.1253 81.915 21.9537C82.1639 21.7821 82.3954 21.5754 82.6049 21.3246L82.894 22.5125H83.2727V18.179H80.13V18.8301H82.614V20.4227C82.2353 20.9199 81.7723 21.3114 81.2251 21.593C80.6772 21.8745 80.0276 22.0153 79.2838 22.0153C78.7495 22.0153 78.2646 21.9273 77.8236 21.7514C77.3827 21.5754 77.0047 21.3202 76.6883 20.977C76.3771 20.6383 76.1274 20.2203 75.9536 19.7232C75.7802 19.2261 75.6911 18.6629 75.6911 18.0294C75.6911 17.3914 75.7802 16.8239 75.9536 16.3356C76.1274 15.8428 76.3726 15.4337 76.6883 15.0993C77.0047 14.7649 77.3781 14.5142 77.8191 14.3469C78.2555 14.1798 78.7404 14.0962 79.2663 14.0962C79.6936 14.0962 80.0852 14.149 80.4412 14.2502C80.8017 14.3514 81.1136 14.4921 81.3807 14.6637C81.6479 14.8397 81.8657 15.0421 82.0349 15.2709C82.1996 15.4996 82.3066 15.746 82.3514 16.0056H83.0587C82.9745 15.6228 82.8234 15.2709 82.6049 14.9585C82.3825 14.6418 82.1063 14.3733 81.7723 14.149C81.4384 13.9246 81.0559 13.753 80.6286 13.6299C80.2006 13.5066 79.7422 13.4451 79.2481 13.4451C78.6159 13.4451 78.0414 13.5507 77.5208 13.7662C77.0002 13.9818 76.5501 14.2898 76.1768 14.6857C75.798 15.0861 75.5086 15.5656 75.2994 16.1288C75.0901 16.6919 74.9877 17.3254 74.9877 18.0294C74.9877 18.7289 75.0901 19.3624 75.295 19.9344C75.4997 20.5063 75.7936 20.9946 76.1722 21.3994C76.5501 21.7998 77.0047 22.1121 77.5345 22.3365Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.5992 22.5079H91.8737L90.9789 20.141C90.8901 19.9122 90.7922 19.701 90.6852 19.4987C90.5782 19.2962 90.4537 19.1247 90.311 18.9751C90.1683 18.8255 90.0037 18.7067 89.8215 18.6231C89.6386 18.5351 89.4208 18.4912 89.1757 18.4912H86.0284V22.5079H85.3696V13.6121H89.4254C89.8033 13.6121 90.1683 13.6473 90.525 13.7133C90.8764 13.7792 91.1929 13.9025 91.4684 14.0741C91.7446 14.2456 91.967 14.4876 92.1317 14.7911C92.2964 15.0992 92.3768 15.4907 92.3768 15.9659C92.3768 16.265 92.3412 16.5422 92.2653 16.7973C92.1901 17.0569 92.0786 17.2857 91.9275 17.4881C91.7758 17.6904 91.5845 17.8664 91.353 18.0072C91.1216 18.1524 90.8453 18.2536 90.5334 18.3196C90.7026 18.4516 90.8453 18.5747 90.9523 18.6935C91.0639 18.8123 91.1572 18.9399 91.2369 19.0763C91.3174 19.2127 91.3887 19.3622 91.4509 19.5251C91.5132 19.6878 91.5845 19.8726 91.6687 20.0838L92.5992 22.5079ZM86.0375 17.8445H89.1537C89.5991 17.8445 89.9771 17.8136 90.2981 17.7476C90.6184 17.6816 90.8764 17.576 91.0859 17.4265C91.2908 17.2769 91.4418 17.0833 91.5443 16.8458C91.6422 16.6082 91.6915 16.3134 91.6915 15.9659C91.6915 15.3543 91.4957 14.9144 91.1033 14.6548C90.7117 14.3908 90.1289 14.2588 89.3495 14.2588H86.0375V17.8445Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.114 13.775C99.5934 13.5594 99.0281 13.4495 98.4133 13.4495C97.7948 13.4495 97.2248 13.5551 96.7088 13.7794C96.1919 13.9994 95.7426 14.3073 95.3639 14.7033C94.9852 15.1037 94.687 15.5832 94.4783 16.1463C94.2688 16.7095 94.1618 17.3386 94.1618 18.0381C94.1618 18.7289 94.2643 19.3624 94.4737 19.9299C94.6832 20.5019 94.9769 20.9902 95.3503 21.395C95.7244 21.7997 96.1745 22.1165 96.6951 22.3409C97.2157 22.5653 97.7902 22.6796 98.4133 22.6796C99.0372 22.6796 99.6109 22.5653 100.128 22.3409C100.644 22.1165 101.094 21.7997 101.467 21.3905C101.846 20.9814 102.135 20.4931 102.345 19.9256C102.554 19.3536 102.656 18.7244 102.656 18.0381C102.656 17.343 102.549 16.7139 102.34 16.1507C102.127 15.5876 101.832 15.1037 101.459 14.7033C101.085 14.2985 100.635 13.9906 100.114 13.775ZM99.8295 21.7601C99.3976 21.9361 98.921 22.0241 98.405 22.0241C97.8881 22.0241 97.4161 21.9361 96.9759 21.7601C96.5395 21.5842 96.1654 21.329 95.8489 20.9858C95.5332 20.6471 95.288 20.2291 95.115 19.732C94.9412 19.2348 94.8517 18.6716 94.8517 18.0381C94.8517 17.4046 94.9412 16.8458 95.1188 16.3575C95.2972 15.8692 95.5468 15.46 95.8626 15.1256C96.179 14.7913 96.557 14.5361 96.9934 14.3645C97.4297 14.1929 97.9064 14.105 98.427 14.105C98.9385 14.105 99.406 14.1886 99.8378 14.3601C100.266 14.5317 100.639 14.7825 100.956 15.1169C101.267 15.4512 101.517 15.8604 101.695 16.3487C101.873 16.8371 101.962 17.4002 101.962 18.0381C101.962 18.6716 101.873 19.2348 101.699 19.732C101.526 20.2247 101.276 20.6426 100.96 20.9858C100.644 21.3246 100.266 21.5842 99.8295 21.7601Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M107.994 22.6707C107.455 22.6707 106.997 22.6135 106.618 22.5035C106.236 22.3935 105.919 22.244 105.67 22.0547C105.417 21.8656 105.216 21.6412 105.069 21.3905C104.922 21.1352 104.811 20.8669 104.731 20.5766C104.655 20.2906 104.606 19.9914 104.589 19.6834C104.57 19.3754 104.561 19.0719 104.561 18.7815V13.6121H105.221V18.7551C105.221 19.4327 105.283 19.9826 105.408 20.4093C105.532 20.8361 105.71 21.1661 105.951 21.4037C106.191 21.6412 106.481 21.804 106.823 21.892C107.166 21.98 107.558 22.024 108.003 22.024C108.452 22.024 108.853 21.98 109.201 21.892C109.547 21.804 109.841 21.6412 110.073 21.3992C110.305 21.1573 110.482 20.8273 110.603 20.405C110.723 19.9826 110.785 19.4327 110.785 18.7551V13.6121H111.444V18.7815C111.444 19.3931 111.391 19.943 111.288 20.4225C111.186 20.9065 111.003 21.3113 110.745 21.6456C110.487 21.98 110.136 22.2351 109.69 22.4111C109.236 22.5827 108.675 22.6707 107.994 22.6707Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.053 13.6121H113.777V22.5123H114.436V18.7595H117.134C118.202 18.7595 119.017 18.5308 119.574 18.0776C120.125 17.6201 120.406 16.9557 120.406 16.0846C120.406 15.5918 120.312 15.1871 120.13 14.866C119.947 14.5448 119.703 14.2896 119.391 14.1048C119.079 13.9157 118.723 13.7881 118.318 13.7177C117.913 13.6473 117.494 13.6121 117.053 13.6121ZM117.16 18.104H114.436V14.2588H117.125C117.472 14.2588 117.802 14.2808 118.113 14.3248C118.429 14.3688 118.701 14.4568 118.942 14.5888C119.177 14.7208 119.369 14.9099 119.507 15.1519C119.644 15.3939 119.716 15.7151 119.716 16.111C119.716 16.4366 119.672 16.7225 119.578 16.9689C119.484 17.2153 119.333 17.4221 119.128 17.5892C118.923 17.7564 118.661 17.884 118.336 17.972C118.011 18.06 117.619 18.104 117.16 18.104Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M22.1392 22.6704H17.3757L25.7631 0.241699H30.5266L22.1392 22.6704Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M25.7854 22.6704H30.549L38.9408 0.241699H34.1773L25.7854 22.6704Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M47.2969 22.6705L40.5745 4.69849L38.2729 11.2845L42.5334 22.6705H47.2969Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6982 31.4694C23.9743 31.6513 24.1093 31.9262 24.1095 32.2796C24.1118 32.5491 24.0272 32.7752 23.848 32.9453C23.7685 33.0206 23.6729 33.0827 23.5625 33.1326C23.7199 33.1876 23.8562 33.2653 23.9697 33.367C24.1884 33.563 24.292 33.8239 24.292 34.1367C24.292 34.4998 24.1508 34.7837 23.8628 34.9709C23.5834 35.1526 23.1784 35.2347 22.6645 35.2347H21.2685V31.2129H22.5132C23.0282 31.2129 23.4297 31.2925 23.6982 31.4694ZM21.6565 32.9903H22.5399C22.9678 32.9903 23.2705 32.9227 23.4629 32.8026C23.6466 32.6879 23.7393 32.5196 23.7393 32.2801C23.7393 32.0221 23.6474 31.8492 23.4662 31.7321C23.2802 31.6135 22.9688 31.5435 22.5176 31.5435H21.6565V32.9903ZM21.6609 34.9085H22.669C23.1119 34.9085 23.425 34.8356 23.6243 34.7064C23.8154 34.5826 23.9129 34.3999 23.9129 34.1411C23.9129 33.8523 23.8078 33.6579 23.6096 33.5321C23.3967 33.3998 23.0493 33.3253 22.5443 33.3253H21.6609V34.9085Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M27.7861 31.2122V33.5376C27.7861 34.0985 27.9054 34.5396 28.1642 34.8416C28.4262 35.1473 28.8142 35.2911 29.3068 35.2911C29.7974 35.2911 30.1843 35.1459 30.4456 34.8398C30.7037 34.5374 30.8229 34.0964 30.8229 33.5376V31.2122H30.4394V33.5376C30.4394 34.0379 30.3368 34.3919 30.1519 34.6193C29.9706 34.8419 29.6957 34.9606 29.3068 34.9606C28.9153 34.9606 28.6394 34.8418 28.4577 34.6191C28.2722 34.3919 28.1696 34.038 28.1696 33.5376V31.2122H27.7861Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M34.6865 35.2384V31.2122H35.0611V35.2384H34.6865Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M39.3796 34.9078V31.2122H38.9961V35.2384H41.4809V34.9078H39.3796Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.0549 31.2122C46.7316 31.2122 47.2614 31.3844 47.6111 31.7444C47.9574 32.1056 48.1231 32.6075 48.1231 33.2341C48.1231 33.8811 47.9558 34.388 47.6009 34.7321C47.2465 35.0758 46.7241 35.2384 46.0549 35.2384H44.8592V31.2122H46.0549ZM45.2427 34.9078H46.0549C46.6337 34.9078 47.0468 34.7638 47.3163 34.4951C47.5858 34.2262 47.7307 33.8132 47.7307 33.2341C47.7307 32.6807 47.5889 32.267 47.3199 31.977C47.0554 31.6919 46.6422 31.5428 46.0549 31.5428H45.2427V34.9078Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M53.9112 33.0486L53.9065 33.0452C53.8379 32.9978 53.7598 32.981 53.681 32.981C53.6005 32.981 53.5184 32.9966 53.4508 33.0486C53.389 33.0962 53.3446 33.1664 53.3446 33.2518C53.3446 33.3435 53.3907 33.4088 53.4508 33.455C53.5184 33.5069 53.6005 33.5227 53.681 33.5227C53.7652 33.5227 53.8385 33.5054 53.9065 33.4583C53.9822 33.4061 54.0174 33.3317 54.0174 33.2518C54.0174 33.1601 53.9713 33.0948 53.9112 33.0486Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M59.2433 35.2385V31.2122H59.3947V31.0393L59.5016 31.2122H59.6655L61.2625 33.7685L62.8172 31.2659L62.8506 31.2122H63.0137L63.126 31.0305V31.2122H63.2818V35.2385H62.8983V31.8303L61.4289 34.2089H61.2661L61.2626 34.2149L61.2591 34.2089H61.092L59.6224 31.8385V35.2385H59.2433Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M67.1498 31.2122V33.5376C67.1498 34.0985 67.269 34.5396 67.5279 34.8416C67.7899 35.1473 68.1779 35.2911 68.6704 35.2911C69.1611 35.2911 69.5479 35.1459 69.8092 34.8398C70.0673 34.5374 70.1866 34.0964 70.1866 33.5376V31.2122H69.8031V33.5376C69.8031 34.0379 69.7005 34.3919 69.5155 34.6193C69.3343 34.8419 69.0594 34.9606 68.6704 34.9606C68.279 34.9606 68.0031 34.8418 67.8214 34.6191C67.6359 34.3919 67.5333 34.038 67.5333 33.5376V31.2122H67.1498Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M74.4337 34.9078H76.5349V35.2384H74.0502V31.2122H74.4337V34.9078Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M81.6993 31.2122H78.7425V31.5428H80.0335V35.2384H80.4167V31.5428H81.6993V31.2122Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M85.0196 35.2384V31.2122H85.3945V35.2384H85.0196Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.713 33.5577H90.4272C90.9865 33.5577 91.4198 33.4694 91.7067 33.2739C92.0012 33.0735 92.1431 32.7719 92.1431 32.3806C92.1431 31.9854 92.0012 31.6829 91.706 31.4865C91.4191 31.2959 90.9857 31.2122 90.4272 31.2122H89.329V35.2384H89.713V33.5577ZM89.713 33.2228V31.5383H90.4272C90.9243 31.5383 91.2597 31.6133 91.4578 31.7474C91.6475 31.8763 91.7507 32.0797 91.7507 32.3762C91.7507 32.6673 91.6483 32.8715 91.4525 33.0052L91.4517 33.006C91.265 33.137 90.9698 33.2142 90.541 33.2257V33.2228H89.713Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M96.0835 34.9078H98.185V35.2384H95.7002V31.2122H96.0835V34.9078Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M103.28 31.2122L103.248 31.2684L102.028 33.3558L100.805 31.2683L100.772 31.2122H100.33L101.83 33.7485V35.2384H102.213V33.7487L103.724 31.2122H103.28Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M108.932 33.0452L108.937 33.0486C108.997 33.0948 109.043 33.1601 109.043 33.2518C109.043 33.3373 108.998 33.4074 108.937 33.455C108.869 33.5069 108.787 33.5227 108.707 33.5227C108.626 33.5227 108.543 33.5069 108.476 33.455C108.416 33.4088 108.37 33.3435 108.37 33.2518C108.37 33.1664 108.414 33.0962 108.476 33.0486C108.543 32.9966 108.626 32.981 108.707 32.981C108.785 32.981 108.863 32.9978 108.932 33.0452Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M114.245 35.0139L114.246 35.0144C114.542 35.1984 114.884 35.2912 115.277 35.2912C115.663 35.2912 115.992 35.2085 116.263 35.0415C116.55 34.8716 116.696 34.6152 116.696 34.29C116.696 34.1224 116.656 33.961 116.568 33.818C116.483 33.6802 116.364 33.5582 116.212 33.4614C116.069 33.3653 115.931 33.2925 115.807 33.2339C115.682 33.1743 115.531 33.1156 115.361 33.0538L115.36 33.0534C115.007 32.9284 114.744 32.7886 114.562 32.641C114.392 32.5041 114.309 32.3338 114.309 32.1122C114.309 31.9151 114.39 31.7653 114.545 31.6592C114.707 31.5487 114.936 31.4856 115.246 31.4856C115.558 31.4856 115.8 31.5564 115.981 31.6845C116.151 31.805 116.241 31.9836 116.241 32.2442V32.3727L116.6 32.3253L116.602 32.2283C116.607 31.9027 116.48 31.6401 116.219 31.4423C115.967 31.2519 115.638 31.1594 115.246 31.1594C114.866 31.1594 114.552 31.2374 114.305 31.4032L114.305 31.4039C114.056 31.5742 113.926 31.8153 113.926 32.1166C113.926 32.4137 114.043 32.6645 114.272 32.8615L114.273 32.8622C114.497 33.0499 114.808 33.2137 115.205 33.3654L115.209 33.3669C115.374 33.4226 115.508 33.4783 115.618 33.5259C115.718 33.5716 115.834 33.6345 115.958 33.7154C116.077 33.7924 116.161 33.8729 116.216 33.9679C116.273 34.066 116.304 34.1698 116.304 34.2856C116.304 34.501 116.213 34.6593 116.027 34.7756C115.831 34.8976 115.583 34.9606 115.268 34.9606C114.944 34.9606 114.679 34.8859 114.462 34.7347C114.255 34.5914 114.149 34.3813 114.144 34.0861L114.143 33.9682L113.767 33.9907L113.77 34.099C113.779 34.5029 113.936 34.8184 114.245 35.0139Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M122.808 31.5428H120.671V32.9808H122.697V33.3113H120.671V34.9078H122.808V35.2384H120.283V31.2122H122.808V31.5428Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M128.715 31.468L128.714 31.4675C128.435 31.2627 128.103 31.1594 127.725 31.1594C127.188 31.1594 126.758 31.3427 126.443 31.7196C126.132 32.0938 125.986 32.6019 125.986 33.2341C125.986 33.862 126.132 34.3661 126.444 34.7359C126.758 35.108 127.188 35.2912 127.725 35.2912C128.106 35.2912 128.436 35.1931 128.712 35.0024L128.713 35.0008C128.99 34.8001 129.189 34.5109 129.315 34.146L129.354 34.0339L129.083 33.9575L128.976 33.9274L128.943 34.0318C128.846 34.3345 128.689 34.5647 128.483 34.7246C128.273 34.8839 128.027 34.965 127.725 34.965C127.307 34.965 126.985 34.8158 126.746 34.5191C126.504 34.22 126.378 33.795 126.378 33.2385C126.378 32.6731 126.504 32.2478 126.746 31.9442L126.746 31.9435C126.981 31.6437 127.303 31.4944 127.725 31.4944C128.018 31.4944 128.269 31.5755 128.478 31.7386C128.688 31.9025 128.845 32.1367 128.943 32.4528L128.975 32.5588L129.352 32.4524L129.315 32.3418C129.194 31.9664 128.99 31.673 128.715 31.468Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M132.762 31.2122H133.146V33.5376C133.146 34.038 133.248 34.3919 133.433 34.6191C133.616 34.8418 133.891 34.9606 134.283 34.9606C134.671 34.9606 134.947 34.8419 135.127 34.6193C135.312 34.3919 135.415 34.0379 135.415 33.5376V31.2122H135.799V33.5376C135.799 34.0964 135.68 34.5374 135.421 34.8398C135.16 35.1459 134.773 35.2911 134.283 35.2911C133.79 35.2911 133.402 35.1473 133.14 34.8416C132.881 34.5396 132.762 34.0985 132.762 33.5376V31.2122Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.217 33.9085C142.154 33.7119 142.054 33.5501 141.913 33.4278C141.867 33.3877 141.817 33.3524 141.763 33.3217C141.919 33.2643 142.054 33.1838 142.169 33.0791L142.169 33.0781C142.37 32.8904 142.468 32.6343 142.468 32.3278C142.468 31.9598 142.323 31.6716 142.03 31.4814C141.744 31.2965 141.332 31.2122 140.809 31.2122H139.658V35.234H140.041V33.4565H140.911C141.172 33.4565 141.373 33.4991 141.52 33.5789C141.657 33.6539 141.766 33.7897 141.841 34.0082L142.214 35.1602L142.239 35.2384H142.643L142.596 35.092L142.217 33.9085ZM140.925 33.1259H140.046V31.5383H140.813C141.287 31.5383 141.61 31.6131 141.799 31.7426C141.981 31.8674 142.08 32.0626 142.08 32.341C142.08 32.6107 141.986 32.7978 141.81 32.9226C141.627 33.0524 141.338 33.1259 140.925 33.1259Z"
        stroke="white"
        strokeWidth="0.3"
      />
      <path
        d="M148.727 31.5428H146.59V32.9808H148.616V33.3113H146.59V34.9078H148.727V35.2384H146.202V31.2122H148.727V31.5428Z"
        stroke="white"
        strokeWidth="0.3"
      />
    </g>
    <defs>
      <clipPath id="clip0_29202_115064">
        <rect width="170" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default BmsWhiteLogo
