import React from 'react'

import Section from '../Common/Section'
import Container from '../Common/Container'
import Typography from '../Common/Typography'
import PartnersBtn from './PartnersBtn'
import { toLink } from '../../utils'

import MrGroupLogo from './svg/mr-group-logo'
import MrGroupWhiteLogo from './svg/mr-group-white-logo'
import X5GroupWhiteLogo from './svg/X5-group-white-logo'
import X5GroupLogo from './svg/X5-group-logo'
import HalsLogo from './svg/hals-logo'
import HalsWhiteLogo from './svg/hals-white-logo'
import MosinzhproektLogo from './svg/mosinzhproekt-logo'
import MosinzhproektWhiteLogo from './svg/mosinzhproekt-white-logo'
import GreenLogo from './svg/green-logo'
import GreenWhiteLogo from './svg/green-white-logo'
import AkvilonLogo from './svg/akvilon-logo'
import AkvilonWhiteLogo from './svg/akvilon-white-logo'
import MavisLogo from './svg/mavis-logo'
import MavisWhiteLogo from './svg/mavis-white-logo'
import SberbankDevelopmentLogo from './svg/sberbank-development-logo'
import SberbankDevelopmentWhiteLogo from './svg/sberbank-development-white-logo'
import KpUgsLogo from './svg/kp-ugs-logo'
import KpUgsWhiteLogo from './svg/kp-ugs-white-logo'
import SminexLogo from './svg/sminex-logo'
import SminexWhiteLogo from './svg/sminex-white-logo'
import PioneerWhiteLogo from './svg/pioneer-white-logo'
import PioneerLogo from './svg/pioneer-logo'
import TretiiTrestLogo from './svg/tretii-trest-logo'
import TretiiTrestWhiteLogo from './svg/tretii-trest-white-logo'
import KsmLogo from './svg/ksm-logo'
import KsmWhiteLogo from './svg/ksm-white-logo'
import SibtehproektLogo from './svg/sibtehproekt-logo'
import SibtehproektWhiteLogo from './svg/sibtehproekt-white-logo'

import * as s from './PartnersBlock.module.scss'
import GdcrazvitieLogo from './svg/gdc-razvitie-logo'
import GdcrazvitieWhiteLogo from './svg/gdc-razvitie-white-logo'
import PridexLogo from './svg/pridex-logo'
import PridexWhiteLogo from './svg/pridex-white-logo'
import UniqLogo from './svg/uniq-logo'
import UniqWhiteLogo from './svg/uniq-white-logo'
import BmsWhiteLogo from './svg/bms-white-logo'
import BmsLogo from './svg/bms-logo'
import RbiLogo from './svg/rbi-logo'
import RbiWhiteLogo from './svg/rbi-white-logo'
import AagLogo from './svg/aag-logo'
import AagWhiteLogo from './svg/aag-white-logo'
import ArhitektBajUnistrojLogo from './svg/arhitekt-baj-unistroj-logo'
import ArhitektBajUnistrojWhiteLogo from './svg/arhitekt-baj-unistroj-white-logo'
import GolosLogo from './svg/golos-logo'
import GolosWhiteLogo from './svg/golos-white-logo'
import LevelGroupLogo from './svg/level-group-logo'
import LevelGroupWhiteLogo from './svg/level-group-white-logo'
import GranelLogo from './svg/granel-logo'
import GranelWhiteLogo from './svg/granel-white-logo'
import AkkermanLogo from './svg/akkerman-logo'
import AkkermanWhiteLogo from './svg/akkerman-white-logo'
import GlavstrojLogo from './svg/glavstroj-logo'
import GlavstrojWhiteLogo from './svg/glavstroj-white-logo'
import EnergostroyLogo from './svg/energostroy-logo'
import EnergostroyWhiteLogo from './svg/energostroy-white-logo'
import ChajkaLabLogo from './svg/chajka-lab-logo'
import ChajkaLabWhiteLogo from './svg/chajka-lab-white-logo'
import CimajloLyashenkoPartnyoryLogo from './svg/cimajlo-lyashenko-partnyory-logo'
import CimajloLyashenkoPartnyorWhiteLogo from './svg/cimajlo-lyashenko-partnyory-white-logo'
import AeonLogo from './svg/aeon-logo'
import AeonWhiteLogo from './svg/aeon-white-logo'

const partners = [
  {
    title: 'MR Group',
    link: 'https://www.mr-group.ru',
    Icon: MrGroupLogo,
    IconHover: MrGroupWhiteLogo,
  },
  {
    title: 'Х5 Group',
    link: 'https://www.x5.ru/ru/about',
    Icon: X5GroupLogo,
    IconHover: X5GroupWhiteLogo,
  },
  {
    title: 'ГАЛС',
    link: 'https://hals-development.ru',
    Icon: HalsLogo,
    IconHover: HalsWhiteLogo,
  },
  {
    title: 'Мосинжпроект',
    link: 'https://mosinzhproekt.ru',
    Icon: MosinzhproektLogo,
    IconHover: MosinzhproektWhiteLogo,
  },
  {
    title: 'Green',
    link: 'https://greenfingroup.com/en',
    Icon: GreenLogo,
    IconHover: GreenWhiteLogo,
  },
  {
    title: 'Аквилон',
    link: 'https://group-akvilon.ru',
    Icon: AkvilonLogo,
    IconHover: AkvilonWhiteLogo,
  },
  {
    title: 'Мавис',
    link: 'https://grafikadom.ru',
    Icon: MavisLogo,
    IconHover: MavisWhiteLogo,
  },
  {
    title: 'Сбербанк development',
    link: 'https://sbdevelop.ru',
    Icon: SberbankDevelopmentLogo,
    IconHover: SberbankDevelopmentWhiteLogo,
  },
  {
    title: 'КП УГС',
    link: 'https://kpugs.ru',
    Icon: KpUgsLogo,
    IconHover: KpUgsWhiteLogo,
  },
  {
    title: 'Sminex',
    link: 'https://www.sminex.com',
    Icon: SminexLogo,
    IconHover: SminexWhiteLogo,
  },
  {
    title: 'Pioneer',
    link: 'https://pioneer.ru',
    Icon: PioneerLogo,
    IconHover: PioneerWhiteLogo,
  },
  {
    title: 'Третий Трест',
    link: 'https://tretiitrest.ru',
    Icon: TretiiTrestLogo,
    IconHover: TretiiTrestWhiteLogo,
  },
  {
    title: 'KSM',
    link: 'https://ksm.company',
    Icon: KsmLogo,
    IconHover: KsmWhiteLogo,
  },
  {
    title: 'Сибтехпроект',
    link: 'https://main.sibtehproekt.com',
    Icon: SibtehproektLogo,
    IconHover: SibtehproektWhiteLogo,
  },
  {
    title: 'ООО Генеральная Дирекция Центр',
    link: 'https://gdcmoscow.ru/',
    Icon: GdcrazvitieLogo,
    IconHover: GdcrazvitieWhiteLogo,
  },
  {
    title: 'Компания Pridex',
    link: 'https://pridex.ru/',
    Icon: PridexLogo,
    IconHover: PridexWhiteLogo,
  },
  {
    title: 'АО Юник Девелопмент',
    link: 'https://uniqdevelopment.ru/',
    Icon: UniqLogo,
    IconHover: UniqWhiteLogo,
  },
  {
    title: 'BMS development group',
    link: 'https://bmsdevelopmentgroup.com/',
    Icon: BmsLogo,
    IconHover: BmsWhiteLogo,
  },
  {
    title: 'Группа RBI',
    link: 'https://www.rbi.ru/',
    Icon: RbiLogo,
    IconHover: RbiWhiteLogo,
  },
  {
    title: 'AAG застройщик',
    link: 'https://aag.company/',
    Icon: AagLogo,
    IconHover: AagWhiteLogo,
  },
  {
    title: 'AU АРХИТЕКТУРНО-ПРОЕКТНОЕ БЮРО',
    link: 'https://au-project.ru/',
    Icon: ArhitektBajUnistrojLogo,
    IconHover: ArhitektBajUnistrojWhiteLogo,
  },
  {
    title: 'Голос Девелопмент',
    link: 'https://golos.click/',
    Icon: GolosLogo,
    IconHover: GolosWhiteLogo,
  },
  {
    title: 'Level Group',
    link: 'https://level.ru/',
    Icon: LevelGroupLogo,
    IconHover: LevelGroupWhiteLogo,
  },
  {
    title: 'ГК Гранель',
    link: 'https://granelle.ru/',
    Icon: GranelLogo,
    IconHover: GranelWhiteLogo,
  },
  {
    title: 'Akkermann',
    link: 'https://www.akkermann.ru/',
    Icon: AkkermanLogo,
    IconHover: AkkermanWhiteLogo,
  },
  {
    title: 'Главстрой',
    link: 'https://www.glavstroy.ru/',
    Icon: GlavstrojLogo,
    IconHover: GlavstrojWhiteLogo,
  },
  {
    title: 'Энергострой',
    link: 'https://energo-stroy.org/',
    Icon: EnergostroyLogo,
    IconHover: EnergostroyWhiteLogo,
  },
  {
    title: 'Проектное бюро Чайка Лаб',
    link: 'https://offers.uds18.ru/chaika',
    Icon: ChajkaLabLogo,
    IconHover: ChajkaLabWhiteLogo,
  },
  {
    title: 'Цимайло Ляшенко Партнёры',
    link: 'https://tlp-ab.ru/',
    Icon: CimajloLyashenkoPartnyoryLogo,
    IconHover: CimajloLyashenkoPartnyorWhiteLogo,
  },
  {
    title: 'Аеон девелопмент ',
    link: 'https://aeondevelopment.ru/',
    Icon: AeonLogo,
    IconHover: AeonWhiteLogo,
  },
]

const PartnersBlock = () => {
  return (
    <Section>
      <Container>
        <Typography variant="h2" color="blue" size={40} mb={48}>
          С нами работают
        </Typography>
        <div className={s.content}>
          {partners.map(({ title, Icon, IconHover, link }, idx) => (
            <PartnersBtn
              key={title + idx}
              title={title}
              Icon={<Icon />}
              IconHover={<IconHover />}
              onClick={() => toLink(link)}
            />
          ))}
        </div>
      </Container>
    </Section>
  )
}

export default PartnersBlock
