import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import Typography from '../../Common/Typography'
import List from '../../Common/List'
import { ModuleName } from '../../../types'
import SignalMiddleIcon from '../../../svg/signal-middle-icon'
import { useTranslations } from '../../../hooks/use-translations'

import * as s from './ModuleItem.module.scss'

interface ModuleItemProps {
  moduleName: ModuleName
  modulePosition?: 'left0' | 'left' | 'right'
  subtitle: string
  isModules?: boolean
  text: string[]
  image: {
    id: string
    title: string
    alt: string
  }
}

const ModuleItem: React.FC<ModuleItemProps> = ({
  image,
  moduleName,
  modulePosition = 'right',
  subtitle,
  text,
  isModules,
}) => {
  const images = useStaticQuery(imagesQuery)
  const { getPath, t } = useTranslations()

  return (
    <div className={s.item}>
      <div className="relative">
        <Link
          to={getPath(`/${moduleName.toLowerCase()}/`)}
          className={cn(
            s.brand_button,
            s[`brand_button_${moduleName.toLocaleLowerCase()}`],
            s[modulePosition]
          )}
        >
          <SignalMiddleIcon className={s.logo} />
          <span className={cn(s.module_text)}>{moduleName}</span>
        </Link>
        <GatsbyImage
          alt={image.alt}
          title={image.title}
          image={images[image.id].childImageSharp.gatsbyImageData}
          objectFit="contain"
          className={s.image}
        />
        {isModules && (
          <div className={cn(s.modules, s[modulePosition])}>
            <span>{t('Goes with all SIGNAL modules')}</span>
          </div>
        )}
      </div>
      <Typography variant="h3" color="white" className={s.subtitle}>
        {subtitle}
      </Typography>
      <List>
        {text.map((t, idx) => (
          <li className={s.list_item} key={idx}>
            {t}
          </li>
        ))}
      </List>
    </div>
  )
}

export default ModuleItem

const imagesQuery = graphql`
  query {
    signalDashboardPhone: file(
      relativePath: { eq: "home/signal-dashboard-phone.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    signaxDashboardPhone: file(
      relativePath: { eq: "home/signax-dashboard-phone.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    signalInspectionPhone: file(
      relativePath: { eq: "home/signal-inspection-phone.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    signaxInspectionPhone: file(
      relativePath: { eq: "home/signax-inspection-phone.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    signalDocsPhone: file(relativePath: { eq: "home/signal-docs-phone.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    signaxDocsPhone: file(relativePath: { eq: "home/signax-docs-phone.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    tools: file(relativePath: { eq: "home/tools-macbook.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    toolsEn: file(relativePath: { eq: "home/tools-macbook-en.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
  }
`
