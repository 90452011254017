import React from 'react'

const EnergostroyLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="69"
    height="64"
    viewBox="0 0 69 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_29363_89515)">
      <path
        d="M33.0267 0.541992C34.8113 0.815743 36.6152 0.996806 38.3741 1.3848C42.511 2.29874 46.2708 4.06196 49.7222 6.76714C48.0575 6.27568 46.5407 5.7368 44.9811 5.38114C37.5878 3.69336 30.6915 5.0535 24.3116 9.06061C17.6252 13.2596 13.5612 19.3748 12.2329 27.1282C10.1184 39.4793 16.3441 52.0482 28.5813 57.1503C30.9593 58.1418 33.4145 58.5988 35.9467 58.7562C37.7249 58.8661 39.5095 58.9157 41.292 58.9329C43.9164 58.9566 46.3822 58.2022 48.8117 57.2926C49.0345 57.2085 49.2551 57.1137 49.4779 57.0296C49.4951 57.0231 49.5251 57.0511 49.5979 57.0878C47.207 58.9502 44.6062 60.3706 41.7933 61.4053C38.0463 62.7827 34.1622 63.4293 30.1881 63.231C22.3684 62.8409 15.5236 59.9977 9.75419 54.6413C4.50111 49.7633 1.46966 43.6955 0.355632 36.6297C0.197097 35.6274 0.11783 34.6122 0 33.6034C0 32.4739 0 31.3465 0 30.2171C0.269938 28.4194 0.441327 26.6022 0.82481 24.8283C3.65916 11.7054 14.2189 3.3765 25.1685 1.21236C26.651 0.919207 28.1614 0.766166 29.6589 0.546303C30.7815 0.546303 31.9041 0.546303 33.0246 0.546303L33.0267 0.541992Z"
        fill="#D10207"
      />
      <path
        d="M22.8118 50.3822C24.6628 50.8478 26.6595 51.5656 28.7118 51.8221C34.6269 52.5614 39.9807 51.1021 44.5182 47.108C45.6151 46.1423 46.5535 44.9869 47.524 43.8833C47.9803 43.3638 48.458 43.0944 49.1736 43.0987C52.9634 43.1267 56.7533 43.1116 60.5452 43.1138C60.8045 43.1138 61.0658 43.1138 61.3572 43.1138C55.41 57.0428 34.4384 62.0436 22.8118 50.3822Z"
        fill="#F0040D"
      />
      <path
        d="M22.2163 13.7581C28.4527 8.1731 35.6339 5.93783 43.7385 7.42729C51.6546 8.88012 57.5718 13.3162 61.4559 20.6924C60.194 20.6924 59.1357 20.6773 58.0774 20.6967C56.0507 20.7333 54.0261 20.7678 52.0016 20.8433C49.6386 20.9316 47.6869 20.0931 46.1123 18.3278C43.2886 15.1613 39.8673 12.9734 35.6875 12.1781C31.2828 11.3396 26.9938 11.7922 22.8997 13.7236C22.7583 13.7904 22.6062 13.8378 22.4563 13.8895C22.4391 13.896 22.4113 13.8658 22.2184 13.7602L22.2163 13.7581Z"
        fill="#F0040D"
      />
      <path
        d="M21.4045 29.9635C24.9565 26.6526 28.8813 24.4992 33.3653 23.3525C36.0647 22.6627 38.7834 22.695 41.5277 22.9516C46.0974 23.3783 50.4014 24.9648 54.8318 25.9887C56.8735 26.4607 58.9537 26.8272 61.0361 27.0384C63.6991 27.31 66.2506 26.8142 68.6822 25.0769C65.9528 28.4546 61.8652 31.4529 57.5483 32.3906C53.5871 33.2506 49.808 32.6363 46.2067 30.8451C43.5458 29.5216 40.8807 28.2218 37.9178 27.7411C32.4398 26.853 27.1375 27.476 22.0343 29.6962C21.8908 29.7587 21.7473 29.819 21.4023 29.9635H21.4045Z"
        fill="#0066FF"
      />
      <path
        d="M25.3228 34.2249C31.1414 30.7071 37.2086 29.9096 43.5264 32.7872C46.1915 34.0008 48.9038 35.0031 51.8067 35.4062C54.7974 35.8222 57.756 35.6583 60.8367 34.6603C59.3885 36.1218 57.8353 37.0594 56.1171 37.7298C51.5582 39.506 47.1149 38.9714 42.783 36.9603C39.9851 35.6605 37.1315 34.5677 34.0722 34.1128C31.1564 33.6796 28.2428 33.658 25.3249 34.2271L25.3228 34.2249Z"
        fill="#0066FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_29363_89515">
        <rect width="69" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default EnergostroyLogo
