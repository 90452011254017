import React from 'react'

const AeonLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="81"
    height="86"
    viewBox="0 0 81 86"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M39.6158 59.6754C39.6303 54.0823 42.7213 50.5355 47.3836 49.7833C49.8717 49.3815 52.176 49.8369 54.2842 51.1909C56.8135 52.8148 58.102 55.1993 58.2713 58.1483C58.5831 63.5684 54.598 67.7334 49.149 67.7952C46.9438 67.8199 44.9224 67.2676 43.157 65.9425C40.7659 64.1475 39.6262 61.7136 39.6138 59.6754H39.6158ZM54.183 58.7192C54.183 55.9309 52.6571 53.9463 50.1009 53.4125C47.7821 52.9282 45.5253 53.8844 44.4433 55.834C43.8239 56.951 43.6773 58.1669 43.7929 59.424C44.2162 64.0321 48.9632 65.2088 51.8415 63.3747C53.5202 62.3051 54.183 60.6626 54.183 58.7192Z"
      fill="black"
    />
    <path
      d="M25.4392 50.0352H38.5507V53.3449C38.0985 53.4005 37.7062 53.4912 37.3139 53.4933C35.0075 53.5056 32.7032 53.4994 30.3968 53.4994C30.0561 53.4994 29.7154 53.4994 29.2447 53.4994C29.1869 54.6432 29.1972 55.7149 29.2364 56.9102H37.477V60.3909H29.2674V63.9686C29.6597 63.9871 30.0252 64.018 30.3906 64.018C32.73 64.0222 35.0715 64.018 37.4109 64.0222C37.7805 64.0222 38.1501 64.0572 38.4867 64.0757C38.7448 64.896 38.7799 66.3489 38.592 67.3896C37.8198 67.5771 26.4324 67.6039 25.4434 67.4205C25.2286 66.6312 25.2162 50.9708 25.4392 50.0352Z"
      fill="black"
    />
    <path
      d="M70.6974 60.5668H63.8773V67.3511C62.5827 67.7118 61.3603 67.4562 60.1153 67.5428C60.0471 67.2872 59.9522 67.0976 59.9522 66.908C59.946 61.4509 59.946 55.9917 59.9522 50.5345C59.9522 50.4026 59.9935 50.2708 60.0286 50.0482C61.2612 49.9019 62.4857 50.0008 63.8195 49.9843C63.9413 51.1446 63.8629 52.2677 63.8773 53.3847C63.8918 54.5099 63.8815 55.6352 63.8815 56.8098H70.6519C70.7717 54.5779 70.6272 52.3605 70.7448 50.1718C71.4717 49.939 73.5158 49.8916 74.5689 50.0812V67.4665H70.8563C70.7985 67.0482 70.7097 66.6896 70.7077 66.3289C70.6912 64.7853 70.7015 63.2397 70.7015 61.6941C70.7015 61.3561 70.7015 61.0181 70.7015 60.5668H70.6974Z"
      fill="black"
    />
    <path
      d="M24.1879 67.5243C23.0564 67.5243 21.9331 67.5407 20.8099 67.516C20.3825 67.5078 20.1636 67.1904 20.0129 66.8112C19.6846 65.9827 19.3377 65.1625 18.997 64.3402C18.9061 64.1218 18.807 63.9095 18.7038 63.6725H11.3221C10.9236 64.6391 10.5024 65.5747 10.1555 66.5371C9.8995 67.2502 9.48448 67.5902 8.70192 67.5531C7.78308 67.5078 6.86012 67.5428 6.01149 67.5428C5.9227 67.4027 5.90206 67.3759 5.8876 67.347C5.87315 67.3161 5.85043 67.279 5.85869 67.2522C5.88141 67.1533 5.90412 67.0503 5.94542 66.9575C8.36949 61.284 10.7956 55.6084 13.2403 49.8916H16.7526C17.3245 50.683 24.0971 66.6422 24.192 67.5263L24.1879 67.5243ZM17.2522 60.0866C16.4594 58.2484 15.873 56.4925 15.0367 54.7449C14.0621 56.5729 12.9988 59.3365 12.974 60.0866H17.2522Z"
      fill="black"
    />
    <path
      d="M37.9436 39.23C38.4557 38.9167 38.8191 38.6735 39.2011 38.4654C40.1323 37.9584 40.5948 37.2021 40.5638 36.1263C40.5349 35.133 40.5514 34.1376 40.5618 33.1443C40.5638 32.8743 40.5783 32.592 40.6547 32.3364C40.8219 31.782 41.1977 31.4956 41.6891 31.4894C42.2053 31.4812 42.6906 31.8171 42.8434 32.3385C42.9177 32.5961 42.9322 32.8764 42.9342 33.1484C42.9425 34.1088 42.9569 35.0691 42.928 36.0274C42.8929 37.1732 43.343 38.0017 44.3837 38.519C44.7389 38.6962 45.0568 38.9435 45.5689 39.2732C44.9371 39.3577 44.5365 39.4567 44.1359 39.4567C42.29 39.4608 40.4461 39.4402 38.6002 39.4154C38.4433 39.4154 38.2864 39.3289 37.9436 39.2279V39.23Z"
      fill="#3E5AC1"
    />
    <path
      d="M34.7017 17.8921C33.3141 17.1172 31.9865 16.4928 30.8323 15.5757C30.3512 15.1924 30.0827 14.636 30.058 14.0548C30.0001 12.7606 30.0394 11.4643 30.0394 10.0732C30.3284 10.1845 30.5246 10.234 30.6939 10.3288C31.5591 10.8193 32.4036 11.3489 33.2832 11.8105C34.1607 12.2722 34.6294 13.0141 34.6831 13.9476C34.7554 15.2089 34.7017 16.4763 34.7017 17.8901V17.8921Z"
      fill="#3E5AC1"
    />
    <path
      d="M49.5601 19.3552C50.9311 18.5762 52.044 17.9105 53.19 17.3067C53.7867 16.9935 54.4743 16.944 55.0855 17.2593C56.2934 17.8858 57.4682 18.5721 58.8372 19.3366C57.4703 20.093 56.2954 20.7751 55.0896 21.3954C54.561 21.6675 53.9519 21.7252 53.3944 21.4325C52.1865 20.7978 50.9951 20.1342 49.5601 19.3531V19.3552Z"
      fill="#3E5AC1"
    />
    <path
      d="M24.7411 19.3139C25.9572 18.607 27.0598 17.931 28.1975 17.321C28.9037 16.9439 29.6925 16.9707 30.3862 17.3354C31.5033 17.9228 32.5729 18.5967 33.7663 19.2953C33.471 19.5138 33.2749 19.6889 33.054 19.8188C32.2817 20.266 31.4992 20.6926 30.7249 21.1377C29.7523 21.6962 28.8046 21.7622 27.8135 21.1439C27 20.6349 26.1369 20.2083 25.3006 19.7343C25.1355 19.6415 24.993 19.5055 24.739 19.3139H24.7411Z"
      fill="#3E5AC1"
    />
    <path
      d="M53.3551 35.8708C52.1017 35.1331 50.9764 34.4859 49.8718 33.81C49.18 33.3855 48.8249 32.7136 48.8001 31.9243C48.7609 30.6631 48.7898 29.4018 48.7898 28.0293C49.0727 28.1529 49.2792 28.2271 49.4691 28.3302C50.2785 28.7774 51.0859 29.2246 51.8849 29.6883C52.9111 30.2839 53.4707 31.1103 53.3736 32.3715C53.289 33.4823 53.3551 34.6055 53.3551 35.8688V35.8708Z"
      fill="#3E5AC1"
    />
    <path
      d="M43.1467 22.9696C43.475 22.7676 43.6939 22.6151 43.9252 22.4935C44.8048 22.0278 45.6802 21.5558 46.5722 21.1107C47.5592 20.6202 48.5214 20.5831 49.5042 21.2034C50.4272 21.7866 51.408 22.2792 52.5209 22.9016C51.567 23.458 50.7307 23.9443 49.8924 24.4307C49.5662 24.6203 49.2544 24.915 48.9055 24.9686C48.3108 25.0593 47.6191 25.1747 47.1008 24.9604C45.7794 24.4101 44.5301 23.6826 43.1488 22.9716L43.1467 22.9696Z"
      fill="#3E5AC1"
    />
    <path
      d="M49.7212 26.5366C50.033 26.3243 50.2395 26.1656 50.4625 26.0358C51.2595 25.568 52.0627 25.1064 52.8638 24.6489C53.8116 24.1069 54.7428 24.1316 55.6802 24.7024C56.6445 25.2898 57.6252 25.8483 58.6886 26.4707C58.4223 26.6664 58.2261 26.8395 58.0052 26.9714C57.2412 27.4269 56.4607 27.8576 55.6988 28.3192C54.7345 28.9045 53.7868 28.9746 52.7977 28.3605C51.819 27.7504 50.8135 27.1837 49.7212 26.5387V26.5366Z"
      fill="#3E5AC1"
    />
    <path
      d="M40.4255 22.9267C38.9904 23.6645 37.7598 24.3384 36.492 24.9319C35.7466 25.2802 34.9496 25.2389 34.2187 24.8144C33.1822 24.2147 32.158 23.5923 31.0286 22.9205C31.679 22.533 32.2551 22.1868 32.8332 21.8488C33.2462 21.6057 33.6715 21.381 34.0824 21.1358C34.8773 20.6639 35.7136 20.5855 36.5416 20.9771C37.7763 21.5624 38.9863 22.2012 40.4234 22.9267H40.4255Z"
      fill="#3E5AC1"
    />
    <path
      d="M24.7018 26.5193C26.0067 25.763 27.1341 25.0561 28.3131 24.4523C28.9325 24.1349 29.6635 24.1411 30.2974 24.4873C31.4351 25.1035 32.5439 25.7712 33.8426 26.5193C32.4014 27.288 31.2389 28.17 29.8638 28.6976C29.1865 28.9573 28.5774 28.743 27.9993 28.4194C26.9565 27.8383 25.9283 27.2282 24.6997 26.5193H24.7018Z"
      fill="#3E5AC1"
    />
    <path
      d="M47.1133 6.39844C47.1133 7.89874 47.1422 9.23417 47.103 10.5675C47.0803 11.3321 46.6818 11.9421 46.0211 12.3378C44.9329 12.989 43.8303 13.6155 42.5852 14.3389C42.5852 13.4898 42.5852 12.7562 42.5852 12.0246C42.5852 11.4764 42.579 10.9261 42.5873 10.3779C42.5997 9.52681 42.9528 8.85703 43.694 8.41601C44.7822 7.7689 45.8765 7.13004 47.1133 6.4005V6.39844Z"
      fill="#3E5AC1"
    />
    <path
      d="M42.3911 21.4839C42.4427 20.843 42.5109 20.202 42.5439 19.5591C42.5769 18.9099 42.5749 18.2566 42.5873 17.6054C42.5997 16.9191 42.8123 16.2926 43.3946 15.9134C44.5612 15.1509 45.765 14.4461 47.1009 13.6279C47.1009 15.1633 47.1195 16.4946 47.0927 17.8238C47.0803 18.4029 46.7747 18.8996 46.3122 19.217C45.1043 20.0454 43.8613 20.8244 42.6327 21.622C42.5522 21.5766 42.4716 21.5313 42.3911 21.486V21.4839Z"
      fill="#3E5AC1"
    />
    <path
      d="M53.3758 10.0255C53.3758 11.4207 53.3531 12.6448 53.3841 13.8669C53.4047 14.6583 53.1033 15.3075 52.4838 15.7176C51.3234 16.4842 50.1052 17.1622 48.7858 17.9495C48.7858 16.478 48.7527 15.1818 48.7982 13.8855C48.825 13.1374 49.1801 12.4655 49.814 12.0678C50.9373 11.363 52.1122 10.7406 53.3758 10.0234V10.0255Z"
      fill="#3E5AC1"
    />
    <path
      d="M42.4509 24.1016C43.7972 24.9815 45.0629 25.7894 46.3038 26.6323C46.8469 27.0012 47.0947 27.5865 47.1091 28.2274C47.136 29.4907 47.1174 30.7561 47.1174 32.1492C45.8145 31.4629 44.6086 30.8529 43.5019 30.0698C43.0043 29.7174 42.6698 29.2001 42.6285 28.6086C42.5294 27.1537 42.5087 25.6946 42.4509 24.1016Z"
      fill="#3E5AC1"
    />
    <path
      d="M40.9211 14.3247C39.5604 13.5457 38.3896 12.9192 37.2684 12.2123C36.7749 11.9011 36.4053 11.39 36.3909 10.7697C36.362 9.40746 36.3826 8.04524 36.3826 6.58203C36.6449 6.6624 36.8348 6.6892 36.9917 6.77575C37.8548 7.26417 38.7117 7.76496 39.5665 8.26781C40.5019 8.81806 40.9541 9.62797 40.9273 10.7264C40.9004 11.8496 40.9211 12.9748 40.9211 14.3226V14.3247Z"
      fill="#3E5AC1"
    />
    <path
      d="M40.9683 24.2867C40.9476 25.4594 40.8919 26.5908 40.9187 27.718C40.9517 29.0638 40.4252 30.0035 39.1925 30.6012C38.2737 31.0443 37.4086 31.5966 36.3989 32.1674C36.3989 30.6609 36.3452 29.2905 36.4298 27.9303C36.4567 27.5078 36.7643 26.9741 37.1112 26.7247C38.3315 25.8447 39.6261 25.0637 40.896 24.2517C40.9208 24.2352 40.9951 24.3012 40.9641 24.2847L40.9683 24.2867Z"
      fill="#3E5AC1"
    />
    <path
      d="M36.3783 13.624C37.6977 14.4113 38.8271 15.044 39.9091 15.7467C40.6152 16.2063 40.9002 16.9441 40.9043 17.7808C40.9105 19.0049 40.9043 20.2291 40.9043 21.5192C40.7763 21.4718 40.5615 21.4264 40.384 21.3192C39.5064 20.7875 38.633 20.2476 37.7699 19.6932C36.8511 19.1038 36.2771 18.3475 36.3597 17.1584C36.4361 16.0744 36.3762 14.9801 36.3762 13.624H36.3783Z"
      fill="#3E5AC1"
    />
    <path
      d="M30.1841 35.7906C30.1841 34.28 30.1697 32.9157 30.1903 31.5514C30.1986 30.962 30.5351 30.4798 30.9976 30.1768C32.1085 29.4514 33.2627 28.7919 34.4995 28.0459C34.5863 28.3777 34.6709 28.559 34.673 28.7404C34.6874 29.6657 34.6936 30.5911 34.6751 31.5164C34.6523 32.621 34.2229 33.4845 33.2029 34.0347C32.2179 34.5664 31.264 35.1579 30.1841 35.7885V35.7906Z"
      fill="#3E5AC1"
    />
    <path
      d="M18.5262 77.6843H15.4043C15.27 77.9852 15.1524 78.249 15.0202 78.5478C14.312 78.2758 14.6444 77.7379 14.5474 77.2804C15.3526 76.8559 15.5116 76.1222 15.5219 75.3041C15.5302 74.6652 15.5839 74.0263 15.619 73.3545H18.5902C18.7348 74.5086 18.5985 75.6626 18.677 76.86C18.8463 76.9589 19.0404 77.0702 19.2345 77.1836V78.451H18.7079C18.6522 78.216 18.5985 77.9935 18.5262 77.6823V77.6843ZM16.2715 73.9851C16.1558 74.9826 16.0402 75.9738 15.9225 76.9754H17.9192V73.9851H16.2715Z"
      fill="black"
    />
    <path
      d="M24.5533 73.3201C25.2057 73.3201 25.8851 73.2748 26.5582 73.3305C27.795 73.4335 28.146 74.2517 27.4399 75.3109C27.4667 75.3728 27.4791 75.4469 27.5225 75.4943C28.4144 76.4465 28.1997 77.3079 26.9402 77.5428C26.1783 77.685 25.3792 77.6335 24.6255 77.6665C24.3778 76.8916 24.3406 74.5134 24.5533 73.3201ZM25.0798 77.0256C25.7054 77.0256 26.2113 77.0503 26.711 77.0173C27.1012 76.9926 27.3407 76.7535 27.316 76.3434C27.2933 75.9786 27.0414 75.7952 26.7048 75.7787C26.2051 75.754 25.7034 75.7726 25.15 75.7726C25.1252 76.2053 25.1066 76.533 25.0798 77.0256ZM25.1768 73.9837V75.1873C25.8293 75.1522 26.3889 75.1234 27.0806 75.0863C27.0434 74.6515 27.0186 74.3526 26.9877 73.9837H25.1768Z"
      fill="black"
    />
    <path
      d="M52.6986 74.9078C52.2547 75.625 51.8128 76.3442 51.311 77.1562C50.4624 76.5854 50.3199 75.5694 49.6737 74.9346C49.5973 75.8187 49.525 76.6843 49.4362 77.5478C49.4321 77.5828 49.2586 77.6014 49.1678 77.6261C48.8849 76.9769 48.8271 74.5966 49.0563 73.4096C49.3949 73.2323 49.556 73.459 49.7026 73.7022C49.9503 74.1144 50.1919 74.5286 50.4376 74.9408C50.6648 75.3221 50.8919 75.7033 51.1562 76.1443C51.6373 75.6806 51.8376 75.0912 52.1968 74.6152C52.5355 74.1659 52.5602 73.4034 53.4894 73.3271C53.5596 74.7574 53.5224 76.1361 53.508 77.5457C53.3015 77.5746 53.1735 77.5931 52.9381 77.6281V74.9676C52.8576 74.947 52.777 74.9264 52.6986 74.9058V74.9078Z"
      fill="black"
    />
    <path
      d="M42.6162 75.4084C42.6286 76.6841 41.6457 77.6651 40.3449 77.6754C39.0998 77.6857 38.053 76.7398 38.0488 75.5321C38.0447 74.4605 38.7178 73.3126 40.3325 73.2961C41.6354 73.2816 42.6059 74.1843 42.6182 75.4084H42.6162ZM40.3449 77.0365C41.2534 77.0303 41.9307 76.377 41.9389 75.4991C41.9472 74.5697 41.2493 73.9081 40.285 73.9329C39.3951 73.9555 38.755 74.5553 38.7302 75.3961C38.7013 76.3132 39.4219 77.0448 40.347 77.0386L40.3449 77.0365Z"
      fill="black"
    />
    <path
      d="M46.8572 77.5859C46.659 76.4277 46.8221 75.2695 46.7519 74.0742C46.0375 73.8021 45.2942 74.0103 44.4621 73.9402V77.5096C44.2267 77.5611 44.078 77.592 43.7931 77.6539C43.7352 76.1927 43.76 74.8078 43.7765 73.3652H47.4271C47.4519 73.6744 47.4932 73.9711 47.4952 74.2658C47.5035 75.1932 47.5056 76.1185 47.4911 77.0459C47.4849 77.421 47.4622 77.421 46.8572 77.5838V77.5859Z"
      fill="black"
    />
    <path
      d="M59.9541 75.0824H62.1407C62.1778 74.5424 62.2171 73.9839 62.2563 73.4028C62.4359 73.376 62.564 73.3554 62.6734 73.3389C62.9439 73.615 62.9583 76.6816 62.7126 77.5842C62.1737 77.6481 62.2233 77.2607 62.2067 76.9248C62.1902 76.5888 62.1758 76.2509 62.1572 75.8387H60.0305C59.6588 76.3848 60.1915 77.102 59.6464 77.5739C59.5906 77.6213 59.4564 77.5801 59.3635 77.5801C59.1075 76.9124 59.0497 74.9752 59.2396 73.3842H59.8343C59.8735 73.9571 59.9107 74.4888 59.952 75.0803L59.9541 75.0824Z"
      fill="black"
    />
    <path
      d="M29.9052 77.0859H32.2941C32.2549 77.3105 32.2735 77.4568 32.2178 77.4918C31.8915 77.7021 30.4234 77.7185 29.1866 77.5331C29.135 76.2038 29.1557 74.8519 29.166 73.3846C30.2066 73.2589 31.2019 73.3124 32.2446 73.3578C32.2136 73.5927 32.1971 73.7184 32.1661 73.9451H29.8742V75.0044C30.5721 75.3815 31.4084 74.8395 32.0773 75.4578C31.4063 76.0307 30.6299 75.5814 29.9031 75.8617V77.0859H29.9052Z"
      fill="black"
    />
    <path
      d="M57.9844 73.4217V73.9039C57.6148 73.9183 57.2535 73.9369 56.8901 73.9431C56.5184 73.9493 56.1447 73.9431 55.6801 73.9431C55.6615 74.3367 55.645 74.6582 55.6264 75.0765C56.3326 75.1219 56.9644 75.161 57.5611 75.1981C57.7697 75.5547 57.6293 75.7195 57.3381 75.7422C56.8033 75.7855 56.2644 75.7896 55.676 75.8102V77.0076H57.9369C58.1393 77.4259 58.0381 77.6155 57.7284 77.6217C56.8446 77.6402 55.9609 77.6279 55.009 77.6279V73.4876C55.7028 73.2733 56.793 73.2444 57.9823 73.4217H57.9844Z"
      fill="black"
    />
    <path
      d="M20.3349 73.3741H23.2731C23.24 73.5679 23.2173 73.6977 23.1781 73.9182H20.9688C20.946 74.3345 20.9275 74.6622 20.9027 75.1032C21.2929 75.1197 21.6233 75.132 21.9537 75.1465C22.2861 75.1609 22.6185 75.1733 22.9861 75.1897C22.8773 75.4769 22.2076 75.6953 20.977 75.8451V77.0301H23.2627C23.4321 77.535 23.2153 77.638 22.8952 77.6401C22.0734 77.6442 21.2516 77.6401 20.4464 77.6401C20.2048 76.9003 20.1532 74.8662 20.3328 73.3721L20.3349 73.3741Z"
      fill="black"
    />
    <path
      d="M33.9171 73.3779H36.9317V77.5388C36.7604 77.5718 36.6344 77.5944 36.5146 77.6171C36.4733 77.5697 36.432 77.545 36.43 77.5182C36.368 76.3682 36.3082 75.2183 36.2462 74.0086H34.5262C34.4746 74.6577 34.454 75.3007 34.3652 75.9334C34.3032 76.3682 34.2145 76.8257 34.0121 77.2049C33.8923 77.4275 33.5351 77.5553 33.2585 77.6418C33.1511 77.6748 32.9714 77.4728 32.8042 77.3636C33.3713 76.8828 33.6955 76.2652 33.7767 75.5109C33.8118 75.1709 33.8263 74.8267 33.849 74.4846C33.8717 74.1466 33.8903 73.8086 33.9171 73.38V73.3779Z"
      fill="black"
    />
    <path
      d="M67.3484 73.3601C67.1729 74.3287 66.38 73.7022 65.9319 74.1349V77.525C65.6986 77.5663 65.5499 77.593 65.2629 77.6425V74.172C64.7694 73.7063 64.0096 74.2957 63.7329 73.3807C64.9821 73.2611 66.1363 73.2982 67.3463 73.3559L67.3484 73.3601Z"
      fill="black"
    />
  </svg>
)

export default AeonLogo
