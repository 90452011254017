// extracted by mini-css-extract-plugin
export var item = "ModuleItem-module--item--htXsV";
export var brand_button = "ModuleItem-module--brand_button--16KzW";
export var image = "ModuleItem-module--image--v8b4M";
export var brand_button_docs = "ModuleItem-module--brand_button_docs--GKcxz";
export var brand_button_inspection = "ModuleItem-module--brand_button_inspection--uZAIr";
export var brand_button_tools = "ModuleItem-module--brand_button_tools--vVg1e";
export var brand_button_dashboard = "ModuleItem-module--brand_button_dashboard--NM7mj";
export var logo = "ModuleItem-module--logo--fsSRM";
export var module_text = "ModuleItem-module--module_text--6extz";
export var left0 = "ModuleItem-module--left0--2NMem";
export var left = "ModuleItem-module--left--UFYXo";
export var right = "ModuleItem-module--right--THzD6";
export var modules = "ModuleItem-module--modules--IASHp";
export var subtitle = "ModuleItem-module--subtitle--jO9yR";
export var list_item = "ModuleItem-module--list_item--P5WBb";