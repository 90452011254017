import React from 'react'

const AagLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="110"
    height="36"
    viewBox="0 0 110 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_29202_115442)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.2302 17.5496C80.1678 22.4756 84.2363 26.5407 89.2443 26.5407H89.3067L92.3124 35.0993H89.1819C79.542 35.0993 71.6547 27.2178 71.6547 17.5496C71.6547 7.88148 79.542 0 89.1819 0H99.7612L103.017 8.43555H89.1819C84.1739 8.56 80.1678 12.6237 80.2302 17.5496ZM63.5177 35.0993L56.9444 17.5496H65.7712L72.3444 35.0993H63.5177ZM37.6647 35.0993L50.8082 0H59.635L46.4885 35.0993H37.6647ZM20.5106 17.5496H29.3389L35.9106 35.0993H27.0839L20.5106 17.5496ZM1.16848 35.0993L14.315 0H23.1417L9.99375 35.0993H1.16848ZM108.841 35.0993H100.011L93.4377 17.5496H102.264L108.838 35.0993H108.841Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_29202_115442">
        <rect width="110" height="35.5556" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default AagLogo
