import React from 'react'

const GranelWhiteLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="140"
    height="15"
    viewBox="0 0 140 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.2036 0.078125L58.2271 0.417969C58.2363 0.571289 58.2432 0.723633 58.2432 0.882812L58.2383 7.24707L58.2388 8.92285C58.2407 10.6436 58.2471 12.3633 58.2627 14.0801C58.2627 14.7666 58.1055 15.0801 57.3799 14.9824C57.1572 14.9551 56.9302 14.9492 56.6948 14.9502C56.4326 14.9521 56.1592 14.9629 55.8701 14.9629V8.53027L55.6831 8.51855C55.4575 8.50391 55.2715 8.49121 55.0854 8.49121C50.9873 8.49121 46.9082 8.51074 42.8096 8.47168C42.2407 8.47168 41.9858 8.66797 41.8486 9.19727C41.6299 9.9375 41.4004 10.6777 41.1709 11.418C40.8652 12.4033 40.5596 13.3887 40.2798 14.374C40.1426 14.8447 39.9268 15.002 39.4561 14.9629C39.0786 14.9375 38.7012 14.9453 38.2959 14.9531H38.231C38.0034 14.958 37.7661 14.9629 37.5146 14.9629L38.0923 13.2256C38.272 12.6855 38.4497 12.1572 38.6323 11.6289C39.8677 7.98145 41.084 4.33398 42.3193 0.666992C42.4565 0.274414 42.5742 0 43.1035 0C47.9868 0.0195312 52.8501 0.0195312 57.7329 0.0195312C57.8506 0.0195312 57.9878 0.0585938 58.2036 0.078125ZM55.811 2.03906L50.9814 2.04102C49.6514 2.04199 48.3271 2.04395 47.0034 2.04785L44.3389 2.05957C44.1821 2.05957 43.9272 2.25488 43.8882 2.39258L43.7114 2.96875C43.6318 3.23438 43.5542 3.50098 43.4775 3.76953C43.356 4.19336 43.2373 4.62109 43.1177 5.05176L42.9316 5.7207L42.731 6.43262H55.811V2.03906Z"
      fill="white"
    />
    <path
      d="M98.6802 14.9238H81.4033V0.0585938H98.6802V2.03906H84.7173C84.4409 2.03906 84.2427 2.03906 84.1001 2.06152C83.7368 2.11816 83.7368 2.32129 83.7368 3.03906V6.45117H98.6606V8.47168L93.228 8.47559C91.8818 8.47656 90.5356 8.47754 89.1895 8.47559C87.6528 8.47266 86.1167 8.46582 84.5801 8.45215C84.2495 8.45215 84.0254 8.49512 83.8877 8.62305C83.8574 8.65137 83.8315 8.68359 83.8096 8.71973C83.7671 8.79004 83.7393 8.87793 83.7246 8.98438C83.7119 9.07812 83.71 9.1875 83.7173 9.31445C83.7529 9.80957 83.7456 10.3115 83.7388 10.8115C83.7339 11.1445 83.729 11.4766 83.7368 11.8057C83.7417 12.1602 83.73 12.4131 83.7368 12.5947C83.7432 12.7588 83.7642 12.8633 83.8262 12.9297C83.9341 13.0449 84.167 13.0439 84.6616 13.041H98.6606C98.6802 13.668 98.6802 14.2373 98.6802 14.9238Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0225 14.9238H22.4346V8.49121H23.376L26.7852 8.49512C29.0532 8.5 31.3188 8.50391 33.5928 8.49121C33.8169 8.49121 34.0449 8.4834 34.2715 8.46484C34.4043 8.4541 34.5366 8.43945 34.6675 8.4209C34.8218 8.39844 34.9741 8.37012 35.1226 8.33398C37.064 7.88281 38.3975 6.33398 38.4565 4.4707C38.5347 2.49023 37.3975 0.921875 35.4165 0.333008C34.8086 0.157227 34.1616 0.0585938 33.5342 0.0585938C29.2788 0.0195312 25.0034 0.0390625 20.748 0.0390625C20.5127 0.0390625 20.2773 0.0585938 20.0225 0.078125V14.9238ZM25.3291 6.47363L22.4346 6.47168V2.03906C22.6699 2.01953 22.9053 2 23.1406 2H33.436C33.5732 2 33.73 2 33.8677 2.01953C35.1616 2.15723 36.1226 3.0791 36.1226 4.21582C36.1226 4.92188 35.77 5.57812 35.2041 5.99414C34.8267 6.27246 34.354 6.44434 33.8281 6.45215C32.3467 6.46777 30.8652 6.47363 29.3779 6.47559C28.0347 6.47754 26.6865 6.47559 25.3291 6.47363Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M123.86 6.49023H124.782C127.939 6.49023 131.097 6.4707 134.254 6.51074C134.577 6.51074 134.907 6.53223 135.237 6.57422C135.688 6.63086 136.138 6.72754 136.568 6.86328C138.49 7.47168 139.686 9.33398 139.451 11.1973C139.176 13.2363 137.509 14.7861 135.352 14.9424C134.837 14.9805 134.322 14.9863 133.802 14.9854L132.92 14.9824H121.487V0.0976562H123.86V6.49023ZM123.899 12.9814H126.723C127.638 12.9746 128.551 12.9746 129.463 12.9746C131.288 12.9746 133.11 12.9746 134.94 12.9229C136.156 12.9033 137.019 12.001 137.078 10.9033C137.137 9.82422 136.431 8.88281 135.312 8.62793C135.104 8.57617 134.894 8.55859 134.685 8.54102C134.581 8.53223 134.476 8.52344 134.372 8.51074C134.294 8.49805 134.217 8.50195 134.135 8.50684L133.999 8.51074H124.684C124.627 8.51074 124.567 8.50586 124.505 8.50098C124.223 8.47656 123.919 8.45215 123.919 8.90234C123.907 9.31641 123.9 9.72852 123.897 10.1426C123.893 10.7197 123.895 11.3008 123.896 11.8975V11.9121L123.899 12.9814Z"
      fill="white"
    />
    <path
      d="M62.9888 0.0585938H60.6748V14.9238H63.0083V14.0801L63.0112 12.8877C63.0146 11.6963 63.0181 10.5059 62.9888 9.31445C62.9692 8.70703 63.1064 8.47168 63.793 8.47168C66.7646 8.50098 69.7476 8.49707 72.7246 8.49316L75.6963 8.49121C75.8687 8.49121 76.041 8.50195 76.2593 8.51562L76.52 8.53027V14.9238H78.834V0.078125H76.4614V6.43164H62.9888V0.0585938Z"
      fill="white"
    />
    <path
      d="M119.095 14.9434H116.781V2.09766H106.857L106.799 2.94141C106.76 5.29492 106.818 7.62793 106.681 9.96191C106.505 12.7666 104.171 14.8838 101.367 14.9629C101.17 14.9629 100.994 14.9434 100.759 14.9229V13.0996L100.865 13.0615C100.954 13.0293 101.048 12.9951 101.131 12.9814C103.367 12.9033 104.367 11.3936 104.387 9.35352C104.4 7.4834 104.396 5.60449 104.391 3.72852L104.387 0.920898V0.078125H119.075C119.095 5.01953 119.095 9.94238 119.095 14.9434Z"
      fill="white"
    />
    <path
      d="M17.7476 0.0585938H0V14.8643H2.25537V13.9229C2.25537 10.2363 2.25537 6.5498 2.23584 2.86328C2.21582 2.21582 2.39258 2 3.07861 2C5.12305 2.01758 7.16357 2.02344 9.20361 2.02441C11.7427 2.02539 14.2812 2.01953 16.8257 2.01953H17.7476V0.0585938Z"
      fill="white"
    />
  </svg>
)

export default GranelWhiteLogo
