import React from 'react'

const PridexLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="150"
    height="36"
    viewBox="0 0 150 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_29202_115005)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.8345 0V21.2582H46.4819V12.743H52.7179C57.2184 12.743 59.8364 10.3611 59.8364 6.34173C59.8364 2.38187 57.2184 0 52.7179 0H43.8345ZM46.4819 10.3611V2.38187H52.2767C55.5418 2.38187 57.1596 3.72167 57.1596 6.37151C57.1596 9.02137 55.5418 10.3611 52.2767 10.3611H46.4819Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.0288 0V21.2582H66.6761V12.356H72.7945C73.3828 12.356 73.9417 12.3262 74.5005 12.2666L77.6482 21.2582H80.3838L77.0303 11.6414C79.1776 10.6589 80.2369 8.8427 80.2369 6.19286C80.2369 2.29255 77.707 0 73.324 0H64.0288ZM66.6761 9.97411V2.38187H72.9416C76.2067 2.38187 77.5601 3.6919 77.5601 6.19286C77.5601 8.72358 76.0302 9.97411 72.9416 9.97411H66.6761Z"
        fill="black"
      />
      <path d="M87.7075 21.2582H85.06V0H87.7075V21.2582Z" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.2428 0V21.2582H101.097C104.245 21.2582 106.627 20.3054 108.304 18.3999C109.98 16.4944 110.804 13.9041 110.804 10.6291C110.804 7.35403 109.98 4.76374 108.304 2.85824C106.656 0.952748 104.245 0 101.097 0H93.2428ZM95.8902 18.8168V2.44142H101.097C103.509 2.44142 105.274 3.21553 106.421 4.73397C107.569 6.25241 108.127 8.21747 108.127 10.6291C108.127 13.0408 107.569 15.0356 106.421 16.554C105.274 18.0725 103.509 18.8168 101.097 18.8168H95.8902Z"
        fill="black"
      />
      <path
        d="M127.399 11.4627H117.751V18.8168H130.193V21.2582H115.103V0H129.723V2.44142H117.751V9.02137H127.399V11.4627Z"
        fill="black"
      />
      <path
        d="M150 21.2582L143.735 9.91456L149.177 0H146.353L141.705 8.48543H140.617L136.028 0H133.175L138.558 10.0336L132.263 21.2582H135.145L140.587 11.6116H141.676L146.97 21.2582H150Z"
        fill="black"
      />
      <path
        d="M28.2617 15.5094V0.241508H18.3983L10.7491 6.01854H20.6126V21.2865L28.2617 15.5094Z"
        fill="black"
      />
      <path
        d="M0 14.1064L7.64919 8.32935H17.5126V30.0758L9.86343 35.8528V14.1064H0Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_29202_115005">
        <rect width="150" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default PridexLogo
