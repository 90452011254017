import React from 'react'

const UniqLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="99"
    height="43"
    viewBox="0 0 99 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_29202_115128)">
      <path
        d="M45.0761 33.3111C46.1522 37.1486 48.3442 38.4937 51.134 38.4937C54.0436 38.4937 56.8732 36.5947 56.8732 32.7573V2.37371C56.8732 1.18686 55.9566 0.356056 54.7609 0.356056C53.5653 0.356056 52.6486 1.18686 52.6486 2.37371V32.7573C52.6486 34.0231 52.1703 34.7749 51.134 34.7749C50.2573 34.7749 49.6993 34.2606 49.3805 33.0738L41.6884 5.18261C40.6123 1.3451 38.4203 0 35.6305 0C32.7209 0 29.8913 1.89897 29.8913 5.73646V36.1596C29.8913 37.3465 30.8079 38.1772 32.0036 38.1772C33.1992 38.1772 34.1159 37.3465 34.1159 36.1596V5.73646C34.1159 4.47049 34.5942 3.71882 35.6305 3.71882C36.5072 3.71882 37.0652 4.23313 37.384 5.41998L45.0761 33.3111ZM99 12.0268C99 3.87707 94.4964 0 87.8405 0C81.1849 0 76.6811 3.87707 76.6811 12.0268V26.823C76.6811 34.3001 81.1849 38.1772 87.8405 38.1772H92.9819C94.0181 38.1772 94.7754 38.7707 94.7754 40.1158C94.7754 41.3027 95.692 42.1729 96.8877 42.1729C98.0833 42.1729 99 41.3027 99 40.1158C99 36.1596 96.2899 34.221 92.9819 34.221H87.8405C83.6957 34.221 80.9059 31.6495 80.9059 26.823V12.0268C80.9059 6.1321 83.6957 3.99576 87.8405 3.99576C91.9856 3.99576 94.7754 6.1321 94.7754 12.0268V28.4846C94.7754 29.6714 95.692 30.5813 96.8877 30.5813C98.0833 30.5813 99 29.6714 99 28.4846V12.0268ZM64.8443 36.0805C64.8443 37.2672 65.8008 38.1772 66.9166 38.1772C68.1124 38.1772 69.0689 37.2672 69.0689 36.0805V2.41327C69.0689 1.22642 68.1124 0.356056 66.9166 0.356056C65.7211 0.356056 64.8443 1.22642 64.8443 2.41327V36.0805ZM11.1594 34.5375C7.01449 34.5375 4.22464 32.3615 4.22464 26.467V2.41327C4.22464 1.22642 3.30797 0.356056 2.11232 0.356056C0.916666 0.356056 0 1.22642 0 2.41327V26.467C0 34.6166 4.46377 38.4937 11.1594 38.4937C17.8551 38.4937 22.3189 34.6166 22.3189 26.467V2.41327C22.3189 1.22642 21.4022 0.356056 20.2064 0.356056C19.0109 0.356056 18.0941 1.22642 18.0941 2.41327V26.467C18.0941 32.3615 15.2645 34.5375 11.1594 34.5375Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_29202_115128">
        <rect width="99" height="42.9398" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default UniqLogo
