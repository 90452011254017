import React from 'react'

const LevelGroupWhiteLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="63"
    height="64"
    viewBox="0 0 63 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_29202_115545)">
      <mask
        id="mask0_29202_115545"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="63"
        height="64"
      >
        <path d="M63 0H0V64H63V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_29202_115545)">
        <path
          d="M3.01574 60.6582V39.4363H0V63.7078H13.4262V60.6582H3.01574ZM40.1547 50.1725L36.3954 60.4493L32.6361 50.1725H29.3312L34.3712 63.666H38.4197L43.4597 50.1725H40.1547ZM59.9842 63.666H63V39.3945H59.9842V63.666ZM25.6545 55.2691H18.0945C19.1272 51.8853 24.7042 51.8436 25.6545 55.2691ZM21.8951 49.8801C17.7639 49.8801 14.8308 52.7626 14.8721 56.9402C14.9134 61.1595 17.7226 64.0002 22.019 64.0002C24.7869 64.0002 27.4308 62.5798 28.5462 59.2796H25.2826C23.5475 62.4963 18.2597 61.4519 18.0118 58.0681H28.8354C29.3725 53.431 26.3154 49.8801 21.8951 49.8801ZM54.6964 55.2691H47.1364C48.1692 51.8853 53.7462 51.8436 54.6964 55.2691ZM50.9371 49.8801C46.8059 49.8801 43.8728 52.7626 43.9141 56.9402C43.9554 61.1595 46.7646 64.0002 51.061 64.0002C53.8288 64.0002 56.4728 62.5798 57.5882 59.2796H54.3246C52.5895 62.4963 47.3016 61.4519 47.0538 58.0681H57.8361C58.4145 53.431 55.3574 49.8801 50.9371 49.8801ZM59.9842 3.04982V36.4284H63V0.0419922H0V36.4284H3.01574V3.04982H59.9842Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_29202_115545">
        <rect width="63" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default LevelGroupWhiteLogo
