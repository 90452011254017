import React from 'react'

const ChajkaLabLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="70"
    height="54"
    viewBox="0 0 70 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_29395_17893)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.6402 31.1689H58.037V29.6514H64.9648V28.0879H56.4283V36.4407L56.4624 36.4438C56.4843 36.446 56.5046 36.4481 56.5237 36.4501C56.5805 36.4561 56.6268 36.461 56.674 36.461H56.7366C58.8831 36.4628 61.0313 36.4646 63.1778 36.461C63.3215 36.461 63.4689 36.4389 63.609 36.4039C64.8519 36.1004 65.6599 34.8533 65.5125 33.4701C65.376 32.1972 64.3006 31.1855 63.0449 31.1726C62.2449 31.1635 61.4457 31.1654 60.6462 31.1673H60.6427C60.3089 31.1681 59.9742 31.1689 59.6402 31.1689ZM58.0805 34.8233C58.0682 34.8157 58.054 34.807 58.037 34.7981V32.8115C58.082 32.8088 58.1261 32.8056 58.1697 32.8024C58.2724 32.7949 58.3717 32.7876 58.4701 32.7876C59.0034 32.787 59.5365 32.7873 60.0696 32.7876H60.0727C61.0044 32.7881 61.9361 32.7886 62.8684 32.7839C63.256 32.7821 63.5963 32.852 63.791 33.2291C64.0021 33.6338 64.0276 34.066 63.7619 34.4376C63.6254 34.6289 63.3561 34.8367 63.1432 34.8404C61.8004 34.8642 60.4553 34.858 59.1106 34.8518H59.1065C58.7835 34.8503 58.46 34.8488 58.1371 34.8478C58.1204 34.8478 58.1038 34.8375 58.0805 34.8233Z"
        fill="black"
      />
      <path
        d="M43.743 17.2832V25.6691H42.1543V19.3341C42.1216 19.3194 42.0888 19.3047 42.0561 19.2882C42.0216 19.3283 41.9864 19.368 41.9511 19.4077C41.8879 19.4789 41.8245 19.5504 41.7649 19.6248C41.433 20.0427 41.1009 20.4604 40.7688 20.878C39.588 22.3633 38.4073 23.8482 37.2356 25.3399C37.0372 25.5937 36.8407 25.7188 36.5095 25.7022C36.0786 25.6814 35.6478 25.6854 35.2028 25.6896C35.0182 25.6913 34.8311 25.693 34.6406 25.693V17.32H36.2347V23.8021C36.2511 23.8076 36.2675 23.8136 36.2839 23.8196C36.3002 23.8256 36.3166 23.8315 36.333 23.8371C36.3797 23.7793 36.4268 23.7219 36.4739 23.6644C36.5422 23.5809 36.6109 23.4971 36.6787 23.4121L37.5113 22.3604C38.7699 20.7707 40.0285 19.1812 41.2809 17.5867C41.4501 17.3715 41.6175 17.2648 41.8996 17.274C42.3307 17.2897 42.7627 17.2868 43.2035 17.2838H43.2046C43.383 17.2826 43.5629 17.2813 43.7448 17.2813L43.743 17.2832Z"
        fill="black"
      />
      <path
        d="M46.7237 25.6638V23.0646C48.1463 23.5784 49.5437 24.0836 50.9411 24.5888C52.0604 24.9935 53.1797 25.3982 54.312 25.8072C54.312 25.6553 54.3097 25.5108 54.3074 25.3715C54.3025 25.0658 54.298 24.7846 54.3193 24.5068C54.3466 24.1609 54.1992 24.0248 53.8953 23.9181C52.1754 23.3158 50.4606 22.7008 48.7456 22.0857L47.6845 21.7053C47.5766 21.6676 47.4707 21.625 47.3382 21.5717L47.3335 21.5698L47.3319 21.5692C47.2852 21.5504 47.235 21.5302 47.1804 21.5085L47.3307 21.4482C47.4885 21.3848 47.6124 21.335 47.7373 21.2896C48.227 21.1108 48.7167 20.9323 49.2064 20.7537L49.2092 20.7527L49.2125 20.7515C50.7714 20.1831 52.3304 19.6147 53.888 19.04C54.0245 18.9903 54.2338 18.8781 54.241 18.7825C54.2666 18.4021 54.2633 18.0196 54.2599 17.6145C54.2587 17.4711 54.2574 17.3248 54.2574 17.1748L51.225 18.2846C49.7234 18.8342 48.2293 19.3809 46.7091 19.9376V17.3091H45.1205V25.6638H46.7237Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.6841 36.4634C55.5177 36.4634 55.359 36.4647 55.2058 36.466C54.8162 36.4692 54.4621 36.4721 54.1082 36.4523C53.9826 36.445 53.817 36.3291 53.7533 36.2169C53.4858 35.746 53.2456 35.2585 53.0073 34.7711C52.9181 34.5908 52.8053 34.5173 52.6015 34.5191C51.1475 34.5265 49.6935 34.5265 48.2395 34.5191C48.0357 34.5191 47.9247 34.589 47.8355 34.7711C47.599 35.2585 47.3551 35.7442 47.0876 36.215C47.0239 36.3291 46.8583 36.4468 46.7346 36.4523C46.3802 36.4722 46.024 36.4692 45.6473 36.4659C45.502 36.4647 45.353 36.4634 45.2006 36.4634C45.2167 36.4161 45.2304 36.3726 45.243 36.3322C45.2688 36.2498 45.2908 36.1799 45.3225 36.1157C45.7502 35.2535 46.1787 34.3917 46.6072 33.5299C47.464 31.8067 48.3209 30.0835 49.1712 28.357C49.2877 28.1197 49.4315 28.0462 49.6789 28.0554C50.1284 28.0719 50.5815 28.083 51.031 28.0517C51.384 28.0278 51.5697 28.1528 51.728 28.4784C52.5734 30.2048 53.4302 31.9264 54.2867 33.6473L54.2874 33.6485L54.2884 33.6506C54.6132 34.3031 54.9379 34.9555 55.2619 35.608C55.3367 35.7571 55.4097 35.9074 55.49 36.0729L55.4905 36.0739C55.5487 36.1938 55.611 36.3223 55.6805 36.4634H55.6841ZM50.4214 29.7513L49.8855 30.7943C49.518 31.5094 49.1617 32.2027 48.7946 32.9188H52.0446C51.7794 32.4008 51.5191 31.8931 51.2556 31.3792C50.9839 30.8492 50.7087 30.3126 50.4214 29.7513Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.481 25.4835C23.4526 25.547 23.4215 25.6165 23.3855 25.6949L23.3873 25.6967C23.5511 25.6967 23.7084 25.6956 23.8613 25.6944C24.2182 25.6918 24.5507 25.6894 24.8831 25.7022C25.1252 25.7114 25.2489 25.6158 25.349 25.4024C25.463 25.1624 25.5858 24.9264 25.7085 24.6905C25.8228 24.4707 25.9371 24.251 26.0441 24.0284C26.1388 23.8352 26.2534 23.7506 26.4772 23.7506C27.9167 23.7598 29.3579 23.7598 30.7973 23.7506C31.0157 23.7488 31.1412 23.8168 31.2377 24.0173C31.4652 24.4919 31.7108 24.9609 31.9583 25.4263C32.0093 25.5238 32.0966 25.671 32.1712 25.6746C32.5938 25.69 33.0163 25.6884 33.4421 25.6867C33.5754 25.6862 33.7095 25.6857 33.8436 25.6857C33.8387 25.6478 33.8362 25.6196 33.8343 25.5979C33.8312 25.5625 33.8296 25.5441 33.8217 25.5293L33.2084 24.2932C32.0832 22.0254 30.9584 19.7583 29.8219 17.4966C29.7673 17.3899 29.5799 17.2979 29.4489 17.2924C29.2023 17.2804 28.9548 17.2823 28.7073 17.2841C28.4598 17.2859 28.2124 17.2878 27.9658 17.2758C27.6655 17.2611 27.5036 17.3604 27.3653 17.64C26.4122 19.5781 25.4495 21.5107 24.487 23.4429C24.1944 24.0304 23.9017 24.618 23.6093 25.2056C23.5666 25.2921 23.5271 25.3803 23.481 25.4835ZM27.812 20.5955L27.8225 20.5751C28.089 20.0564 28.3586 19.5315 28.6391 18.9846C29.1978 20.0662 29.7164 21.0724 30.2641 22.1356H27.0213C27.2869 21.6179 27.5479 21.1097 27.812 20.5955Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.4779 25.6932H65.4755C65.4699 25.6556 65.4665 25.6275 65.4639 25.6059C65.4591 25.5667 65.4569 25.5484 65.4488 25.5331L64.7364 24.0972C63.6443 21.8956 62.5521 19.6939 61.449 17.4985C61.3944 17.39 61.207 17.2999 61.076 17.2943C60.8294 17.2824 60.5819 17.2842 60.3344 17.2861C60.0869 17.2879 59.8395 17.2898 59.5929 17.2778C59.2926 17.2631 59.1307 17.3606 58.9924 17.642C58.0886 19.4797 57.1763 21.3115 56.2636 23.1439L56.2577 23.1558L56.2559 23.1595L56.2524 23.1664C55.9137 23.8466 55.5748 24.5269 55.2364 25.2076C55.1915 25.2971 55.1505 25.3897 55.1018 25.4995C55.0755 25.5589 55.0469 25.6234 55.0144 25.695C55.177 25.695 55.3337 25.6939 55.486 25.6927H55.4871C55.8438 25.6901 56.1778 25.6876 56.5102 25.7005C56.7541 25.7097 56.8742 25.6104 56.9743 25.3989C57.0912 25.1527 57.2169 24.9107 57.3425 24.6687L57.3444 24.665C57.4547 24.4526 57.5651 24.24 57.6694 24.0248C57.7622 23.8317 57.8805 23.7489 58.1043 23.7489C59.5456 23.7581 60.9868 23.7581 62.4262 23.7489C62.6482 23.7471 62.772 23.8225 62.8666 24.0193C63.0959 24.4957 63.3343 24.9666 63.589 25.4283C63.6491 25.5387 63.7837 25.6766 63.8893 25.6821C64.2696 25.7009 64.6498 25.6982 65.0408 25.6953C65.184 25.6942 65.329 25.6932 65.4755 25.6932L65.4761 25.6968L65.4779 25.6932ZM58.6448 22.1431H61.8948C61.5075 21.3912 61.1356 20.6686 60.7542 19.9275L60.268 18.9829L59.7133 20.0633C59.3549 20.7612 59.0075 21.4379 58.6448 22.1431Z"
        fill="black"
      />
      <path
        d="M17.5277 23.5725C18.7415 23.6774 19.9025 23.4695 21.0872 22.8202V25.6676H22.6703V17.3203H21.0617C21.0617 17.596 21.0574 17.8688 21.0531 18.1393V18.1415C21.0435 18.7527 21.0341 19.3526 21.0744 19.9488C21.1363 20.8704 20.6704 21.3781 19.9171 21.7129C19.08 22.0844 18.2029 22.1488 17.3076 22.0164C16.7398 21.9336 16.203 21.7607 15.7317 21.4149C15.3186 21.1114 15.0784 20.7306 15.1075 20.1825C15.1264 19.817 15.1217 19.4507 15.1171 19.0841V19.0831C15.115 18.9205 15.113 18.7577 15.113 18.595V17.3222H13.4806C13.4806 17.6195 13.4827 17.9135 13.4848 18.2051V18.2062C13.4895 18.8558 13.494 19.494 13.4752 20.131C13.4515 20.9532 13.75 21.619 14.3068 22.1893C15.193 23.0961 16.3103 23.4658 17.5277 23.5725Z"
        fill="black"
      />
      <path
        d="M44.4654 36.4631C44.2806 36.4631 44.1042 36.4619 43.9335 36.4608C43.5375 36.4582 43.1725 36.4557 42.8076 36.4686C42.5815 36.4768 42.5145 36.3357 42.4438 36.1869C42.4348 36.168 42.4257 36.149 42.4163 36.1301C42.0446 35.3867 41.6732 34.6433 41.3019 33.9L41.2773 33.8507C40.7133 32.7216 40.1494 31.5928 39.5848 30.4647C39.4732 30.2426 39.3599 30.0205 39.2177 29.7417L39.199 29.705C39.009 30.0843 38.8256 30.4499 38.6461 30.8078C38.4147 31.2691 38.1897 31.7177 37.9652 32.1662C37.8054 32.4857 37.6449 32.8048 37.4845 33.1239C36.9927 34.1018 36.501 35.0798 36.029 36.0658C35.8762 36.384 35.6978 36.4907 35.3612 36.4686C35.0527 36.4482 34.7434 36.4525 34.4151 36.4571C34.2692 36.4592 34.1186 36.4612 33.9636 36.4612C33.9878 36.4024 34.0085 36.3498 34.0276 36.3015C34.0645 36.2078 34.095 36.1306 34.131 36.0566C34.4607 35.3929 34.7908 34.7294 35.1208 34.0659C36.0642 32.1693 37.0078 30.2724 37.9434 28.3715C38.0671 28.1213 38.2164 28.0385 38.482 28.0514C38.9461 28.0716 39.4137 28.0771 39.8778 28.0514C40.1853 28.0348 40.3418 28.147 40.4783 28.423C41.4201 30.3386 42.3712 32.2496 43.3222 34.1606C43.5602 34.6387 43.7981 35.1167 44.0359 35.5949C44.0902 35.704 44.1442 35.8134 44.2015 35.9292L44.2026 35.9315C44.281 36.0903 44.3658 36.2622 44.4654 36.4612V36.4631Z"
        fill="black"
      />
      <path
        d="M58.3518 3.63102V5.13567C59.9368 5.09152 60.9977 5.8089 61.6037 7.2271H63.4544C64.0622 5.77947 65.1576 5.08968 66.6971 5.12647V3.62366C64.9211 3.50042 63.5854 4.23803 62.5281 5.6268C61.4563 4.23619 60.1206 3.48571 58.3518 3.63102Z"
        fill="black"
      />
      <path
        d="M38.2204 17.6075C38.0477 17.4529 37.8765 17.2997 37.6904 17.1326C38.0704 16.7026 38.4454 16.2772 38.8249 15.8466C39.1306 15.4998 39.4392 15.1496 39.7558 14.791C39.8812 14.9034 40.0032 15.013 40.1264 15.1237L40.1282 15.1253C40.305 15.2842 40.4845 15.4454 40.6803 15.6206C39.9924 16.4005 39.3227 17.1602 38.6167 17.9622L38.6185 17.964L38.2204 17.6075Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.7215 49.6479C27.598 49.7158 27.5816 49.8333 27.566 49.945C27.5451 50.0943 27.5258 50.2332 27.2543 50.2289C27.2543 50.1181 27.2537 50.0087 27.2532 49.9004C27.2518 49.6062 27.2504 49.3196 27.2597 49.0333C27.261 48.9788 27.2969 48.9251 27.333 48.8711C27.3504 48.845 27.368 48.8188 27.3816 48.7924C27.3988 48.8164 27.4176 48.8399 27.4365 48.8635C27.4797 48.9175 27.5232 48.9719 27.5472 49.0333C27.5673 49.0835 27.569 49.1415 27.5717 49.2355C27.5732 49.2861 27.5749 49.3473 27.58 49.4233C27.6304 49.3717 27.6751 49.33 27.7142 49.2936C27.7852 49.2275 27.8378 49.1784 27.873 49.1179C28.0841 48.7666 28.408 48.636 28.7556 48.7758C29.0904 48.9119 29.2542 49.2632 29.1814 49.69C29.1195 50.0468 28.8338 50.2952 28.4808 50.286C28.2133 50.2786 28.0131 50.1554 27.8875 49.9089C27.8557 49.8471 27.8165 49.7894 27.7771 49.7314C27.7583 49.7038 27.7395 49.6762 27.7215 49.6479ZM28.4808 49.9825C28.2387 49.9806 28.0932 49.7985 28.0932 49.4987C28.0932 49.2136 28.2569 49.0131 28.4899 49.0168C28.7192 49.0204 28.8829 49.2246 28.8811 49.5079C28.8775 49.8004 28.7228 49.9825 28.4808 49.9825Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3783 48.9076C25.3564 48.9119 25.3336 48.9165 25.3096 48.921C25.357 48.6548 25.5542 48.6218 25.7557 48.588C25.9358 48.5579 26.1193 48.5271 26.2024 48.3288C25.5109 48.2387 25.0924 48.4373 24.9977 48.899C24.9468 49.151 24.9377 49.4233 24.9686 49.6808C25.0232 50.1296 25.3544 50.3577 25.7657 50.2749C26.1624 50.1958 26.3735 49.8592 26.308 49.4141C26.2461 49.0039 25.9695 48.8181 25.5182 48.8825C25.4733 48.8888 25.4284 48.8977 25.3794 48.9074L25.3783 48.9076ZM25.662 49.1602C25.3963 49.1584 25.278 49.3 25.2634 49.5355C25.2489 49.7838 25.3399 49.9677 25.6165 49.9825C25.8676 49.9972 25.9895 49.8353 26.0022 49.6091C26.015 49.3699 25.9258 49.1805 25.6638 49.1602H25.662Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.548 48.7629C30.4155 48.7857 30.2774 48.8095 30.1368 48.7998V48.8016C30.1368 48.953 30.1365 49.1024 30.1362 49.2503C30.1352 49.7366 30.1343 50.2063 30.1441 50.676C30.1441 50.724 30.1913 50.7703 30.2385 50.8166C30.2601 50.8377 30.2816 50.8589 30.2987 50.8802C30.3128 50.86 30.3299 50.8403 30.347 50.8205C30.3831 50.7788 30.4193 50.7369 30.4279 50.6907C30.4456 50.5952 30.4475 50.4961 30.4495 50.3965C30.4503 50.3523 30.4512 50.3081 30.4534 50.264C31.1795 50.3136 31.4925 50.0929 31.4707 49.5208C31.4634 49.3167 31.3869 49.0794 31.2614 48.923C31.0627 48.6742 30.8167 48.7166 30.548 48.7629ZM30.7919 49.0113C31.083 49.0408 31.1504 49.2633 31.1722 49.4804C31.1686 49.7912 30.9975 50.0175 30.7792 49.9991C30.4807 49.9734 30.4134 49.7526 30.4134 49.4988C30.4116 49.199 30.5699 48.9874 30.7919 49.0113Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.32254 50.3906C6.32544 50.5086 6.32793 50.6101 6.31269 50.709C6.3064 50.7522 6.26451 50.7901 6.22241 50.8283C6.20357 50.8453 6.18468 50.8624 6.16893 50.8801C6.15453 50.8616 6.13659 50.8431 6.1186 50.8246C6.07749 50.7822 6.03609 50.7396 6.03609 50.6961C6.029 50.208 6.02964 49.7198 6.03027 49.2352C6.03045 49.0971 6.03063 48.9592 6.03063 48.8217C6.09401 48.813 6.1524 48.8066 6.2072 48.8006C6.3144 48.7889 6.40801 48.7786 6.4983 48.7555C6.79856 48.6783 7.05696 48.7408 7.22802 49.0002C7.42819 49.3037 7.43183 49.6421 7.25895 49.9603C7.10973 50.2363 6.86043 50.3282 6.55471 50.262C6.51265 50.2535 6.46951 50.2472 6.39534 50.2363L6.31997 50.2252V50.2271C6.31997 50.2854 6.32129 50.3395 6.32254 50.3906ZM6.30177 49.4968C6.29813 49.8058 6.43461 49.9971 6.66208 50.0008C6.88227 50.0063 7.06242 49.7966 7.05332 49.517C7.04604 49.2687 6.97325 49.0443 6.68027 49.013C6.45463 48.9891 6.30541 49.1915 6.30177 49.4968Z"
        fill="black"
      />
      <path
        d="M22.8505 49.5946H22.8523L22.8505 49.5964L22.8505 49.5946Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3173 48.7374C22.6863 48.8017 22.8556 49.0587 22.8505 49.5946H21.8624C21.9606 49.9992 22.1189 50.0691 22.4483 49.9036C22.4636 49.8958 22.479 49.8876 22.4945 49.8793C22.5526 49.8483 22.6118 49.8167 22.6721 49.8079C22.7018 49.804 22.7359 49.8314 22.7701 49.8589L22.7837 49.8697C22.7933 49.8773 22.8029 49.8845 22.8123 49.8907C22.5702 50.266 22.2427 50.38 21.8915 50.2218C21.5548 50.0691 21.3947 49.6571 21.513 49.2469C21.6167 48.8882 21.9533 48.6749 22.3173 48.7374ZM21.8241 49.3168H22.532C22.31 48.9011 22.1299 48.8956 21.8241 49.3168Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8829 49.5987H11.881C11.8843 49.0445 11.7059 48.7856 11.3315 48.7342C10.9602 48.6827 10.6509 48.896 10.5508 49.2731C10.4398 49.6925 10.6054 50.0861 10.9548 50.2296C11.3169 50.3786 11.6335 50.2535 11.8883 49.7826C11.8231 49.8027 11.7662 49.8185 11.7152 49.8327C11.6168 49.86 11.5405 49.8812 11.4698 49.9151C11.1513 50.0659 10.9821 49.9831 10.9093 49.5987H11.881L11.881 49.6005L11.8829 49.5987ZM11.548 49.3228C11.5562 49.3071 11.5644 49.2919 11.5726 49.2768C11.5808 49.2616 11.589 49.2464 11.5972 49.2308C11.5631 49.2081 11.5289 49.18 11.4946 49.1518C11.4153 49.0866 11.3356 49.0211 11.2569 49.0211C11.1649 49.0211 11.0738 49.0793 10.9824 49.1377C10.9418 49.1636 10.9011 49.1896 10.8602 49.2106C10.8662 49.2258 10.8725 49.241 10.8789 49.2563C10.8879 49.2781 10.8971 49.3001 10.9057 49.3228H11.548Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8807 48.728C19.464 48.7427 19.1928 49.0591 19.2001 49.5245C19.2074 49.9862 19.4931 50.2934 19.9153 50.2897C20.3283 50.286 20.625 49.9623 20.625 49.5116C20.6268 49.0389 20.3174 48.7133 19.8807 48.728ZM19.9043 49.0168C20.1427 49.015 20.2919 49.2026 20.2919 49.5024V49.5006C20.2938 49.8078 20.1536 49.9825 19.908 49.9825C19.6678 49.9825 19.5094 49.7912 19.5094 49.5043C19.5094 49.2247 19.6769 49.0168 19.9043 49.0168Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.65191 49.4937C9.65555 49.9628 9.38804 50.2755 8.96768 50.2902C8.54187 50.3049 8.21977 49.9609 8.22705 49.4974C8.23251 49.0431 8.52549 48.7304 8.94403 48.7285C9.36803 48.7267 9.64827 49.0302 9.65191 49.4956V49.4937ZM9.34255 49.5103C9.34437 49.2288 9.18059 49.021 8.95131 49.0173C8.71474 49.0136 8.55824 49.2031 8.56006 49.4992C8.56006 49.7604 8.64013 49.9683 8.94221 49.983C9.18241 49.994 9.33891 49.8009 9.34255 49.5103Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.7835 49.4882C33.7726 49.0375 33.4705 48.7211 33.0538 48.7266L33.0556 48.7285C32.6407 48.7322 32.3405 49.0577 32.3405 49.5047C32.3386 49.9517 32.6353 50.2809 33.0483 50.2901C33.4778 50.2993 33.7944 49.9517 33.7835 49.4882ZM33.0447 49.9848C32.8045 49.9738 32.6644 49.788 32.6717 49.4882C32.6789 49.1957 32.8445 48.9934 33.0775 49.0209C33.3632 49.0541 33.4578 49.2564 33.4523 49.5213C33.4469 49.8174 33.2885 49.9958 33.0447 49.9848Z"
        fill="black"
      />
      <path
        d="M17.9299 49.6649H17.3148C17.3108 49.7111 17.3088 49.7558 17.3068 49.7991C17.3025 49.8941 17.2985 49.9826 17.2748 50.0659C17.2623 50.1101 17.2181 50.1447 17.174 50.1793C17.1538 50.1952 17.1336 50.2111 17.1164 50.2278C17.101 50.2099 17.0814 50.1919 17.0617 50.1739C17.0189 50.1347 16.9758 50.0952 16.9745 50.0549C16.9647 49.7412 16.9657 49.4275 16.9666 49.1096C16.9669 49.014 16.9672 48.9171 16.9672 48.8206C17.2753 48.7762 17.2833 48.921 17.292 49.0786C17.2966 49.1621 17.3014 49.2492 17.3512 49.3136H17.888C17.9532 49.2704 17.9535 49.182 17.9538 49.0914C17.9544 48.9393 17.9549 48.7808 18.2629 48.8188C18.2629 48.9203 18.2635 49.0213 18.2641 49.1221C18.2659 49.4032 18.2677 49.6822 18.2556 49.9611C18.2531 50.0228 18.2122 50.0837 18.1714 50.1443C18.1527 50.1722 18.134 50.2 18.1191 50.2278C18.1013 50.2017 18.0818 50.1762 18.0624 50.1509C18.0204 50.0959 17.9784 50.0411 17.9535 49.9795C17.9342 49.9281 17.9333 49.8693 17.9322 49.794C17.9317 49.7562 17.9311 49.7142 17.9281 49.6668L17.9299 49.6649Z"
        fill="black"
      />
      <path
        d="M13.501 49.9614C13.6376 50.124 13.7754 50.288 14.0884 50.1901L14.0903 50.1919C13.8792 49.9399 13.6954 49.721 13.5098 49.5003C13.6434 49.3291 13.7625 49.1745 13.8847 49.016L13.8861 49.0142L14.0247 48.8344C13.7285 48.7286 13.6054 48.8881 13.4822 49.0475C13.4042 49.1486 13.3262 49.2497 13.204 49.2832C13.2 49.2542 13.198 49.2254 13.196 49.1972C13.1917 49.1362 13.1877 49.0785 13.164 49.0294C13.1346 48.9687 13.0891 48.9153 13.0439 48.8622C13.0246 48.8396 13.0054 48.817 12.9875 48.7939C12.974 48.8178 12.9572 48.8417 12.9403 48.8657C12.9025 48.9193 12.8644 48.9735 12.8619 49.0294C12.8474 49.366 12.8492 49.7045 12.8619 50.0429C12.8632 50.0855 12.9024 50.1264 12.9417 50.1673C12.9601 50.1865 12.9784 50.2057 12.993 50.225C13.0104 50.2091 13.0305 50.1942 13.0505 50.1793C13.0953 50.1462 13.1398 50.1131 13.1549 50.0687C13.1806 49.9947 13.1854 49.9142 13.1905 49.8293C13.1927 49.7939 13.1948 49.7578 13.1986 49.721C13.3284 49.756 13.4145 49.8584 13.501 49.9614Z"
        fill="black"
      />
      <path
        d="M5.00427 48.8096V48.8114H3.77412C3.77412 48.9244 3.77329 49.0352 3.77246 49.1443C3.77031 49.4295 3.76824 49.7035 3.7814 49.9776C3.78386 50.0348 3.83031 50.0894 3.87702 50.1443C3.89948 50.1707 3.922 50.1972 3.93972 50.2241C3.95313 50.1982 3.9699 50.1726 3.98663 50.1471C4.0221 50.0931 4.05735 50.0394 4.05982 49.9831C4.07029 49.7742 4.06853 49.5653 4.06671 49.3496C4.066 49.2652 4.06528 49.1797 4.06528 49.0928H4.72039C4.72039 49.1807 4.71986 49.2672 4.71933 49.3525C4.71792 49.5833 4.71655 49.8062 4.72585 50.0291C4.72711 50.0751 4.76343 50.1201 4.79953 50.1649C4.81545 50.1847 4.83132 50.2044 4.84413 50.2241C4.86107 50.2022 4.8827 50.1806 4.90432 50.1589C4.95005 50.1131 4.99575 50.0674 4.99699 50.0199C5.00786 49.726 5.00655 49.4321 5.0052 49.126C5.00474 49.0228 5.00427 48.9178 5.00427 48.8114H5.00609L5.00427 48.8096Z"
        fill="black"
      />
      <path
        d="M15.5716 50.1958V49.1344L15.6782 49.0906C15.8439 49.0226 15.9637 48.9734 16.0847 48.9229L16.0465 48.8291H14.8146C14.8109 48.8493 14.8068 48.8696 14.8027 48.8898C14.7986 48.91 14.7945 48.9303 14.7909 48.9505C14.8692 48.9772 14.9476 49.0034 15.0259 49.0297C15.1041 49.0559 15.1822 49.0821 15.2604 49.1087C15.3332 49.8126 15.4369 50.175 15.5716 50.1958Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.00909871 53.9761C0.0834426 53.9794 0.149699 53.9832 0.210706 53.9867C0.318099 53.9929 0.410027 53.9982 0.500429 53.9982C11.9266 54 23.3509 54 34.7753 53.9926C35.0118 53.9926 35.2702 53.9393 35.4831 53.8363C40.7382 51.2767 45.992 48.7152 51.2457 46.1537L51.3395 46.108C57.4379 43.1347 63.5362 40.1614 69.6361 37.1913C69.9199 37.0534 70 36.8897 70 36.588C69.9927 24.5563 69.9927 12.5228 69.9927 0.491126C69.9927 0.385015 69.985 0.278903 69.9766 0.163855C69.9728 0.11166 69.9688 0.0567131 69.9654 0H69.4868C58.1044 0 46.7237 0 35.3448 0.00551827C35.0937 0.00551827 34.8226 0.073577 34.5987 0.185782C31.3077 1.82803 28.0208 3.47886 24.7345 5.12945L18.8282 8.09414C12.698 11.1709 6.5688 14.2471 0.434918 17.3163C0.121923 17.4727 0 17.6438 0 18.0098C0.00955365 28.346 0.00935461 38.6808 0.00915557 49.0154L0.00909871 53.9761ZM68.3405 1.50838C68.3973 1.51142 68.4594 1.51474 68.5296 1.51753H68.5315L68.5314 6.21444C68.5312 16.0064 68.531 25.7994 68.5406 35.591C68.5406 35.9644 68.4059 36.1263 68.0947 36.279C62.9901 38.765 57.8862 41.2535 52.7824 43.742L52.7685 43.7487C46.9257 46.5974 41.083 49.4461 35.2393 52.2912C34.9773 52.4199 34.6606 52.4954 34.3695 52.4954C23.5984 52.5045 12.8292 52.5045 2.05995 52.5045H1.49219L1.49224 47.8569C1.49244 38.2168 1.49264 28.5773 1.48309 18.9369C1.48309 18.5727 1.60319 18.4034 1.91801 18.2453C7.85459 15.2716 13.7901 12.2948 19.7257 9.31786C24.8123 6.76678 29.8989 4.21567 34.9864 1.66652C35.1847 1.56719 35.4249 1.50465 35.6469 1.50465C46.4616 1.49913 57.2745 1.49913 68.0874 1.49913C68.1677 1.49913 68.2473 1.50339 68.3405 1.50838Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_29395_17893">
        <rect width="70" height="54" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ChajkaLabLogo
