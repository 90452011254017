import React from 'react'

const GdcrazvitieLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="44"
    height="50"
    viewBox="0 0 44 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_29202_114985)">
      <path
        d="M40 0H36V42H40V0ZM44 42H40V50H44V42ZM32 0H28V42H32V0ZM12 0V42H16V4H20V42H24V0H12ZM28 42H24V50H28V42ZM12 42H8V50H12V42ZM8 4V0H0V42H4V4H8Z"
        fill="#1D1D1B"
      />
    </g>
    <defs>
      <clipPath id="clip0_29202_114985">
        <rect width="44" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default GdcrazvitieLogo
