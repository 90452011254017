import React from 'react'

const GolosWhiteLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="105"
    height="40"
    viewBox="0 0 105 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 38C0 34.6573 0 31.3427 0 28C0.141477 28 0.282943 28 0.400841 28C30.7949 28 61.189 28 91.583 28C95.9217 28 100.26 28 104.599 28C105 28 105 28 105 28.2378C105 31.4126 105 34.5874 105 37.7622C105 37.986 105 38 104.599 38C69.8191 38 35.0628 38 0.282943 38C0.235784 38 0.141477 38 0 38Z"
      fill="white"
    />
    <path
      d="M44.2567 0.395508C49.1757 0.395508 54.0805 0.395508 58.9995 0.395508C58.9995 6.94471 58.9995 13.4797 58.9995 20.0289C57.5819 20.0289 56.1643 20.0289 54.7184 20.0289C54.7184 14.713 54.7184 9.39711 54.7184 4.08121C52.6629 4.08121 50.6216 4.08121 48.5378 4.08121C48.5378 4.36472 48.5519 4.63406 48.5378 4.91758C48.4811 6.17922 48.4527 7.44086 48.3535 8.7025C48.1834 10.8572 47.829 12.9694 47.0918 15.0107C46.6666 16.2157 46.0995 17.3355 45.2632 18.3137C44.1291 19.6462 42.6832 20.4259 40.9396 20.6101C40.6277 20.6385 40.3158 20.6668 39.9756 20.681C39.9756 20.6101 39.9614 20.5534 39.9614 20.4967C39.9614 19.1784 39.9614 17.8742 39.9614 16.5559C39.9614 16.4566 39.9614 16.3716 40.1032 16.3716C40.9396 16.3432 41.6058 15.9747 42.1445 15.3651C42.669 14.7697 43.0092 14.0609 43.2644 13.3238C43.633 12.2464 43.8739 11.1407 43.9732 10.0209C44.0866 8.87261 44.1858 7.7102 44.2142 6.54779C44.2567 4.67659 44.2425 2.80538 44.2567 0.920004C44.2567 0.735719 44.2567 0.565617 44.2567 0.395508Z"
      fill="white"
    />
    <path
      d="M83.5943 10.2051C83.5802 15.8612 79.0439 20.3833 73.3453 20.3408C67.8309 20.2983 63.323 15.8471 63.323 10.2051C63.323 4.50646 67.9726 -0.0155967 73.6288 0.0836336C79.1715 0.168688 83.5943 4.67657 83.5943 10.2051ZM67.2922 9.70896C67.2922 12.0621 67.9868 13.7065 69.3335 15.0107C71.3465 16.967 74.536 17.2363 76.8183 15.6203C78.8596 14.1743 79.7669 12.1472 79.5968 9.66644C79.4834 7.97953 78.7888 6.54777 77.5555 5.38535C75.6984 3.62756 72.9058 3.23064 70.6802 4.46393C68.4263 5.69722 67.3773 7.68183 67.2922 9.70896Z"
      fill="white"
    />
    <path
      d="M17.2093 10.2051C17.1951 4.634 21.7455 -0.0156396 27.515 0.0835907C33.1003 0.168645 37.4806 4.74742 37.4806 10.2193C37.4806 15.7478 32.9869 20.4258 27.2173 20.3549C21.6037 20.2699 17.1951 15.7053 17.2093 10.2051ZM27.3449 16.655C27.515 16.655 27.6993 16.6692 27.8694 16.655C29.4288 16.4991 30.7471 15.8329 31.7961 14.6846C33.7098 12.5866 34.0784 9.43959 32.6608 6.95883C31.5977 5.08763 29.9816 3.96775 27.8127 3.78346C25.9841 3.62753 24.4105 4.25125 23.1064 5.52707C20.98 7.62508 20.5406 10.9989 22.0716 13.5647C23.2623 15.5493 25.0201 16.5983 27.3449 16.655Z"
      fill="white"
    />
    <path
      d="M102.151 14.3587C103.1 15.2093 104.036 16.0598 105 16.9104C104.178 17.8318 103.228 18.5689 102.151 19.1218C100.308 20.0715 98.3657 20.4259 96.3102 20.1991C91.8165 19.6888 88.2867 16.2724 87.507 11.9772C86.6707 7.39841 88.9955 3.07481 92.8796 1.10438C96.6929 -0.837696 101.13 -0.10056 104.022 2.39437C103.256 3.40085 102.491 4.42151 101.711 5.44216C101.555 5.31458 101.428 5.187 101.272 5.05942C99.9959 4.02459 98.5216 3.52844 96.8772 3.71273C94.7792 3.93954 93.2057 5.03107 92.1567 6.84556C90.4272 9.865 91.4054 13.82 94.2972 15.6487C96.8205 17.2506 100.081 16.7544 102.037 14.4863C102.051 14.4579 102.094 14.4154 102.151 14.3587Z"
      fill="white"
    />
    <path
      d="M4.28109 20.0289C4.19603 20.0289 4.13933 20.0431 4.08263 20.0431C2.79263 20.0431 1.50263 20.0431 0.212636 20.0431C0.056703 20.0431 0 20.0006 0 19.8446C0 13.423 0 7.00141 0 0.593972C0 0.537269 0 0.466387 0 0.395508C4.91899 0.395508 9.80963 0.395508 14.7144 0.395508C14.7144 1.61462 14.7144 2.81957 14.7144 4.06703C11.2414 4.06703 7.76833 4.06703 4.28109 4.06703C4.28109 9.38294 4.28109 14.6988 4.28109 20.0289Z"
      fill="white"
    />
  </svg>
)

export default GolosWhiteLogo
