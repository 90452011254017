import React from 'react'

const AkkermanWhiteLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="168"
    height="31"
    viewBox="0 0 168 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.67202 25.2918H11.2612L12.1672 31H17.4277L11.9602 0H5.13868L0 31H4.80989L5.67202 25.2918ZM10.5209 20.6675H6.41238L7.47909 13.71C7.97347 10.3769 8.30223 8.0438 8.46541 6.29333C8.58721 8.0438 8.87701 10.293 9.41033 13.71L10.5209 20.6675Z"
      fill="white"
    />
    <path
      d="M24.2516 31V16.1666L30.0893 31H35.6785L29.2661 14.4581L35.1013 0H29.758L24.2516 13.7915V0H18.8256V31H24.2516Z"
      fill="white"
    />
    <path
      d="M42.3344 16.1666V31H36.9084V0H42.3344V13.7915L47.8408 0H53.1841L47.3489 14.4581L53.7613 31H48.172L42.3344 16.1666Z"
      fill="white"
    />
    <path
      d="M67.945 4.83419V0H54.5041V30.9975H68.1909V26.0399H59.8473V17.4579H66.5885V12.5002H59.8473V4.83419H67.945Z"
      fill="white"
    />
    <path
      d="M77.7761 17.2505H79.6103C81.4171 17.2505 82.1163 18.1665 82.1163 20.2922V27.4596C82.1163 29.5828 82.3621 30.4988 82.6491 31H87.9924V30.7926C87.7054 30.0865 87.5009 28.8767 87.5009 27.2522V19.3762C87.5009 16.2086 86.02 14.8753 84.4177 14.2507C85.8154 13.8334 87.5009 12.5002 87.5009 9.2511V5.87607C87.5009 1.79245 85.5276 0 81.5827 0H77.7761V4.16757H79.6103C81.4583 4.16757 82.1163 4.95763 82.1163 7.08338V9.74981C82.1163 11.7497 81.4583 12.8335 79.6103 12.8335H77.7761V17.2505Z"
      fill="white"
    />
    <path
      d="M74.5197 26.9655H75.3427C76.3104 26.9655 77.1112 27.4532 77.1112 28.8052V28.9826C77.1112 30.3235 76.3993 30.8666 75.3427 30.8666H72.6734C71.5056 30.8666 70.816 30.3235 70.816 28.9715V28.7942C70.816 27.4088 71.4611 26.9655 72.5622 26.9655H73.1628V28.3176H72.6734C72.1729 28.3176 71.9505 28.4506 71.9505 28.8828C71.9505 29.2929 72.184 29.448 72.6734 29.448H75.2204C75.6653 29.448 75.9656 29.2929 75.9656 28.8939C75.9656 28.4728 75.6764 28.3176 75.2204 28.3176H74.5197V26.9655Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.7644 22.085H75.365C76.5106 22.085 77.1112 22.7278 77.1112 23.9579V24.1574C77.1112 25.3211 76.4883 25.9639 75.365 25.9639H72.5622C71.45 25.9639 70.816 25.3432 70.816 24.1242V23.9025C70.816 22.7832 71.3387 22.085 72.6067 22.085H74.1415V24.6007H75.2871C75.6764 24.6007 75.9656 24.4456 75.9656 24.0133C75.9656 23.6144 75.6875 23.437 75.2982 23.437H74.7644V22.085ZM71.8726 24.0133C71.8726 24.4123 72.1062 24.6007 72.5511 24.6007H73.1628V23.4149H72.5511C72.1062 23.4149 71.8726 23.6255 71.8726 24.0133Z"
      fill="white"
    />
    <path
      d="M72.0061 19.0106C72.0061 19.4318 72.3064 19.598 72.7846 19.598H76.9999V21.0055H70.9272V19.6202H71.6279C71.4722 19.5315 70.8827 19.1325 70.8827 18.3568V18.3124C70.8827 17.714 71.1608 17.315 71.5946 17.1377C71.3832 16.9715 70.8827 16.5171 70.8827 15.7635V15.7191C70.8827 14.8658 71.4277 14.4446 72.1952 14.4446H76.9999V15.8521H72.7401C72.2952 15.8521 72.0061 16.0405 72.0061 16.4395C72.0061 16.8274 72.2952 17.0269 72.7401 17.0269H76.9999V18.4343H72.7401C72.3064 18.4343 72.0061 18.6006 72.0061 19.0106Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.7644 9.46566H75.365C76.5106 9.46566 77.1112 10.1084 77.1112 11.3386V11.5381C77.1112 12.7017 76.4883 13.3445 75.365 13.3445H72.5622C71.45 13.3445 70.816 12.7239 70.816 11.5048V11.2832C70.816 10.1639 71.3387 9.46566 72.6067 9.46566H74.1415V11.9814H75.2871C75.6764 11.9814 75.9656 11.8262 75.9656 11.394C75.9656 10.995 75.6875 10.8177 75.2982 10.8177H74.7644V9.46566ZM71.8726 11.394C71.8726 11.793 72.1062 11.9814 72.5511 11.9814H73.1628V10.7956H72.5511C72.1062 10.7956 71.8726 11.0061 71.8726 11.394Z"
      fill="white"
    />
    <path
      d="M76.9999 6.97869V8.38616H70.9272V7.00086H71.6279C71.4722 6.9122 70.8716 6.50215 70.8716 5.71529V5.67096C70.8716 4.81761 71.4277 4.36322 72.1952 4.36322H76.9999V5.78178H72.7401C72.3064 5.78178 72.0061 5.94802 72.0061 6.36916C72.0061 6.80137 72.3064 6.97869 72.7846 6.97869H76.9999Z"
      fill="white"
    />
    <path
      d="M75.8099 0.274732L76.9999 0.274731V1.74871C76.9999 2.34716 76.6774 2.69072 76.0768 2.69072H72.1173V3.61057H70.9272V2.69072H68.9475V1.29432H70.9272V0.274732H72.1173V1.29432H75.4873C75.7209 1.29432 75.8099 1.23891 75.8099 0.995096V0.274732Z"
      fill="white"
    />
    <path
      d="M102.218 31H99.3416L96.4631 16.2506C95.6005 11.7497 95.1479 9.16716 94.6944 6.50071C94.7776 9.95968 94.8189 12.8335 94.8189 17.2505V31H90.1748V0H97.4905L99.5874 11.0411C100.144 13.9244 100.439 15.8327 100.723 17.6672C100.796 18.1385 100.868 18.6056 100.944 19.0824C101.047 18.4295 101.141 17.796 101.238 17.1429L101.238 17.141C101.487 15.4609 101.756 13.6501 102.259 11.0411L104.354 0H111.545V31H106.776V17.2505C106.776 13.3825 106.84 10.6979 106.91 7.76748L106.91 7.76291C106.92 7.34846 106.93 6.92907 106.94 6.50071C106.487 9.08323 105.995 11.7916 105.172 15.9592L102.218 31Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.617 25.2918H124.206L125.113 31H130.373L124.906 0H118.084L112.946 31H117.756L118.617 25.2918ZM123.469 20.6675H119.358L120.427 13.71C120.921 10.3769 121.25 8.0438 121.413 6.29333C121.535 8.0438 121.825 10.293 122.358 13.71L123.469 20.6675Z"
      fill="white"
    />
    <path
      d="M143.361 20.2083C143.237 18.4158 143.156 16.6258 143.156 11.999V0H147.883V31H143.156L138.225 16.374C137.197 13.4582 136.622 11.3744 136.252 9.70788C136.415 11.4164 136.54 13.6656 136.54 18.0405V31H131.771V0H137.114L141.471 13.6656C142.416 16.6678 142.952 18.4578 143.361 20.2083Z"
      fill="white"
    />
    <path
      d="M162.106 11.999C162.106 16.6258 162.187 18.4158 162.311 20.2083C161.901 18.4578 161.366 16.6678 160.421 13.6656L156.064 0H150.721V31H155.489V18.0405C155.489 13.6656 155.365 11.4164 155.202 9.70788C155.572 11.3744 156.147 13.4582 157.175 16.374L162.106 31H166.833V0H162.106V11.999Z"
      fill="white"
    />
  </svg>
)

export default AkkermanWhiteLogo
