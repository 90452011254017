import React from 'react'

const ArhitektBajUnistrojLogo: React.FC<
  React.SVGProps<SVGSVGElement>
> = props => (
  <svg
    width="157"
    height="32"
    viewBox="0 0 157 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_31236_1025)">
      <path
        d="M157 17.5423V0.0209836H151.333V17.1226C151.333 22.1797 147.25 26.2925 142.229 26.2925C137.208 26.2925 133.125 22.1797 133.125 17.1226V13.1358L147.416 8.54031L145.75 3.29442L133.125 7.36527V0.0209836H127.458V9.19084L104.833 16.4721V0H99.2706L66.7915 27.5515L70.3746 31.8531L99.2706 7.34425V18.2767L84.9582 22.8931L86.6458 28.097L99.2706 24.0262V31.3705H104.833V22.2426L127.458 14.9613V17.5423C127.687 25.537 134.229 32 142.229 32C150.25 32 156.791 25.5581 157 17.5423Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.687 7.44909C64.1871 7.15533 63.8123 6.77758 63.5202 6.29497C63.2286 5.81236 63.1035 5.26679 63.1035 4.65826C63.1035 4.07072 63.2495 3.52514 63.5202 3.04252C63.8123 2.5599 64.1871 2.16121 64.687 1.88842C65.1869 1.61564 65.7287 1.46875 66.3118 1.46875C66.8954 1.46875 67.4372 1.61564 67.9371 1.88842C68.437 2.16121 68.8118 2.5599 69.1039 3.04252C69.3955 3.52514 69.5206 4.07072 69.5206 4.65826C69.5206 5.24581 69.3745 5.79139 69.1039 6.29497C68.8118 6.77758 68.437 7.1763 67.9371 7.44909C67.4372 7.74285 66.8954 7.86873 66.3118 7.86873C65.7287 7.86873 65.1664 7.74285 64.687 7.44909ZM67.2702 6.39988C67.5618 6.23205 67.7911 5.98024 67.9581 5.68648C68.1245 5.3717 68.2078 5.03594 68.2078 4.65826C68.2078 4.28056 68.1245 3.94482 67.9581 3.65105C67.7911 3.33629 67.5618 3.10547 67.2702 2.91662C66.9787 2.74875 66.6662 2.64383 66.3118 2.64383C65.9579 2.64383 65.6454 2.72777 65.3538 2.91662C65.0623 3.08449 64.833 3.33629 64.666 3.65105C64.4996 3.9658 64.4163 4.30154 64.4163 4.65826C64.4163 5.03594 64.4996 5.3717 64.666 5.68648C64.833 6.00121 65.0623 6.23205 65.3538 6.39988C65.6454 6.56776 65.9579 6.67267 66.3118 6.67267C66.6662 6.67267 66.9787 6.58873 67.2702 6.39988Z"
        fill="black"
      />
      <path
        d="M23.291 1.53223V7.72237H21.9994L22.0202 3.98731L18.8119 7.82733H17.8535V1.61616H19.1452V5.5191L22.3119 1.53223H23.291Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.02083 6.46343H1.83334L1.375 7.72246H0L2.39583 1.61621H3.5L5.89582 7.72246H4.47917L4.02083 6.46343ZM3.625 5.39325L2.91667 3.44179L2.20833 5.39325H3.625Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99837 1.61621H8.95669C9.37336 1.61621 9.74837 1.70014 10.0609 1.84703C10.3734 2.0149 10.6234 2.22474 10.8109 2.51851C10.9775 2.81228 11.0817 3.14802 11.0817 3.5467C11.0817 3.94539 10.9984 4.28113 10.8317 4.5749C10.665 4.86867 10.415 5.09949 10.1025 5.2464C9.79003 5.39325 9.41503 5.47719 8.99835 5.47719H8.29006V7.70144H6.99837V1.61621ZM8.91502 4.36507C9.20669 4.36507 9.43586 4.28113 9.60253 4.13425C9.7692 3.98736 9.85253 3.79851 9.85253 3.5467C9.85253 3.2949 9.7692 3.10605 9.60253 2.95916C9.43586 2.81228 9.20669 2.74933 8.91502 2.74933H8.29006V4.38605H8.91502V4.36507Z"
        fill="black"
      />
      <path
        d="M11.3732 1.61621L13.2065 4.55392L11.2065 7.72246H12.6857L13.8732 5.77095L13.9982 5.47719L14.1024 5.77095L15.3107 7.72246H16.8524L14.8315 4.55392L16.6857 1.61621H15.1857L14.1232 3.39982L14.0399 3.60965L13.9774 3.39982L12.9149 1.61621H11.3732Z"
        fill="black"
      />
      <path
        d="M24.3325 2.7913H25.9159V7.72246H27.2075V2.7913H28.7909V1.61621H24.3325V2.7913Z"
        fill="black"
      />
      <path
        d="M29.792 1.61621H33.417V2.7913H31.1045V4.0713H33.1462V5.16246H31.1045V6.54737H33.4587V7.72246H29.792V1.61621Z"
        fill="black"
      />
      <path
        d="M38.1058 7.72246H39.7517L37.1683 4.5749L39.46 1.61621H37.96L36.0016 4.21818H35.96V1.61621H34.6475V7.72246H35.96V5.07852H36.0016L38.1058 7.72246Z"
        fill="black"
      />
      <path
        d="M41.8976 2.7913H40.2935V1.61621H44.7518V2.7913H43.1893V7.72246H41.8976V2.7913Z"
        fill="black"
      />
      <path
        d="M45.9388 6.50538L45.9596 7.74343C46.2305 7.82737 46.5222 7.86932 46.793 7.89029C47.2513 7.89029 47.6471 7.74343 47.9596 7.44967C48.2721 7.15586 48.5638 6.71525 48.8138 6.14865L50.7515 1.61621H49.3346L48.0846 4.8267L46.8347 1.61621H45.418L47.2721 5.9598L47.1679 6.21162C47.0638 6.46343 46.8763 6.58932 46.6263 6.58932C46.3763 6.58932 46.1471 6.56834 45.9388 6.50538Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.7934 1.61621H53.7517C54.1683 1.61621 54.5431 1.70014 54.8556 1.84703C55.1681 2.0149 55.4184 2.22474 55.6058 2.51851C55.7722 2.81228 55.8764 3.14802 55.8764 3.5467C55.8764 3.94539 55.7932 4.28113 55.6267 4.5749C55.4597 4.86867 55.21 5.09949 54.8975 5.2464C54.585 5.39325 54.2102 5.47719 53.7935 5.47719H53.0848V7.70144H51.7934V1.61621ZM53.7098 4.36507C54.0019 4.36507 54.2306 4.28113 54.3976 4.13425C54.5641 3.98736 54.6473 3.79851 54.6473 3.5467C54.6473 3.2949 54.5641 3.10605 54.3976 2.95916C54.2306 2.81228 54.0019 2.74933 53.7098 2.74933H53.0848V4.38605H53.7098V4.36507Z"
        fill="black"
      />
      <path
        d="M58.1889 1.61621H56.897V7.72246H58.1889V5.2464H60.6057V7.72246H61.918V1.61621H60.6057V4.09228H58.1889V1.61621Z"
        fill="black"
      />
      <path d="M70.397 4.17578H72.6677V5.22478H70.397V4.17578Z" fill="black" />
      <path
        d="M0.374023 12.0029H5.4365V18.1092H4.12403V13.199H1.66569V18.1092H0.374023V12.0029Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.85561 12.0029H6.87646V18.0882H8.18894V15.8639H8.89728C9.31395 15.8639 9.68896 15.78 10.0015 15.6331C10.314 15.4862 10.564 15.2554 10.7306 14.9616C10.8973 14.6678 10.9806 14.3321 10.9806 13.9334C10.9806 13.5347 10.8765 13.199 10.7098 12.9052C10.5223 12.6114 10.2723 12.4016 9.95979 12.2337C9.64729 12.0869 9.27228 12.0029 8.85561 12.0029ZM9.50146 14.5419C9.33479 14.6888 9.10562 14.7728 8.81394 14.7728V14.7937H8.18894V13.157H8.81394C9.10562 13.157 9.33479 13.22 9.50146 13.3669C9.66812 13.5137 9.75146 13.7026 9.75146 13.9544C9.75146 14.2062 9.66812 14.3951 9.50146 14.5419Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3348 17.8358C12.8348 17.5421 12.4598 17.1643 12.1681 16.6817C11.8765 16.1991 11.7515 15.6535 11.7515 15.045C11.7515 14.4575 11.8973 13.9119 12.1681 13.4293C12.4598 12.9466 12.8348 12.5479 13.3348 12.2752C13.8348 12.0024 14.3765 11.8555 14.9598 11.8555C15.5431 11.8555 16.0848 12.0024 16.5848 12.2752C17.0848 12.5479 17.4598 12.9466 17.7515 13.4293C18.0431 13.9119 18.1681 14.4575 18.1681 15.045C18.1681 15.6326 18.0223 16.1781 17.7515 16.6817C17.4598 17.1643 17.0848 17.563 16.5848 17.8358C16.0848 18.1296 15.5431 18.2555 14.9598 18.2555C14.3556 18.2765 13.814 18.1296 13.3348 17.8358ZM15.9181 16.8076C16.2098 16.6398 16.439 16.3879 16.6057 16.0942C16.7723 15.7794 16.8557 15.4437 16.8557 15.066C16.8557 14.6883 16.7723 14.3526 16.6057 14.0587C16.439 13.744 16.2098 13.5132 15.9181 13.3243C15.6265 13.1565 15.314 13.0515 14.9598 13.0515C14.6056 13.0515 14.2931 13.1355 14.0015 13.3243C13.7098 13.4922 13.4807 13.744 13.314 14.0587C13.1473 14.3735 13.064 14.7093 13.064 15.066C13.064 15.4437 13.1473 15.7794 13.314 16.0942C13.4807 16.4089 13.7098 16.6398 14.0015 16.8076C14.2931 16.9755 14.6056 17.0804 14.9598 17.0804C15.314 17.0594 15.6265 16.9755 15.9181 16.8076Z"
        fill="black"
      />
      <path
        d="M22.958 12.0029H19.333V18.1092H22.9997V16.9551H20.6455V15.5491H22.708V14.458H20.6455V13.178H22.958V12.0029Z"
        fill="black"
      />
      <path
        d="M29.2927 18.1092H27.6468L25.5635 15.4862H25.501V18.1092H24.1885V12.0029H25.501V14.6049H25.5427L27.501 12.0029H29.001L26.7093 14.9826L29.2927 18.1092Z"
        fill="black"
      />
      <path
        d="M29.8345 13.178H31.4386V18.1092H32.7303V13.178H34.2928V12.0029H29.8345V13.178Z"
        fill="black"
      />
      <path
        d="M35.3135 12.0029H36.6051V14.479H39.0218V12.0029H40.3343V18.1092H39.0218V15.6541H36.6051V18.1092H35.3135V12.0029Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.9367 16.6817C42.2284 17.1643 42.6034 17.5421 43.1033 17.8358C43.5825 18.1296 44.1242 18.2765 44.7284 18.2555C45.3117 18.2555 45.8534 18.1296 46.3533 17.8358C46.8533 17.563 47.2283 17.1643 47.52 16.6817C47.7908 16.1781 47.9367 15.6326 47.9367 15.045C47.9367 14.4575 47.8117 13.9119 47.52 13.4293C47.2283 12.9466 46.8533 12.5479 46.3533 12.2752C45.8534 12.0024 45.3117 11.8555 44.7284 11.8555C44.145 11.8555 43.6033 12.0024 43.1033 12.2752C42.6034 12.5479 42.2284 12.9466 41.9367 13.4293C41.6659 13.9119 41.52 14.4575 41.52 15.045C41.52 15.6535 41.645 16.1991 41.9367 16.6817ZM46.3742 16.0942C46.2075 16.3879 45.9784 16.6398 45.6867 16.8076C45.395 16.9755 45.0825 17.0594 44.7284 17.0804C44.3742 17.0804 44.0617 16.9755 43.77 16.8076C43.4783 16.6398 43.2492 16.4089 43.0825 16.0942C42.9158 15.7794 42.8325 15.4437 42.8325 15.066C42.8325 14.7093 42.9158 14.3735 43.0825 14.0587C43.2492 13.744 43.4783 13.4922 43.77 13.3243C44.0617 13.1355 44.3742 13.0515 44.7284 13.0515C45.0825 13.0515 45.395 13.1565 45.6867 13.3243C45.9784 13.5132 46.2075 13.744 46.3742 14.0587C46.5408 14.3526 46.6242 14.6883 46.6242 15.066C46.6242 15.4437 46.5408 15.7794 46.3742 16.0942Z"
        fill="black"
      />
      <path
        d="M49.125 12.0029H52.7293V13.178H50.4166V14.458H52.4791V15.5491H50.4166V16.9551H52.7706V18.1092H49.125V12.0029Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99902 22.4102H0.374023V28.5374H2.54069C2.93652 28.5374 3.29069 28.4534 3.62402 28.2856C3.95736 28.1177 4.18653 27.8869 4.37402 27.5931C4.56152 27.2993 4.64486 26.9636 4.64486 26.5859C4.64486 26.2292 4.54069 25.8934 4.37402 25.5997C4.20736 25.3059 3.95736 25.0751 3.64486 24.9072C3.33236 24.7393 2.95736 24.6554 2.56152 24.6554H1.66569V23.5223H3.99902V22.4102ZM2.91569 27.2783C2.79069 27.3413 2.64486 27.3833 2.49903 27.3833H1.66569V25.7675H2.49903C2.64486 25.7675 2.79069 25.8095 2.91569 25.8725C3.04069 25.9564 3.14486 26.0403 3.20736 26.1662C3.26986 26.2711 3.31152 26.418 3.31152 26.5649C3.31152 26.7328 3.26986 26.8587 3.20736 26.9846C3.14486 27.1105 3.04069 27.2154 2.91569 27.2783Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3127 22.6619C12.7918 22.9347 13.146 23.3333 13.4168 23.8159C13.6877 24.2986 13.8335 24.8442 13.8335 25.4317C13.8335 26.0192 13.7085 26.5648 13.4168 27.0684C13.146 27.551 12.771 27.9498 12.3127 28.2225C11.8335 28.5163 11.3335 28.6422 10.7502 28.6422C10.2502 28.6422 9.77101 28.5373 9.3335 28.3064C8.896 28.0756 8.54182 27.7609 8.25015 27.3622C7.95853 26.9635 7.79186 26.5019 7.70853 25.9982H7.00018V28.5163H5.7085V22.4101H7.00018V24.9491H7.68769C7.75019 24.4245 7.9377 23.9629 8.22932 23.5642C8.52099 23.1445 8.87516 22.8298 9.31267 22.5989C9.75017 22.3681 10.2293 22.2422 10.7294 22.2422C11.3335 22.2422 11.8543 22.3891 12.3127 22.6619ZM11.6668 27.1943C11.9377 27.0265 12.146 26.7747 12.3127 26.4809C12.4585 26.1661 12.5418 25.8304 12.5418 25.4527C12.5418 25.075 12.4585 24.7393 12.3127 24.4245C12.1668 24.1098 11.9377 23.8789 11.6668 23.711C11.396 23.5432 11.1044 23.4593 10.7502 23.4593C10.4169 23.4593 10.1252 23.5432 9.85434 23.711C9.58351 23.8789 9.37517 24.1307 9.22933 24.4245C9.0835 24.7393 9.00016 25.075 9.00016 25.4527C9.00016 25.8304 9.0835 26.1661 9.22933 26.4809C9.37517 26.7956 9.60434 27.0265 9.85434 27.1943C10.1252 27.3622 10.4169 27.4461 10.7502 27.4461C11.0835 27.4671 11.396 27.3832 11.6668 27.1943Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0017 22.4102H15.0433V28.4954H16.335V26.2711H17.0433C17.46 26.2711 17.835 26.1872 18.1475 26.0403C18.46 25.8934 18.71 25.6626 18.8766 25.3688C19.0433 25.0751 19.1266 24.7393 19.1266 24.3406C19.1266 23.942 19.0225 23.6062 18.8558 23.3125C18.6683 23.0187 18.4183 22.8088 18.1058 22.6409C17.7933 22.4941 17.4183 22.4102 17.0017 22.4102ZM17.6475 24.9282C17.4808 25.0751 17.2517 25.159 16.96 25.159V25.18H16.335V23.5433H16.96C17.2517 23.5433 17.4808 23.6062 17.6475 23.7531C17.8141 23.9 17.8975 24.0888 17.8975 24.3406C17.8975 24.5925 17.8141 24.7813 17.6475 24.9282Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4574 28.244C20.9574 27.9502 20.5824 27.5725 20.2907 27.0899C19.999 26.6073 19.874 26.0617 19.874 25.4532C19.874 24.8657 20.0199 24.3201 20.2907 23.8374C20.5824 23.3548 20.9574 22.9562 21.4574 22.6834C21.9573 22.4106 22.499 22.2637 23.0824 22.2637C23.6657 22.2637 24.2074 22.4106 24.7074 22.6834C25.2073 22.9562 25.5823 23.3548 25.874 23.8374C26.1657 24.3201 26.2907 24.8657 26.2907 25.4532C26.2907 26.0408 26.1449 26.5863 25.874 27.0899C25.5823 27.5725 25.2073 27.9712 24.7074 28.244C24.2074 28.5378 23.6657 28.6637 23.0824 28.6637C22.499 28.6637 21.9573 28.5168 21.4574 28.244ZM24.0615 27.1948C24.3532 27.0269 24.5824 26.7752 24.749 26.4814C24.9157 26.1666 24.999 25.8309 24.999 25.4532C24.999 25.0755 24.9157 24.7397 24.749 24.446C24.5824 24.1312 24.3532 23.9004 24.0615 23.7115C23.7699 23.5437 23.4574 23.4388 23.1032 23.4388C22.749 23.4388 22.4365 23.5227 22.1448 23.7115C21.8532 23.8794 21.624 24.1312 21.4574 24.446C21.2907 24.7608 21.2074 25.0965 21.2074 25.4532C21.2074 25.8309 21.2907 26.1666 21.4574 26.4814C21.624 26.7962 21.8532 27.0269 22.1448 27.1948C22.4365 27.3627 22.749 27.4676 23.1032 27.4676C23.4365 27.4676 23.7699 27.3627 24.0615 27.1948Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_31236_1025">
        <rect width="157" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default ArhitektBajUnistrojLogo
