import React from 'react'

const GlavstrojWhiteLogo: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="150"
    height="18"
    viewBox="0 0 150 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_29363_89458)">
      <path d="M146.081 0H141.26V1.87024H146.081V0Z" fill="white" />
      <path
        d="M9.9673 17.9529H0.00754803V14.1759L9.9673 7.41489V17.9529Z"
        fill="white"
      />
      <path
        d="M11.3179 3.30123L18.2596 8.01781V0.25293H0V10.9783L11.3179 3.30123Z"
        fill="white"
      />
      <path
        d="M12.667 7.41489V17.9514H18.2641V11.2198L12.667 7.41489Z"
        fill="white"
      />
      <path
        d="M31.8818 15.8856V6.16404H38.745V3.625H28.9919V15.8856H31.8818Z"
        fill="white"
      />
      <path
        d="M39.9629 15.4992C40.6857 14.2391 41.0479 12.2796 41.0479 9.59719V3.625H51.7033V15.8856H48.8105V6.16404H43.9362V9.33526C43.9362 12.4699 43.5937 14.6417 42.9071 15.8856H39.9629V15.4992Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.6938 3.625H58.5469L53.5791 15.8856H56.7391L57.7139 13.345H63.5298L64.5047 15.8856H67.6737L62.6938 3.625ZM60.6234 5.79672L62.555 10.806H58.6903L60.6234 5.79672Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.5522 3.625H76.0533C78.51 3.625 80.1171 4.8689 80.1171 6.77719C80.1171 8.1089 79.432 9.03671 78.0407 9.57963C79.6297 10.0187 80.5683 11.0167 80.5683 12.4699C80.5683 14.6417 78.8888 15.8856 76.1438 15.8856H69.5522V3.625ZM75.9642 8.35329C76.8485 8.35329 77.3224 7.93329 77.3224 7.21622C77.3224 6.58549 76.8349 6.16404 75.9642 6.16404H72.4421V8.35916L75.9642 8.35329ZM76.3249 13.345C77.2092 13.345 77.769 12.8723 77.769 12.1201C77.769 11.3679 77.2092 10.8938 76.3249 10.8938H72.4421V13.345H76.3249Z"
        fill="white"
      />
      <path
        d="M92.8399 10.1402C92.4234 12.189 91.1422 13.5207 88.9933 13.5207C86.9697 13.5207 85.2358 12.0675 85.2358 9.75239C85.2358 7.47531 86.8973 5.98702 88.9209 5.98702C90.6729 5.98702 91.9541 6.98507 92.3163 8.68409L94.9707 7.53092C94.429 5.23629 92.3163 3.44946 88.9752 3.44946C85.272 3.38068 82.275 6.23434 82.346 9.75531C82.346 13.3626 84.9476 16.0597 88.9752 16.0597C92.4415 16.0597 94.7534 14.1865 95.4944 11.1734L92.8399 10.1402Z"
        fill="white"
      />
      <path
        d="M107.878 6.16404H103.544V15.8856H100.654V6.16404H96.3198V3.625H107.878V6.16404Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.895 15.8856H112.785V11.3314H115.945C118.726 11.3314 120.19 9.45672 120.19 7.46062C120.19 5.39428 118.762 3.625 115.945 3.625H109.895V15.8856ZM117.3 7.47524C117.3 8.31963 116.704 8.79232 115.765 8.79232H112.785V6.15817H115.765C116.704 6.15817 117.3 6.63085 117.3 7.47524Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.359 9.75527C135.432 13.1709 132.306 16.1475 128.677 16.0597C125.046 16.1475 121.922 13.1709 121.995 9.75527C121.922 6.33966 125.046 3.38064 128.677 3.44942C132.306 3.38064 135.43 6.34259 135.359 9.75527ZM132.469 9.75527C132.469 7.51332 130.79 6.07771 128.677 6.07771C126.564 6.07771 124.885 7.51332 124.885 9.75527C124.885 11.9972 126.564 13.4328 128.677 13.4328C130.79 13.4328 132.469 11.9972 132.469 9.75527Z"
        fill="white"
      />
      <path
        d="M137.513 15.8856H142.461L147.092 5.49526V15.8856H149.982V3.625H145.034L140.41 14.0152V3.625H137.513V15.8856Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_29363_89458">
        <rect width="150" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default GlavstrojWhiteLogo
